import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class DashboardResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.data = data?.data || [];
        this.options = data?.options || {};
        this.loyalty = {
            data: data?.LOYALTY?.data || [],
            options: data?.LOYALTY?.options || {}
        }
        this.portal = {
            data: data?.PORTAL?.data || [],
            options: data?.PORTAL?.options || {}
        }
        this.cma = {
            data: data?.CMA?.data || [],
            options: data?.CMA?.options || {}
        }
    }

    generateItems(items = []) {
        let newItems = [];
        if (items.length < 2) {
            newItems = [];
        } else if (items.length < 3) {
            newItems = items;
            newItems.push([" ", ""])
        } else {
            newItems = items;
        }
        return newItems;
    }

    exportUserActivity() {
        let items = [];
        if (this.data.length < 2) {
            items = [];
        } else if (this.data.length < 3) {
            items = this.data;
            items.push([" ", ""])
        } else {
            items = this.data;
        }
        return {
            data: items,
            options: this.options
        }
    }

    exportDailyUserActivity() {
        let loyalty = {
                data: this.generateItems(this.loyalty?.data || []),
                options: this.loyalty?.options
            },
            portal = {
                data: this.generateItems(this.portal?.data || []),
                options: this.portal?.options
            },
            cma = {
                data: this.generateItems(this.cma?.data || []),
                options: this.cma?.options
            }
        return {
            loyalty,
            portal,
            cma
        }
    }

    exportCityFilter() {
        let items = [];
        if (this.data.length < 2) {
            items = [];
        } else if (this.data.length < 3) {
            items = this.data;
            items.push([" ", ""])
        } else {
            items = this.data;
        }
        return {
            data: items,
            options: this.options
        }
    }

    exportImportLeadType() {
        let items = [];
        if (this.data.length < 2) {
            items = [];
        } else if (this.data.length < 3) {
            items = this.data;
            items.push([" ", ""])
        } else {
            items = this.data;
        }
        return {
            data: items,
            options: this.options
        }
    }

    exportImportCounty() {
        let items = [];
        if (this.data.length < 2) {
            items = [];
        } else if (this.data.length < 3) {
            items = this.data;
            items.push([" ", ""])
        } else {
            items = this.data;
        }
        return {
            data: items,
            options: this.options
        }
    }

}
