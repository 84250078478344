import React, {Fragment} from "react";
import {PAGINATION} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import {Empty, Table, Tag} from "antd";
import Icon from "./Icon";

export const ListComponent = (props) => {

    const onChange = (pagination, filters, sorter) => {
        props.onChange(pagination, filters, sorter);
    };
    const pagination = props.pagination && !(props.pagination.total <= PAGINATION.pageSize) ? props.pagination : false;
    if (pagination) {
        pagination.showTotal = (total, range) => `${range[0]}-${range[1]} ${TAG_DEFINE.PAGINATE.of} ${total} ${TAG_DEFINE.PAGINATE.items}`;
        pagination.showSizeChanger = true;
        pagination.showQuickJumper = true;
        pagination.hideOnSinglePage = false;
        pagination.locale = {
            items_per_page: TAG_DEFINE.PAGINATE.itemsPerPage,
            jump_to: TAG_DEFINE.PAGINATE.jumpTo,
        }
    }

    return (
        <Fragment>
            <div
                className={props?.disabledClassParent ? "" : `row mt-${props.marginTop || 2} ${props.classParent ? props.classParent : ''}`}>
                <div className={`col-md-12 ${props.classChildren ? props.classChildren : ''}`}>
                    <div className={`ibox mb-${props.marginBottom || '4'}`}>
                        <div className="ibox-content" style={{padding: 0}}>
                            {props.componentTableList && (props.componentTableList.left !== undefined || props.componentTableList.right !== undefined) &&
                                <div className={`row mb-3`}>
                                    <div className={`col-md-6`}>
                                        {
                                            props.componentTableList.left
                                        }
                                    </div>
                                    <div className={`col-md-6 text-right`}>
                                        {
                                            props.componentTableList.right
                                        }
                                    </div>
                                </div>
                            }
                            <Table {...props}
                                   locale={{
                                       emptyText: <Empty description={TAG_DEFINE.VALIDATION.nullData}/>,
                                       filterConfirm: <Icon type="search"/>,
                                       filterReset: <Tag color="#f5222d">Hủy</Tag>,
                                   }}
                                   onChange={onChange}
                                   pagination={pagination}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
