import {Comment, Popconfirm, Space, Typography} from "antd";
import Avatar from "antd/es/avatar/avatar";
import React, {useEffect, useRef} from "react";
import PERMISSION from "../../../../../constants/permission";
import {CloseOutlined, FileOutlined} from "@ant-design/icons";

const MessageComments = props => {
    return (
        <>
            {
                (props.itemDetail?.messages || []).length > 0 && (
                    <>
                        {
                            props.itemDetail?.messages.map((item, k) => {
                                return (
                                    <Comment
                                        key={k}
                                        // actions={actions}
                                        className={`ticket__comments--item ${item?.user?.is_admin ? "ticket__comments--item-admin" : ""}`}
                                        author={
                                            <span
                                                onClick={e => !item?.user?.is_admin && props.history.push((PERMISSION.CUSTOMER.UPDATE.route).replace(":id", item?.user?.id), {
                                                    group: PERMISSION.CUSTOMER.UPDATE.group,
                                                    withParams: true
                                                })}
                                                className={`ticket__comments--item-avatar cursorPointer fs--18px ${item?.user?.is_admin ? "color-red-2" : ""}`}>
                                                            {item?.user?.first_name} {item?.user?.last_name} {item?.user?.is_admin ? "(Admin)" : ""}
                                                        </span>
                                        }
                                        avatar={<Avatar
                                            onClick={e => !item?.user?.is_admin && props.history.push((PERMISSION.CUSTOMER.UPDATE.route).replace(":id", item?.user?.id), {
                                                group: PERMISSION.CUSTOMER.UPDATE.group,
                                                withParams: true
                                            })}
                                            className="ticket__comments--avatar"
                                            src={(item?.user?.first_name && item?.user?.last_name) ? `https://ui-avatars.com/api/?bold=true&background=000000&color=ffffff&name=${item?.user?.first_name} ${item?.user?.last_name}` : "https://ui-avatars.com/api/?bold=true&background=ffffff&name=NF"}
                                            alt={`${item?.user?.first_name} ${item?.user?.last_name}`}/>}
                                        content={
                                            <>
                                                <p className="mt-2">
                                                    {item?.message}
                                                </p>
                                                <p className="fs--13px color--description mt-2">
                                                    {item?.created_at}
                                                </p>
                                                {(item?.attachments || []).length > 0 ? (
                                                    <div className="my-2 w-50">
                                                        <Typography.Paragraph>
                                                            <pre>
                                                            {
                                                                (item?.attachments || []).map((i, k) => {
                                                                    return (
                                                                        <div>
                                                                            <Space>
                                                                                <FileOutlined className="icon-ant"/>
                                                                                <a href={i?.value} key={k} target="_blank">
                                                                                    {/*<PaperClipOutlined className="cursorPointer"/>*/}
                                                                                    {i?.title}
                                                                                </a>
                                                                            </Space>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            </pre>
                                                        </Typography.Paragraph>
                                                    </div>
                                                ) : null}
                                            </>
                                        }
                                    />
                                )
                            })
                        }
                        <AlwaysScrollToBottom/>
                    </>
                )
            }
        </>
    )
}

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef}/>;
};

export default MessageComments
