import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SubscriptionCustomer';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionCustomerService';
import * as FunctionAction from '../actions/subscriptionCustomerAction';
import {message} from "antd";
import CustomerRequest from "../mapping/Request/CustomerRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";
import SubscriptionCustomerRequest, {filtersParams} from "../mapping/Request/SubscriptionCustomerRequest";

function* actionGetListCustomers() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters || {}));
        const result = yield call(FunctionService.getListSubscriptionCustomers, params);
        yield put(FunctionAction.subscriptionCustomerListSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_FAILED)))
}

function* actionGetItem() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getItem, action.params.id);
        yield put(FunctionAction.subscriptionCustomerGetItemSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_FAILED)))
}

function* actionGetMasterCustomers() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterData, action.params);
        yield put(FunctionAction.subscriptionCustomerMasterDataSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_FAILED)))
}

function* actionGetMasterStateCustomers() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_MASTER_STATE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterState, action.params);
        yield put(FunctionAction.subscriptionCustomerMasterStateSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_FAILED)))
}

function* createAction() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SubscriptionCustomerRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        yield put(FunctionAction.subscriptionCustomerCreateSuccess("ok"));
        // action.params.props.history.push(PERMISSION.SUBSCRIPTION_CUSTOMER.VIEW.route, {group: PERMISSION.SUBSCRIPTION_CUSTOMER.VIEW.group});
        yield put({ type: Types.SUBSCRIPTION_CUSTOMER_LIST_ACTION });
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_FAILED)))
}

function* updateAction() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SubscriptionCustomerRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        // yield put({ type: Types.HOTEL_GET_ITEM_ACTION });
        // yield put({ type: Types.VOUCHER_FAIL });
        yield put(FunctionAction.subscriptionCustomerGetItemAction({id: action.params.id}));
        // yield put(VoucherActions.listVoucherAction(action.params.filters || {}));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_FAILED)))
}

function* deleteAction() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOMER_REMOVE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.deleteData, action.params?.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.subscriptionCustomerListAction(action.params?.filters));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOMER_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionCustomerSaga() {
    yield all([
        fork(actionGetListCustomers),
        fork(actionGetMasterCustomers),
        fork(actionGetMasterStateCustomers),
        fork(createAction),
        fork(actionGetItem),
        fork(updateAction),
        fork(deleteAction),
    ])
}
