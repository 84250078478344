import React, {useState, useEffect} from 'react';
import {Chart} from "react-google-charts";
import {Button, Form, Input, Modal, DatePicker, Spin, Tabs} from "antd"

import {ActionUserActivity, ActionDataInit, ActionCityFilter, ActionAllDashboard} from "../../data/hooks/dashboard";

import {selectFetching, selectUserActivity, selectCityFilter} from "../../data/reselects/dashboardSelector";

import HeaderAction from "../common/layout/HeaderAction";
import {useSelector} from "react-redux";
import {Icon} from "../common";
import {removeObjectNullFull} from "../../utils/functions";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../constants/define";
import UsageComponent from "./dashboard/components/UsageComponent";
import ActiveUserComponent from "./dashboard/components/ActiveUserComponent";
import TrackingComponent from "./dashboard/components/TrackingComponent";


const Index = (props) => {
    const [isFetching, setIsFetching] = useState(false),
        [tabActive, setTabActive] = useState("1");

    return (
        <>
            <HeaderAction title="Dashboard"/>
            <Spin spinning={isFetching}>
                <Tabs
                    activeKey={tabActive}
                    onTabClick={(e) => setTabActive(e)}
                    className="dashboard--tab">
                    <Tabs.TabPane tab="Usage" key="1">
                        <UsageComponent/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Active User" key="2">
                        <ActiveUserComponent/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Import Tracking" key="3">
                        <TrackingComponent/>
                    </Tabs.TabPane>
                </Tabs>
            </Spin>
        </>
    )
}

export default Index
