import * as Types from '../types/Verify';
import {PAGINATION} from "../../constants/define";

const initialState = {
    isFetching: false,
    access: false,
    errors: [],
    general: {},
    data: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.VERIFY_ROUTE_ACTION:
            return {...state, isFetching: true, errors: [], access: false};
        case Types.VERIFY_GENERAL_ACTION:
            return {...state, isFetching: true};
        case Types.VERIFY_ROUTE_SUCCESS:
            return {...state, isFetching: false, errors: [], access: true};
        case Types.VERIFY_GENERAL_SUCCESS:
            return {...state, isFetching: false, errors: [], general: action.general};
        case Types.VERIFY_INIT:
            return initialState;
        case Types.VERIFY_FAILED:
            return {...state, isFetching: false, errors: action.errors, access: false};
        default:
            return state;
    }
}
