import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Checkbox, Modal} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCreateCustomer,
    ActionInit,
    ActionGetItem,
    ActionUpdateCustomer
} from "../../../../data/hooks/customer";

// ============== SELECT DATA ==============
import {selectFetching, selectCustomerItem, selectMasterData, selectStatusAction} from "../../../../data/reselects/customerSelector";
import {common} from "../../../../data/mapping/Request/CustomerRequest";
import {Icon} from "../../../common";
import {isEmail} from "../../../../utils/validation";
import PERMISSION from "../../../../constants/permission";
import TAG_DEFINE from "../../../../constants/common";
import CreditComponent from "../components/CreditComponent";
import CreditUpdateComponent from "../components/CreditUpdateComponent";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [masterRoles, setMasterRoles] = useState({}),
        [masterStatus, setMasterStatus] = useState({}),
        [masterAdmin, setMasterAdmin] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        [showUpdateCredit, setShowUpdateCredit] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        actionCreateCustomer = ActionCreateCustomer(),
        getUpdateCustomer = ActionUpdateCustomer(),
        getInitAction = ActionInit(),
        getItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemDetail = useSelector(selectCustomerItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail).length < 1) {
                setTypePage(TAG_DEFINE.ACTION.update)
                getItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }

        return () => {
            getInitAction();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);
    useEffect(() => {
        if(itemStatusAction?.isUpdateCreditSuccess){
            setShowUpdateCredit(false)
        }
    }, [itemStatusAction]);

    useEffect(() => {
        if (Object.keys(itemDetail).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.roles || {}).length > 0) {
            setMasterRoles(itemMasterData?.roles)
        }
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
        if (Object.keys(itemMasterData?.admin || {}).length > 0) {
            setMasterAdmin(itemMasterData?.admin)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            getUpdateCustomer({...val, props, id: itemDetail?.id})
        } else {
            actionCreateCustomer({...val, props})
        }
    }

    return (
        <>
            <HeaderAction title={`Customer ${TAG_DEFINE.ACTION[typePage.toLowerCase()]}`}/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="First name"
                                            name={common.ACTION_FIELD.first_name}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Last name"
                                            name={common.ACTION_FIELD.last_name}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email"
                                            name={common.ACTION_FIELD.email}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Email!'
                                                }, ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        if ((itemDetail[common.ACTION_FIELD.email] === value)) {
                                                            return Promise.resolve();
                                                        }
                                                        if (value) {
                                                            if (!isEmail(value) && ((value || "").length >= 10)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("Invalid email");
                                                        }
                                                        return Promise.reject("Invalid email")
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input disabled={isUpdate}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Account Management"
                                            name={common.ACTION_FIELD.account_manager}
                                            rules={[{required: true}]}
                                        >
                                            <Select
                                                className="w-100"
                                                allowClear
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    (Object.keys(masterAdmin || {})).length > 0 && (Object.keys(masterAdmin || {})).map((i, k) => {
                                                        return (
                                                            <Select.Option value={i}
                                                                           key={k}>{masterAdmin[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Phone number"
                                            name={common.ACTION_FIELD.phone_number}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Password"
                                            name={common.ACTION_FIELD.password}
                                            rules={[{
                                                required: !isUpdate,
                                                message: 'Please input your Password!',
                                            }, {type: "string", min: 6}, ({getFieldValue, getFieldsError}) => ({
                                                validator(rule, value) {
                                                    if ((itemDetail[common.ACTION_FIELD.password] === value)) {
                                                        return Promise.resolve();
                                                    }
                                                    if (value) {
                                                        if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject("'password' cannot be empty");
                                                    }
                                                    return Promise.resolve();
                                                },
                                            })]}
                                        >
                                            <Input.Password/>
                                        </Form.Item>
                                    </div>
                                    {
                                        isUpdate && (
                                            <>
                                                <div className="col-4">
                                                    <Form.Item
                                                        label="Credit"
                                                        name={common.ACTION_FIELD.credit}
                                                    >
                                                        <Input disabled/>
                                                    </Form.Item>
                                                </div><div className="col-4">
                                                    <Form.Item
                                                        label="API Key"
                                                        name={common.ACTION_FIELD.api_key}
                                                    >
                                                        <Input disabled/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-4">
                                                    <Form.Item
                                                        label="Status"
                                                        name={common.ACTION_FIELD.status}
                                                        rules={[{required: true, message: 'Please select your Role!'}]}
                                                    >
                                                        <Select
                                                            className="w-100"
                                                            style={{width: 120}}>
                                                            {
                                                                (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {
                                                                    return (
                                                                        <Select.Option value={i}
                                                                                       key={k}>{masterStatus[i]}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-4">
                                                    <Form.Item
                                                        className="mb-0"
                                                        label=""
                                                        name={common.ACTION_FIELD.is_bypass_multiple_login}
                                                        valuePropName="checked"
                                                    >
                                                        <Checkbox>Bypass Multiple Login</Checkbox>
                                                    </Form.Item>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        {
                                            isUpdate && (
                                                <Form.Item className="">
                                                    <Button type="primary"
                                                            htmlType="button"
                                                            className="me-3"
                                                        // onClick={() => props.history.push({
                                                        //     pathname: PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route,
                                                        //     search: `?email=${form.getFieldValue(common.ACTION_FIELD.email)}`
                                                        // })}
                                                    >
                                                        <a
                                                            className="text-decoration-none"
                                                            href={`${PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route}?email=${form.getFieldValue(common.ACTION_FIELD.email)}`}
                                                            target="_blank">
                                                            Subscription
                                                        </a>
                                                    </Button>
                                                    <Button type="primary" htmlType="button" className="me-3"
                                                        // onClick={() => props.history.push({
                                                        //     pathname: PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.route,
                                                        //     search: `?email=${form.getFieldValue(common.ACTION_FIELD.email)}`
                                                        // })}
                                                    >
                                                        <a
                                                            className="text-decoration-none"
                                                            href={`${PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.route}?search=${form.getFieldValue(common.ACTION_FIELD.email)}`}
                                                            target="_blank">
                                                            Subscription Recurring
                                                        </a>
                                                    </Button>
                                                </Form.Item>
                                            )
                                        }
                                    </div>
                                    <div className="col-6 text-end">
                                        <Form.Item className="">
                                            <Button type="default" htmlType="button" className="me-3"
                                                    onClick={() => props.history.goBack()}>
                                                Back
                                            </Button>
                                            <Button type="primary" htmlType="submit">
                                                Save {isFetching && <Icon type="sync-outlined" spin/>}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="px-5 mt-4">
                    <Modal
                        title="Update Credit"
                        destroyOnClose
                        visible={showUpdateCredit}
                        footer={null}
                    >
                        <CreditUpdateComponent user_id={props?.match.params?.id} setShowUpdateCredit={e => setShowUpdateCredit(e)}/>
                    </Modal>
                    <div className="px-3">
                        <p className="fw-bold mb-3 fs--20px">Credit List</p>
                        <Button
                            type="primary"
                                htmlType="button"
                                className="mb-3"
                            onClick={e => setShowUpdateCredit(true)}
                        >
                            Update Credit
                        </Button>
                    </div>
                    <CreditComponent user_id={props?.match.params?.id} />
                </div>
            </Spin>
        </>
    )
}

export default Index
