import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Tooltip, message, DatePicker, Table, Divider} from 'antd';
import HeaderAction from "../../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterSubscriptionCustomerRecurring,
    ActionInit,
    ActionUpdateSubscriptionCustomerRecurring,
    ActionGetItemSubscriptionCustomerRecurring, ActionCreateSubscriptionCustomerRecurring
} from "../../../../../data/hooks/subscriptionCustomerRecurring";

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectSubscriptionCustomerRecurringItem,
    selectMasterData
} from "../../../../../data/reselects/subscriptionCustomerRecurringSelector";
import {common} from "../../../../../data/mapping/Request/SubscriptionCustomerRequest";
import {Icon} from "../../../../common";
import {isEmail} from "../../../../../utils/validation";
import PERMISSION from "../../../../../constants/permission";
import {CopyOutlined} from "@ant-design/icons";
import {
    constants,
    historyCols,
    packagesCols
} from "../../../../../data/mapping/Response/SubscriptionCustomerRecurringResponse";
import {DATE_TIME_FORMAT} from "../../../../../constants/define";
import PackagesList from "./PackagesList";
import {columnsTable, getTitleTable} from "../../../../../utils/functions";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [masterRoles, setMasterRoles] = useState({}),
        [masterStatus, setMasterStatus] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        [resultPackages, setResultPackages] = useState([]),
        // ============== ACTION ==============
        getMasterData = ActionMasterSubscriptionCustomerRecurring(),
        // getCreateUser = ActionCreateUser(),
        actionUpdateSubscriptionCustomerRecurring = ActionUpdateSubscriptionCustomerRecurring(),
        actionCreateSubscriptionCustomerRecurring = ActionCreateSubscriptionCustomerRecurring(),
        getInitAction = ActionInit(),
        getItem = ActionGetItemSubscriptionCustomerRecurring(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectSubscriptionCustomerRecurringItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail).length < 1) {
                getItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }

        return () => {
            getInitAction();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        // if (Object.keys(itemMasterData?.roles || {}).length > 0) {
        //     setMasterRoles(itemMasterData?.roles)
        // }
        // if (Object.keys(itemMasterData?.status || {}).length > 0) {
        //     setMasterStatus(itemMasterData?.status)
        // }
    }, [itemMasterData]);

    const onFinish = (val) => {
        let item = [...resultPackages];
        if (isUpdate) {
            actionUpdateSubscriptionCustomerRecurring({
                query: {...val, item},
                id: itemDetail?.id
            })
        } else {
            actionCreateSubscriptionCustomerRecurring({
                query: {...val, item},
                id: itemDetail?.id,
                props
            })
        }
    }

    return (
        <>
            <HeaderAction title="Subscription Customer Recurring" disabledFormatUpperCaseTitle/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email"
                                            name={common.ACTION_FIELD.email}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Email!'
                                                }
                                            ]}
                                        >
                                            {/*<Input disabled/>*/}
                                            <Select
                                                className="w-100"
                                                allowClear
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                disabled={isUpdate}
                                            >
                                                {
                                                    (itemMasterData?.customer_dropdown || []).length > 0 && (itemMasterData?.customer_dropdown).map((i, k) => {
                                                        return (
                                                            <Select.Option value={i?.value}
                                                                           key={k}>{i?.label}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Next Payment Date"
                                            name="next_payment_date"
                                        >
                                            <DatePicker format={DATE_TIME_FORMAT.SHORT_DATE_US} className="w-100"/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Status"
                                            name="status"
                                        >
                                            <Select
                                                className="w-100"
                                                allowClear
                                            >
                                                {
                                                    (constants?.status).length > 0 && (constants?.status).map((i, k) => {
                                                        return (
                                                            <Select.Option value={k}
                                                                           key={k}>{i}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <PackagesList items={itemDetail.item} master={itemMasterData}
                                              getPackages={val => setResultPackages(val)}/>
                                <Form.Item className="text-end mt-4">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.push(PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.route, {group: PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.group})}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                                <Divider className="mt-0"/>
                                <p className="h4 mb-4">Action Log</p>
                                <Table
                                    columns={columnsTable({
                                        titleTable: getTitleTable(historyCols, [], []),
                                        component: {
                                            action: {
                                                render: (val, record) => (val || []).length > 0 && (val || []).map((i, k) => {
                                                    return <p>{i}</p>
                                                })
                                            }
                                        },
                                        itemName: "subscription_customer_recurring_update_history"
                                    })}
                                    pagination={false}
                                    dataSource={itemDetail?.history}
                                />
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
