import * as Types from '../types/Verify';

export const verifyRouteAction = (params) => ({type: Types.VERIFY_ROUTE_ACTION, params});
export const verifyRouteSuccess = (data) => ({type: Types.VERIFY_ROUTE_SUCCESS, data});

export const verifyGeneralAction = (params) => ({type: Types.VERIFY_GENERAL_ACTION, params});
export const verifyGeneralSuccess = (general) => ({type: Types.VERIFY_GENERAL_SUCCESS, general});

export const verifyInit = (params) => ({type: Types.VERIFY_INIT, params});
export const verifyFail = (params) => ({type: Types.VERIFY_FAILED, params});
