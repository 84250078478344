import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCreateUser,
    ActionInit,
    ActionGetItem,
    ActionUpdateUser
} from "../../../../data/hooks/user";

// ============== SELECT DATA ==============
import {selectFetching, selectMasterData, selectUserItem} from "../../../../data/reselects/userSelector";
import {common} from "../../../../data/mapping/Request/UserRequest";
import {Icon} from "../../../common";
import {isEmail} from "../../../../utils/validation";
import PERMISSION from "../../../../constants/permission";
import {getProfile} from "../../../../data/reselects/authSelector";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [masterRoles, setMasterRoles] = useState({}),
        [masterStatus, setMasterStatus] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        [isShowPassword, setIsShowPassword] = useState(true),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        getCreateUser = ActionCreateUser(),
        getUpdateUser = ActionUpdateUser(),
        getInitUsers = ActionInit(),
        getItemUser = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        profile = useSelector(getProfile()),
        itemUser = useSelector(selectUserItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (!profile?.user?.is_super_admin) {
                setIsShowPassword(false)
            }
            if (Object.keys(itemUser).length < 1) {
                getItemUser({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }

        return () => {
            getInitUsers();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemUser).length > 0) {
            form.setFieldsValue(itemUser)
        }
    }, [itemUser]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.roles || {}).length > 0) {
            setMasterRoles(itemMasterData?.roles)
        }
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            getUpdateUser({...val, props, id: itemUser?.id})
        } else {
            getCreateUser({...val, props})
        }
    }

    return (
        <>
            <HeaderAction title="Users Create"/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="First name"
                                            name={common.ACTION_FIELD.first_name}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Last name"
                                            name={common.ACTION_FIELD.last_name}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email"
                                            name={common.ACTION_FIELD.email}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Email!'
                                                }, ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        if ((itemUser[common.ACTION_FIELD.email] === value)) {
                                                            return Promise.resolve();
                                                        }
                                                        if (value) {
                                                            if (!isEmail(value) && ((value || "").length >= 10)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("Invalid email");
                                                        }
                                                        return Promise.reject("Invalid email")
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input disabled={isUpdate}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        isShowPassword && (
                                            <div className="col-4">
                                                <Form.Item
                                                    label="Password"
                                                    name={common.ACTION_FIELD.password}
                                                    rules={[{
                                                        required: !isUpdate,
                                                        message: 'Please input your Password!',
                                                    }, {type: "string", min: 6}, ({getFieldValue, getFieldsError}) => ({
                                                        validator(rule, value) {
                                                            if ((itemUser[common.ACTION_FIELD.password] === value)) {
                                                                return Promise.resolve();
                                                            }
                                                            if (value) {
                                                                if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject("'password' cannot be empty");
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    })]}
                                                >
                                                    <Input.Password/>
                                                </Form.Item>
                                            </div>
                                        )
                                    }
                                    <div className="col-4">
                                        <Form.Item
                                            label="Role"
                                            name={common.ACTION_FIELD.roles}
                                            rules={[{required: true, message: 'Please select your Role!'}]}
                                        >
                                            <Select
                                                className="w-100"
                                                mode="multiple"
                                                allowClear
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterRoles)).length > 0 && (Object.keys(masterRoles)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={i}
                                                                           key={k}>{masterRoles[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    {
                                        isUpdate && (
                                            <div className="col-4">
                                                <Form.Item
                                                    label="Status"
                                                    name={common.ACTION_FIELD.status}
                                                    rules={[{required: true, message: 'Please select your Role!'}]}
                                                >
                                                    <Select
                                                        className="w-100"
                                                        style={{width: 120}}>
                                                        {
                                                            (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {
                                                                return (
                                                                    <Select.Option value={i}
                                                                                   key={k}>{masterStatus[i]}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        )
                                    }
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.goBack()}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
