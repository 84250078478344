import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable, mappingSort,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
// import {ActionGetListUser, ActionInit, ActionMasterData} from '../../../../data/hooks/user';
import {ActionGetListCustomer} from '../../../../data/hooks/customer';
import {
    ActionGetListSubscriptionCustomer,
    ActionMasterData,
    ActionMasterState,
    ActionCreate,
    ActionRemoveSubscriptionCustomer
} from '../../../../data/hooks/subscriptionCustomer';
import {Tooltip, Button, Modal, Form, Input, Select, DatePicker} from 'antd';

// ============== SELECT DATA ==============
// import {selectListUser, selectMasterData} from '../../../../data/reselects/userSelector';
import {selectListCustomer} from '../../../../data/reselects/customerSelector';
import {
    selectFetching,
    selectErrors,
    selectListSubscriptionCustomer,
    selectMasterData,
    selectMasterState, selectIsAction,
} from '../../../../data/reselects/subscriptionCustomerSelector';
import SubscriptionCustomerResponse, {
    constants,
    listCols, responseQueryParams
} from "../../../../data/mapping/Response/SubscriptionCustomerResponse";
import {common} from "../../../../data/mapping/Request/UserRequest";
import AddSubscription from "./actions/AddSubscription";
import queryString from "query-string";
import moment from "moment";
import {filtersParams} from "../../../../data/mapping/Request/SubscriptionCustomerRequest";

const {confirm} = Modal;
const ModelFields = Object.getOwnPropertyNames(new SubscriptionCustomerResponse());
const Index = props => {
    const namePage = "subscription_customers",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            email: {
                fixed: 'left',
                width: 250,
                sorter: (a, b) => a.age - b.age,
                sortOrder: paramsFilters?.order_field === "email" ? mappingSort(paramsFilters?.order_value, 1) : ""
            },
            valid_time: {
                sorter: (a, b) => a.age - b.age,
                sortOrder: paramsFilters?.order_field === "valid_time" ? mappingSort(paramsFilters?.order_value, 1) : ""
            },
            status: {
                // width: 150,
                render: (val, record) => constants.status[val],
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <>
                        {/*<Icon type="edit-outlined" className="cursorPointer"*/}
                        {/*      onClick={() => props.history.push((PERMISSION.SUBSCRIPTION.CUSTOMER_UPDATE.route).replace(":id", record.id), {*/}
                        {/*          group: PERMISSION.SUBSCRIPTION.CUSTOMER_UPDATE.group,*/}
                        {/*          withParams: true*/}
                        {/*      })}/>*/}
                        {
                            record?.is_delete && (
                                <Icon type="delete" className="cursorPointer ms-2"
                                      onClick={() => onRemove(record.id)}/>
                            )
                        }
                    </>
                ),
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [isCreate, setIsCreate] = useState(false),
        [isOpenCreate, setIsOpenCreate] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListSubscriptionCustomers = ActionGetListSubscriptionCustomer(),
        getMasterData = ActionMasterData(),
        actionMasterState = ActionMasterState(),
        actionCreate = ActionCreate(),
        actionRemoveSubscriptionCustomer = ActionRemoveSubscriptionCustomer(),
        // ============== SELECT DATA ==============
        itemMasterState = useSelector(selectMasterState()),
        itemListSubscriptionCustomers = useSelector(selectListSubscriptionCustomer()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsAction = useSelector(selectIsAction()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            paramsFilters = responseQueryParams(paramsFilters)
            setPramsFilters(paramsFilters)
            // if (paramsFilters?.valid_time_from) {
            //     paramsFilters.valid_time_from = moment(paramsFilters?.valid_time_from);
            // }
            // if (paramsFilters?.valid_time_to) {
            //     paramsFilters.valid_time_to = moment(paramsFilters?.valid_time_to);
            // }
            // if (paramsFilters?.status !== undefined) {
            //     paramsFilters.status = parseInt(paramsFilters.status);
            // }
            form.setFieldsValue(paramsFilters)
            getListSubscriptionCustomers(removeObjectNullFull(paramsFilters));
        } else {
            getListSubscriptionCustomers()
        }
        getMasterData()
        actionMasterState();
    }, []);

    useEffect(() => {
        setIsOpenCreate(itemIsAction)
    }, [itemIsAction]);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListSubscriptionCustomers?.result || [])
        setPagination({
            total: itemListSubscriptionCustomers?.total || 0,
            totalPage: itemListSubscriptionCustomers?.total_page || 0,
            defaultCurrent: itemListSubscriptionCustomers?.page_index || 1,
            pageSize: itemListSubscriptionCustomers?.page_size || 10
        })
    }, [itemListSubscriptionCustomers]);

    const onTableChange = (pagination, filters, sorter) => {
        let filterSort = {};
        if (Object.keys(sorter).length > 0) {
            filterSort = {
                order_field: sorter?.order ? sorter?.field || "" : "",
                order_value: sorter?.order ? mappingSort(sorter?.order) : ""
            }
        }
        filters = omit({...paramsFilters, ...filters, ...filterSort}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route, filtersParams(newFilters)))
        getListSubscriptionCustomers(newFilters);
    };

    const onSearch = (val) => {
        let getFilters = removeObjectNullFull({...initData.filters, ...paramsFilters, ...val, page_index: 1});
        getFilters = omit(getFilters, ["order_field", "order_value"])
        setPramsFilters(getFilters)
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route, filtersParams(getFilters)))
        getListSubscriptionCustomers((getFilters));
    };

    const onRemove = (val) => {
        confirm({
            title: 'Do you want to delete this item?',
            // icon: <ExclamationCircleOutlined />,
            // content: 'Some descriptions',
            onOk() {
                actionRemoveSubscriptionCustomer({id: val, filters: paramsFilters})
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    return <>
        <HeaderAction title="Subscription Customer List" disabledFormatUpperCaseTitle isCreate
                      onClick={() => setIsOpenCreate(true)}/>
        <AddSubscription itemIsAction={itemIsAction} actionCreate={actionCreate} masterData={itemMasterState}
                         isCreate={isCreate} isOpenCreate={isOpenCreate} setIsOpenCreate={(e) => setIsOpenCreate(e)}/>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-3">
                        <Form.Item
                            label="Email"
                            name="email"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Status"
                            name="status"
                        >
                            <Select
                                className="w-100"
                                allowClear
                            >
                                {
                                    (Object.keys(itemMasterData?.status || {})).length > 0 && (Object.keys(itemMasterData?.status || {})).map((i, k) => {
                                        return (
                                            <Select.Option value={itemMasterData?.status[i]}
                                                           key={k}>{i}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Lead Type"
                            name="lead_type"
                        >
                            <Select
                                className="w-100"
                                allowClear
                            >
                                {
                                    (itemMasterData?.lead_type || []).length > 0 && itemMasterData?.lead_type.map((i, k) => {
                                        return (
                                            <Select.Option value={i}
                                                           key={k}>{i}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="State"
                            name="state"
                        >
                            <Select
                                className="w-100"
                                allowClear
                            >
                                {
                                    (itemMasterData?.state || []).length > 0 && itemMasterData?.state.map((i, k) => {
                                        return (
                                            <Select.Option value={i}
                                                           key={k}>{i}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Valid Time From"
                            name="valid_time_from"
                        >
                            <DatePicker picker="month" className="w-100"/>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Valid Time To"
                            name="valid_time_to"
                        >
                            <DatePicker picker="month" className="w-100"/>
                        </Form.Item>
                    </div>
                    <div className="col-1">
                        <Form.Item className="text-end" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 2000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
