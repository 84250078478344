import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Customer';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/customerService';
import * as FunctionAction from '../actions/customerAction';
import {message} from "antd";
import CustomerRequest, {AnnouncementRequest, CustomerCreditRequest} from "../mapping/Request/CustomerRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";
import {createAnnouncement} from "../services/customerService";

function* actionGetListCustomers() {
    yield takeLatest(Types.CUSTOMER_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters || {});
        const result = yield call(FunctionService.getListCustomers, params);
        yield put(FunctionAction.customerListSuccess(result));
    }, errorHandle(Types.CUSTOMER_FAILED)))
}

function* actionGetCreditListCustomers() {
    yield takeLatest(Types.CUSTOMER_CREDIT_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters || {});
        const result = yield call(FunctionService.getCreditListCustomers, params);
        yield put(FunctionAction.customerCreditListSuccess(result));
    }, errorHandle(Types.CUSTOMER_FAILED)))
}

function* actionAnnouncementList() {
    yield takeLatest(Types.CUSTOMER_ANNOUNCEMENT_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters || {});
        const result = yield call(FunctionService.getAnnouncementList, params);
        yield put(FunctionAction.customerAnnouncementListSuccess(result));
    }, errorHandle(Types.CUSTOMER_FAILED)))
}

function* actionGetItem() {
    yield takeLatest(Types.CUSTOMER_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getItem, action.params.id);
        yield put(FunctionAction.customerGetItemSuccess(result));
    }, errorHandle(Types.CUSTOMER_FAILED)))
}

function* actionGetMasterCustomers() {
    yield takeLatest(Types.CUSTOMER_MASTER_DATA_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterData, action.params);
        yield put(FunctionAction.customerMasterDataSuccess(result));
    }, errorHandle(Types.CUSTOMER_FAILED)))
}

function* createAction() {
    yield takeLatest(Types.CUSTOMER_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new CustomerRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        action.params.props.history.push(PERMISSION.CUSTOMER.VIEW.route, {group: PERMISSION.CUSTOMER.VIEW.group});
        yield put({type: Types.CUSTOMER_LIST_ACTION});
    }, errorHandle(Types.CUSTOMER_FAILED)))
}

function* addCreditAction() {
    yield takeLatest(Types.CUSTOMER_CREDIT_ADD_ACTION, Func.sagaWrapper(function* (action) {
        const data = new CustomerCreditRequest(action.params?.query).exportUpdate();
        const result = yield call(FunctionService.addCredit, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        // action.params.props.history.push(PERMISSION.CUSTOMER.VIEW.route, {group: PERMISSION.CUSTOMER.VIEW.group});
        yield put(FunctionAction.customerGetItemAction({id: action.params?.query?.id}));
        yield put(FunctionAction.customerCreditListAction({user_id: action.params?.query?.id}));
        yield put(FunctionAction.customerCreditAddSuccess(result));
    }, errorHandle(Types.CUSTOMER_FAILED)))
}

function* updateAction() {
    yield takeLatest(Types.CUSTOMER_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new CustomerRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        // yield put({ type: Types.HOTEL_GET_ITEM_ACTION });
        // yield put({ type: Types.VOUCHER_FAIL });
        yield put(FunctionAction.customerGetItemAction({id: action.params.id}));
        // yield put(VoucherActions.listVoucherAction(action.params.filters || {}));
    }, errorHandle(Types.CUSTOMER_FAILED)))
}


function* createAnnouncementAction() {
    yield takeLatest(Types.CUSTOMER_ANNOUNCEMENT_ACTION, Func.sagaWrapper(function* (action) {
        const data = new AnnouncementRequest(action.params).exportAction();
        const result = yield call(FunctionService.createAnnouncement, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["205"]);
        yield put(FunctionAction.customerAnnouncementSuccess(true));
        yield put({type: Types.CUSTOMER_ANNOUNCEMENT_LIST_ACTION});
    }, errorHandle(Types.CUSTOMER_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* customerSaga() {
    yield all([
        fork(actionGetListCustomers),
        fork(createAction),
        fork(actionGetMasterCustomers),
        fork(actionGetItem),
        fork(updateAction),
        fork(actionGetCreditListCustomers),
        fork(addCreditAction),
        fork(actionAnnouncementList),
        fork(createAnnouncementAction),
    ])
}
