import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as HookAction from '../actions/dashboardAction'

export const ActionDataInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.dashboardInit()),
        [dispatch]
    )
}

export const ActionUserActivity = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dashboardUserActivityAction(params)),
        [dispatch]
    )
}

export const ActionCityFilter = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dashboardCityFilterAction(params)),
        [dispatch]
    )
}

export const ActionDailyUserActivity = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dashboardDailyUserActivityAction(params)),
        [dispatch]
    )
}

export const ActionAllDashboard = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dashboardAllAction(params)),
        [dispatch]
    )
}

export const ActionImportCounty = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dashboardImportCountyAction(params)),
        [dispatch]
    )
}

export const ActionImportLeadType = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dashboardImportLeadTypeAction(params)),
        [dispatch]
    )
}

export const ActionTrackingDashboard = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dashboardTrackingAction(params)),
        [dispatch]
    )
}
