import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Banner';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/bannerService';
import * as FunctionAction from '../actions/bannerAction';
import {message} from "antd";
// import BannerRequest, {AnnouncementRequest, BannerCreditRequest} from "../mapping/Request/BannerRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";

function* actionGetListBanners() {
    yield takeLatest(Types.BANNER_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters || {});
        const result = yield call(FunctionService.getListBanners, params);
        yield put(FunctionAction.bannerListSuccess(result));
    }, errorHandle(Types.BANNER_FAILED)))
}

function* updateAction() {
    yield takeLatest(Types.BANNER_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(FunctionService.update, action.params);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        // yield put({ type: Types.HOTEL_GET_ITEM_ACTION });
        // yield put({ type: Types.VOUCHER_FAIL });
        yield put(FunctionAction.bannerUpdateSuccess());
        yield put(FunctionAction.bannerListAction());
        // yield put(VoucherActions.listVoucherAction(action.params.filters || {}));
    }, errorHandle(Types.BANNER_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* bannerSaga() {
    yield all([
        fork(actionGetListBanners),
        fork(updateAction),
    ])
}
