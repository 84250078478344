import React from 'react';
import PERMISSION from './constants/permission'

const routes = [
    {
        is_title: true,
        is_redirect: false,
        title: "Trang Chủ",
        group: "home",
        path: '',
        exact: false,
        icon: 'dashboard',
        show: false,
        sub_menu: [
            {
                is_redirect: false,
                title: 'Home',
                name: 'Home',
                path: PERMISSION.HOME.VIEW.route,
                icon: 'icon-handbag',
                group: "data",
                permission_key: PERMISSION.HOME.VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Banner',
                name: 'Banner',
                path: PERMISSION.HOME.BANNER_LIST.route,
                icon: 'icon-handbag',
                group: "data",
                permission_key: PERMISSION.HOME.BANNER_LIST.permission,
                exact: false,
                show: true,
            },
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "Data Management",
        name: 'Data Management',
        group: "data",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Import Data',
                name: 'Import Data',
                path: PERMISSION.DATA.VIEW.route,
                icon: 'icon-handbag',
                group: "data",
                permission_key: PERMISSION.DATA.VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Import Data',
                name: 'Import Data',
                path: PERMISSION.DATA.TRUNCATE.route,
                icon: 'icon-handbag',
                group: "data",
                permission_key: PERMISSION.DATA.TRUNCATE.permission,
                exact: false,
                show: false,
            }
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "Customer Management",
        name: 'Customer Management',
        group: "customer",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Listing',
                name: 'Listing',
                path: PERMISSION.CUSTOMER.VIEW.route,
                icon: 'icon-handbag',
                group: "customer",
                permission_key: PERMISSION.CUSTOMER.VIEW.permission,
                exact: false,
                show: true,
            }, {
                is_redirect: false,
                title: 'Ticket View',
                name: 'Ticket View',
                path: PERMISSION.CUSTOMER.TICKET_LIST.route,
                icon: 'icon-handbag',
                group: "customer",
                permission_key: PERMISSION.CUSTOMER.TICKET_LIST.permission,
                exact: false,
                show: true,
            }, {
                is_redirect: false,
                title: 'Announcement View',
                name: 'Announcement View',
                path: PERMISSION.CUSTOMER.ANNOUNCEMENT.route,
                icon: 'icon-handbag',
                group: "customer",
                permission_key: PERMISSION.CUSTOMER.ANNOUNCEMENT.permission,
                exact: false,
                show: true,
            }
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "Subscription",
        name: 'Subscription',
        group: "subscription",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Package View',
                name: 'Package View',
                path: PERMISSION.SUBSCRIPTION.PACKAGE_VIEW.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.PACKAGE_VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Customer View',
                name: 'Customer View',
                path: PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Transaction View',
                name: 'Transaction View',
                path: PERMISSION.SUBSCRIPTION.TRANSACTION_VIEW.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.TRANSACTION_VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Cancelation Feedback View',
                name: 'Cancelation Feedback View',
                path: PERMISSION.SUBSCRIPTION.CANCELATION_FEEDBACK_VIEW.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.CANCELATION_FEEDBACK_VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'General Package View',
                name: 'General Package View',
                path: PERMISSION.SUBSCRIPTION.GENERAL_PACKAGE_VIEW.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.GENERAL_PACKAGE_VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Geo Data',
                name: 'Geo Data',
                path: PERMISSION.SUBSCRIPTION.GEO_DATA.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.GEO_DATA.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Custom Package',
                name: 'Custom Package',
                path: PERMISSION.SUBSCRIPTION.CUSTOM_PACKAGE.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.CUSTOM_PACKAGE.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Customer Recurring',
                name: 'Customer Recurring',
                path: PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.permission,
                exact: false,
                show: true,
            },
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "User Management",
        name: 'User Management',
        group: "user",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Listing',
                name: 'Listing',
                path: PERMISSION.USER.VIEW.route,
                icon: 'icon-handbag',
                group: "user",
                permission_key: PERMISSION.USER.VIEW.permission,
                exact: false,
                show: true,
            }
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "Exclusive Content",
        name: 'Exclusive Content',
        group: "exclusive_content",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Post',
                name: 'Post',
                path: PERMISSION.EXCLUSIVE_CONTENT.VIEW.route,
                icon: 'icon-handbag',
                group: "exclusive_content",
                permission_key: PERMISSION.EXCLUSIVE_CONTENT.VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Listing',
                name: 'Listing',
                path: PERMISSION.EXCLUSIVE_CONTENT.LAYOUT_LIST.route,
                icon: 'icon-handbag',
                group: "exclusive_content",
                permission_key: PERMISSION.EXCLUSIVE_CONTENT.LAYOUT_LIST.permission,
                exact: false,
                show: true,
            }
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "",
        name: '',
        group: "errorPages",
        path: '/403',
        exact: false,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: []
    }, {
        is_title: true,
        is_redirect: false,
        title: "",
        name: '',
        group: "errorPages",
        path: '/404',
        exact: false,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: []
    }
];

export default routes;
