import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable, customEditorTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
import {
    ActionGetListSubscriptionTransaction,
    ActionMasterData,
    ActionUpdateSubscriptionTransaction
} from '../../../../data/hooks/subscriptionTransaction';
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker, Modal, Table, Space} from 'antd'

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListSubscriptionTransaction,
    selectMasterData
} from '../../../../data/reselects/subscriptionTransactionSelector';
import SubscriptionTransactionResponse, {
    listColDetail,
    listCols, responseQueryParams
} from "../../../../data/mapping/Response/SubscriptionTransactionResponse";
import {common} from "../../../../data/mapping/Request/UserRequest";
import {constants} from "../../../../data/mapping/Response/SubscriptionTransactionResponse";
import {CustomCellTableEditor, CustomRowTableEditor} from "../../../common/CustomBodyTableComponent";
import queryString from "query-string";
import moment from "moment";
import {filtersParams} from "../../../../data/mapping/Request/SubscriptionTransactionRequest";
import ModalTracker from "./ModalTracker";


const ModelFields = Object.getOwnPropertyNames(new SubscriptionTransactionResponse());
const Index = props => {
    const namePage = "subscription_transactions",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ['tracker'], ["id"]),
            modelItem: ModelFields,
        }),
        [isDetail, setIsDetail] = useState(false),
        [resultDetail, setResultDetail] = useState({}),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            status: {
                width: 150,
                render: (val, record) => constants.status[val],
            },
            tracker: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="eye-outlined" className="cursorPointer"
                                                onClick={() => {
                                                    setResultDetail({
                                                        id: record?.id,
                                                        query: record?.tracker
                                                    });
                                                    setIsDetail(true)
                                                }}/>,
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListSubscriptionTransactions = ActionGetListSubscriptionTransaction(),
        actionUpdateSubscriptionTransaction = ActionUpdateSubscriptionTransaction(),
        getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemListSubscriptionTransactions = useSelector(selectListSubscriptionTransaction()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            paramsFilters = responseQueryParams(paramsFilters)
            setPramsFilters(paramsFilters)
            form.setFieldsValue(paramsFilters)
            getListSubscriptionTransactions(removeObjectNullFull(paramsFilters));
        } else {
            getListSubscriptionTransactions()
        }
        // if ((itemListSubscriptionTransactions?.result || []).length < 1) {
        //     getListSubscriptionTransactions();
        // }
        getMasterData()
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListSubscriptionTransactions?.result || [])
        setPagination({
            total: itemListSubscriptionTransactions?.total || 0,
            totalPage: itemListSubscriptionTransactions?.total_page || 0,
            defaultCurrent: itemListSubscriptionTransactions?.page_index || 1,
            pageSize: itemListSubscriptionTransactions?.page_size || 10
        })
    }, [itemListSubscriptionTransactions]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(PERMISSION.SUBSCRIPTION.TRANSACTION_VIEW.route, filtersParams(newFilters)))
        getListSubscriptionTransactions(newFilters);
    };

    const onSearch = (val) => {
        let getFilters = removeObjectNullFull({...initData.filters, ...paramsFilters, ...val, page_index: 1});
        setPramsFilters(getFilters)
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(PERMISSION.SUBSCRIPTION.TRANSACTION_VIEW.route, filtersParams(getFilters)))
        getListSubscriptionTransactions(getFilters);
    };

    const onExport = val => {
        let getFilters = removeObjectNullFull({
            ...paramsFilters,
            is_export: 1
        });
        getListSubscriptionTransactions(removeObjectNullFull(getFilters));
    }

    return (
        <>
            <HeaderAction title="Subscription Transaction List" disabledFormatUpperCaseTitle/>
            <Modal
                // width={1000}
                destroyOnClose
                title="Transaction Tracker"
                visible={isDetail}
                footer={null}
                maskClosable={false}
                // onOk={() => {
                //     setResultDetail({})
                //     setIsDetail(false);
                // }}
                onCancel={() => {
                    setResultDetail({})
                    setIsDetail(false);
                }}
            >
                <ModalTracker
                    onCloseModal={e => setIsDetail(false)}
                    actionUpdate={e => actionUpdateSubscriptionTransaction({
                        ...e,
                        filters: {
                            ...initData.filters, ...paramsFilters,
                        }
                    })}
                    result={resultDetail}
                    isFetching={isFetching}
                    master={itemMasterData}
                />
            </Modal>
            <div className="container-fluid px-4">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSearch}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="row bg-white py-3 align-items-center">
                        <div className="col-4">
                            <Form.Item
                                label="Search"
                                name="search"
                            >
                                <Input allowClear/>
                            </Form.Item>
                        </div>
                        <div className="col-2">
                            <Form.Item
                                label="Status"
                                name="status"
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                >
                                    {
                                        (Object.keys(itemMasterData?.status || {})).length > 0 && (Object.keys(itemMasterData?.status || {})).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{itemMasterData?.status[i]}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-3">
                            <Form.Item
                                label="Type"
                                name="type"
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                >
                                    {
                                        Object.keys(itemMasterData?.type || {}).length > 0 && Object.keys(itemMasterData?.type || {}).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{itemMasterData?.type[i]}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-3">
                            <Form.Item
                                label="Subscription Type"
                                name="subscription_type"
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                >
                                    {
                                        Object.keys(itemMasterData?.subscription_type || {}).length > 0 && Object.keys(itemMasterData?.subscription_type || {}).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{itemMasterData?.subscription_type[i]}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-3">
                            <Form.Item
                                label="Month"
                                name="month"
                            >
                                <DatePicker picker="month" className="w-100"/>
                            </Form.Item>
                        </div>
                        <div className="col-1">
                            <Space>
                                <Form.Item className="text-end" label=" ">
                                    <Button type="primary" htmlType="submit">
                                        Search {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                                <Form.Item className="text-end ms-2" label=" ">
                                    <Button type="primary" htmlType="button" onClick={e => onExport(e)}>
                                        Export {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Space>
                        </div>
                    </div>
                </Form>
            </div>
            <ListComponent
                scroll={{x: 2300}}
                classParent="m-0 p-0 col-md-12 mt-4"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultList || [], initData.modelItem)}
                columns={columnsTable({
                    titleTable: initData.titleItemTable,
                    component: componentTable,
                    itemName: namePage
                })}
                pagination={{
                    total: pagination.total,
                    totalPage: pagination.totalPage,
                    current: pagination.defaultCurrent,
                    pageSize: pagination.pageSize
                }}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </>
    )
}

export default Index
