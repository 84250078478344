import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class DashboardRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {

    }

}
export const filtersParams = (filters) => {
    let newDateField = (filters?.date_filed || []).length > 0 ? [moment(filters?.date_filed[0]).format("YYYY-MM-DD"), moment(filters?.date_filed[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        date_from: newDateField[0],
        date_to: newDateField[1],
        timezone: filters?.timezone || ((new Date().toString().match(/([-\+][0-9]+)\s/)[1]).replaceAll("0", "")).replace("+", ""),
        level1: filters?.level1 || "",
        level2: filters?.level2 || "",
        is_export: filters?.is_export || "",
    })
}
