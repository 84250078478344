import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import CustomerResponse, {
    MasterCustomerResponse,
    CustomerCreditResponse,
    AnnouncementResponse
} from "../mapping/Response/CustomerResponse";

export function getListBanners(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.BANNER_LIST,
        params: params,
        parser: data => data?.data?.result,
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.BANNER_UPDATE,
        // endpointParams: {id: data?.id},
    }).put(data);
}
