import * as Types from '../types/SubscriptionCustomer';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    all: [],
    errors: [],
    masterData: {},
    masterState: {},
    isAction: false,
    // item: new HotelRequest().update(),
    item: {},
    data: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.SUBSCRIPTION_CUSTOMER_REMOVE_ACTION:
        case Types.SUBSCRIPTION_CUSTOMER_LIST_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_CUSTOMER_LIST_SUCCESS:
            return {...state, isFetching: false, data: action.subscriptionCustomers};
        case Types.SUBSCRIPTION_CUSTOMER_MASTER_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_CUSTOMER_MASTER_SUCCESS:
            return {...state, isFetching: false, masterData: action.masterData};
        case Types.SUBSCRIPTION_CUSTOMER_MASTER_STATE_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_CUSTOMER_MASTER_STATE_SUCCESS:
            return {...state, isFetching: false, masterState: action.masterState};
        case Types.SUBSCRIPTION_CUSTOMER_GET_TEM_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_CUSTOMER_GET_TEM_SUCCESS:
            return {...state, isFetching: false, item: action.item};
        case Types.SUBSCRIPTION_CUSTOMER_UPDATE_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_CUSTOMER_REMOVE_SUCCESS:
        case Types.SUBSCRIPTION_CUSTOMER_UPDATE_SUCCESS:
            return {...state, isFetching: false};
        case Types.SUBSCRIPTION_CUSTOMER_CREATE_ACTION:
            return {...state, isFetching: true, isAction: true};
        case Types.SUBSCRIPTION_CUSTOMER_CREATE_SUCCESS:
            return {...state, isFetching: false, isAction: false};
        case Types.SUBSCRIPTION_CUSTOMER_FAILED:
            return {...state, isFetching: false, errors: action.errors};
        case Types.SUBSCRIPTION_CUSTOMER_INIT:
            return initialState;
        default:
            return state;
    }
}
