import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as HookAction from '../actions/bannerAction'
import {bannerAnnouncementAction} from "../actions/bannerAction";

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.bannerInit()),
        [dispatch]
    )
}

export const ActionGetListBanner = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.bannerListAction(filters)),
        [dispatch]
    )
}

export const ActionUpdateBanner = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.bannerUpdateAction(params)),
        [dispatch]
    )
}
