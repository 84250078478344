import * as Types from '../types/Banner';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isFetchingCredit: false,
        isUpdateCreditSuccess: true,
        isCreateAnnouncements: false,
        isCreateAnnouncementsSuccess: false,
        actionSuccess: false,
    },
    all: [],
    errors: [],
    masterData: {},
    // item: new HotelRequest().update(),
    item: {},
    data: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.BANNER_LIST_ACTION:
            return {
                ...state,
                isFetching: true,
                statusAction: initialState.statusAction,
            };
        case Types.BANNER_LIST_SUCCESS:
            return {...state, statusAction: initialState.statusAction, isFetching: false, data: action.banners};
        case Types.BANNER_UPDATE_ACTION:
            return {...state, statusAction: initialState.statusAction, isFetching: true};
        case Types.BANNER_UPDATE_SUCCESS:
            return {
                ...state, statusAction: {
                    ...initialState.statusAction,
                    actionSuccess: true
                }, isFetching: false
            };
        case Types.BANNER_FAILED:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                statusAction: initialState.statusAction,
            };
        case Types.BANNER_INIT:
            return initialState;
        default:
            return state;
    }
}
