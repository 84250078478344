import React, {useState, useEffect} from 'react';
import {connect, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";

import HeaderAction from "../../common/layout/HeaderAction";
import {Icon, Select} from "../../common";
import {Form, Input, Checkbox, Upload, Button, Popconfirm, message, Modal, Tabs} from "antd"
import {UploadOutlined} from "@ant-design/icons";

import {
    dataImportAction,
    dataTruncateAction,
    dataMasterLeadTypeAction,
    dataMasterOtherLeadTypeAction
} from "../../../data/actions/dataAction";
import {capitalizeFirstLetter, customOptionSelect} from "../../../utils/functions";
import {selectFetching, selectStatusAction} from "../../../data/reselects/dataSelector";
import {ActionDataInit} from "../../../data/hooks/data";
import FormOtherLeadType from "./components/FormOtherLeadType";

const Index = (props) => {
    const [fileList, setFileList] = useState([]),
        [masterLeadTypes, setMasterLeadTypes] = useState([]),
        [showMessageUpload, setShowMessageUpload] = useState(false),
        [selectLeadType, setSelectLeadType] = useState(),
        [uploading, setUploading] = useState(false),
        getDataInit = ActionDataInit(),
        isFetchingData = useSelector(selectFetching()),
        [truncating, setTruncating] = useState(false);

    useEffect(() => {
        props.actionGetMasterDatLeadType();
        props.actionGetMasterDataOtherLeadType();
        return () => getDataInit();
    }, []);

    useEffect(() => {
        setUploading(props.data.isFetching)
    }, [isFetchingData]);

    useEffect(() => {
        if (props.data.dataImport?.message_type) {
            setShowMessageUpload(true)
        }
    }, [props.data.dataImport]);

    useEffect(() => {
        if (Object.keys(props.data.masterLeadType).length > 0) {
            let newResult = [];
            Object.values(props.data.masterLeadType).map(i => newResult.push({
                name: capitalizeFirstLetter(i?.title),
                value: i?.key
            }))
            setMasterLeadTypes(newResult)
        }
    }, [props.data.masterLeadType]);


    useEffect(() => {
        setTruncating(props.data.isTruncate)
    }, [props.data.isTruncate]);


    const handleUpload = () => {
        // const formData = new FormData();
        // fileList.forEach(file => {
        //     formData.append('files', file);
        // });
        // formData.append('files', fileList[0]);

        if (selectLeadType) {
            props.actionImportData({file: fileList[0], leadType: selectLeadType})
            setFileList([])
            // setUploading(true)
        } else {
            message.error("Please select Lead Type")
        }
    };

    const propsUpload = {
        onRemove: file => {
            setFileList([])
            return false
        },
        beforeUpload: file => {
            setFileList([file])
            return false;
        },
        fileList,
    };

    return (
        <>
            <HeaderAction title="Import data"/>
            {
                props.data.dataImport?.message_type && (
                    <Modal width={600} title={props.data.dataImport?.message_type}
                           className={props.data.dataImport?.message_type ? `import-data__popup-message--${(props.data.dataImport?.message_type || "").toLowerCase()}` : ""}
                           destroyOnClose visible={showMessageUpload}
                           onOk={() => setShowMessageUpload(false)}
                           onCancel={() => setShowMessageUpload(false)}
                           maskClosable={false}
                           footer={<button type="button" onClick={() => setShowMessageUpload(false)}
                                           className="ant-btn ant-btn-primary">
                               <span>OK</span></button>}>
                        <div style={{whiteSpace: "pre-wrap"}}>{props.data.dataImport?.message}</div>
                    </Modal>
                )
            }
            <div className="container-fluid">
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Normal Lead Type" key="1">
                        <div className="bg-white p-4">
                            <div className="float-start me-4">
                                <p className="mb-2">File Import</p>
                                <Upload {...propsUpload} maxCount={1} className="data-upload">
                                    <Button icon={<Icon type="upload-outlined"/>}>Select File</Button>
                                </Upload>
                            </div>
                            <div className="float-start me-4">
                                <label>Lead Type <span style={{color: "red"}}>*</span></label><br/>
                                <Select
                                    className="width-200px"
                                    onChange={(e) => setSelectLeadType(e)}
                                    placeholder="Select Lead Type"
                                    customOptions={masterLeadTypes}
                                />
                            </div>
                            <div className="float-start">
                                <p className="mb-2">&nbsp;</p>
                                <Button
                                    type="primary"
                                    onClick={handleUpload}
                                    disabled={(fileList.length === 0) || !selectLeadType}
                                    loading={uploading}
                                >
                                    {uploading ? 'Uploading' : 'Import data'}
                                </Button>
                            </div>
                            <div className="clearfix"/>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Other Lead Type" key="2">
                        <FormOtherLeadType/>
                    </Tabs.TabPane>
                </Tabs>
                {/*<div className="row bg-white py-3 align-items-center">*/}
                {/*    <div className="col-1">*/}
                {/*        <p className="mb-2">File Import</p>*/}
                {/*        <Upload {...propsUpload}>*/}
                {/*            <Button icon={<Icon type="upload-outlined"/>}>Select File</Button>*/}
                {/*        </Upload>*/}
                {/*    </div>*/}
                {/*    <div className="col-1 ms-4">*/}
                {/*        <label>Lead Type <span style={{color: "red"}}>*</span></label>*/}
                {/*        <Select*/}
                {/*            onChange={(e) => setSelectLeadType(e)}*/}
                {/*            placeholder="Select Lead Type"*/}
                {/*            customOptions={masterLeadTypes}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <div className="col-2 text-center">*/}
                {/*        <p className="mb-2">&nbsp;</p>*/}
                {/*        <Button*/}
                {/*            type="primary"*/}
                {/*            onClick={handleUpload}*/}
                {/*            disabled={(fileList.length === 0) || !selectLeadType}*/}
                {/*            loading={uploading}*/}
                {/*        >*/}
                {/*            {uploading ? 'Uploading' : 'Import data'}*/}
                {/*        </Button>*/}
                {/*    </div>*/}
                {/*    /!*<div className="col-2">*!/*/}
                {/*    /!*    <Popconfirm*!/*/}
                {/*    /!*        title="Are you sure to delete this task?"*!/*/}
                {/*    /!*        onConfirm={() => props.actionTruncateData()}*!/*/}
                {/*    /!*        onCancel={null}*!/*/}
                {/*    /!*        okText="Yes"*!/*/}
                {/*    /!*        cancelText="No"*!/*/}
                {/*    /!*    >*!/*/}
                {/*    /!*        <Button*!/*/}
                {/*    /!*            type="danger"*!/*/}
                {/*    /!*            // onClick={() => props.actionTruncateData()}*!/*/}
                {/*    /!*            // disabled={fileList.length === 0}*!/*/}
                {/*    /!*            loading={truncating}*!/*/}
                {/*    /!*        >*!/*/}
                {/*    /!*            {truncating ? 'Truncating' : 'Truncate data'}*!/*/}
                {/*    /!*        </Button>*!/*/}
                {/*    /!*    </Popconfirm>*!/*/}
                {/*    /!*</div>*!/*/}
                {/*</div>*/}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        data: state.dataReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionImportData: (params) => {
            dispatch(dataImportAction(params))
        },
        actionTruncateData: (params) => {
            dispatch(dataTruncateAction(params))
        },
        actionGetMasterDatLeadType: (params) => {
            dispatch(dataMasterLeadTypeAction(params))
        },
        actionGetMasterDataOtherLeadType: (params) => {
            dispatch(dataMasterOtherLeadTypeAction(params))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))
