import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/subscriptionCustomerAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.subscriptionCustomerInit()),
        [dispatch]
    )
}

export const ActionGetListSubscriptionCustomer = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.subscriptionCustomerListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerGetItemAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerMasterDataAction(params)),
        [dispatch]
    )
}

export const ActionMasterState = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerMasterStateAction(params)),
        [dispatch]
    )
}

export const ActionCreate = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerCreateAction(params)),
        [dispatch]
    )
}

export const ActionUpdateSubscriptionCustomer = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerUpdateAction(params)),
        [dispatch]
    )
}


export const ActionRemoveSubscriptionCustomer = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerRemoveAction(params)),
        [dispatch]
    )
}
