import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/subscriptionCustomerRecurringAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.subscriptionCustomerRecurringInit()),
        [dispatch]
    )
}

export const ActionGetListSubscriptionCustomerRecurring = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.subscriptionCustomerRecurringListAction(filters)),
        [dispatch]
    )
}

export const ActionUpdateSubscriptionCustomerRecurring = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerRecurringUpdateAction(params)),
        [dispatch]
    )
}

export const ActionCreateSubscriptionCustomerRecurring = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerRecurringCreateAction(params)),
        [dispatch]
    )
}

export const ActionGetItemSubscriptionCustomerRecurring = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerRecurringGetItemAction(params)),
        [dispatch]
    )
}


export const ActionMasterSubscriptionCustomerRecurring = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomerRecurringMasterAction(params)),
        [dispatch]
    )
}
