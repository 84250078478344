import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt} from "../../../utils/functions";
import {v4 as uuidv4} from "uuid";

export default class CustomerRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.email = data?.email || "";
        this.roles = data?.roles || [];
        this.password = data?.password || "";
        this.phone_number = data?.phone_number || "";
        this.account_manager = data?.account_manager || "";
        this.status = formatInt(data?.status) || 0;
        this.is_bypass_multiple_login = data?.is_bypass_multiple_login ? 1 : 0;
    }

    exportCreate() {
        return {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            // roles: this.roles,
            password: this.password,
            phone_number: this.phone_number,
            account_manager: this.account_manager,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            roles: this.roles,
            password: this.password,
            status: this.status,
            phone_number: this.phone_number,
            account_manager: this.account_manager,
            is_bypass_multiple_login: this.is_bypass_multiple_login,
        }
    }
}

export class CustomerCreditRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.note = data?.note || "";
        this.credit = data?.credit || "";
    }

    exportUpdate() {
        return {
            user_id: this.id,
            note: this.note,
            credit: this.credit,
        }
    }
}

export const common = {
    ACTION_FIELD: {
        first_name: "first_name",
        last_name: "last_name",
        credit: "credit",
        api_key: "api_key",
        email: "email",
        roles: "roles",
        status: "status",
        is_bypass_multiple_login: "is_bypass_multiple_login",
        password: "password",
        phone_number: "phone_number",
        account_manager: "account_manager",
    },
}

export class AnnouncementRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.data = this.generateItemsData(data || []);
    }

    generateItemsData(items = []) {
        let newItem = [];
        if (items.length > 0) {
            items.map(i => newItem.push({
                id: i?.id || uuidv4(),
                title: i?.title || "",
                url: i?.url || "",
                popup_content: i?.popup_content || [],
                popup_image_url: i?.popup_image_url || "",
                is_popup: Number(i?.is_popup),
                startTime: this.generateDateTime(i?.date_time)[0],
                endTime: this.generateDateTime(i?.date_time)[1],
            }))
        }
        return newItem
    }

    generateDateTime(dateTime = []) {
        let newDateTime = [];
        if (dateTime.length > 0) {
            newDateTime = [new Date(dateTime[0])?.getTime(), new Date(dateTime[1])?.getTime()]
        }
        return newDateTime;
    }

    exportAction() {
        return {
            data: this.data
        }
    }
}
