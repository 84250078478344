import {Button, DatePicker, Form, Modal, Spin} from "antd";
import {DATE_TIME_FORMAT} from "../../../../../constants/define";
import {Icon} from "../../../../common";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {
    ActionAllDashboard,
    ActionCityFilter, ActionDailyUserActivity,
    ActionDataInit,
    ActionUserActivity
} from "../../../../../data/hooks/dashboard";
import {useSelector} from "react-redux";
import {
    selectCityFilter,
    selectDailyUserActivity,
    selectFetching,
    selectUserActivity
} from "../../../../../data/reselects/dashboardSelector";
import {Chart} from "react-google-charts";

const {RangePicker} = DatePicker,
    initLevel = {
        level1: false,
        level2: false,
    },
    dataInit = {
        userActivity: {data: [], options: {}},
        cityFilter: {data: [], options: {}}
    };

const ActiveUserComponent = props => {
    const [isFetching, setIsFetching] = useState(false),
        [form] = Form.useForm(),
        [isDetail, setIsDetail] = useState(initLevel),
        [isOpenChartDetail, setIsOpenChartDetail] = useState(false),
        [keyLevel1, setKeyLevel1] = useState(""),
        [filters, setFilters] = useState({}),
        // [titleChartDetail, setTitleChartDetail] = useState("Detail Chart"),
        [resultInit, setResultInit] = useState(dataInit),
        [resultChartDetailLevel1, setResultChartDetailLevel1] = useState(dataInit.userActivity),
        [resultChartDetailLevel2, setResultChartDetailLevel2] = useState(dataInit.userActivity),
        [moduleSelect, setModuleSelect] = useState(""),
        [positionLevel1, setPositionLevel1] = useState(),
        [positionLevel2, setPositionLevel2] = useState(),
        actionUserActivity = ActionUserActivity(),
        actionDailyUserActivity = ActionDailyUserActivity(),
        getCityFilter = ActionCityFilter(),
        getDashboardInit = ActionDataInit(),
        actionAllDashboard = ActionAllDashboard(),
        itemFetching = useSelector(selectFetching()),
        itemCityFilter = useSelector(selectCityFilter()),
        itemUserActivity = useSelector(selectUserActivity()),
        itemDailyUserActivity = useSelector(selectDailyUserActivity());

    const onSelectChartInit = (props, module, level = "level1") => {
        if ((props.chartWrapper.getChart().getSelection()).length > 0) {
            const position = props.chartWrapper.getChart().getSelection()[0]?.row
            if (level === "level1") {
                setPositionLevel1(position)
                setResultChartDetailLevel1(dataInit.userActivity)
                setResultChartDetailLevel2(dataInit.userActivity)
                setIsDetail({...isDetail, level1: true, level2: false})
                setKeyLevel1(resultInit[module].data[position + 1][0])
                if (module === "userActivity") {
                    actionDailyUserActivity({...filters, level1: resultInit[module].data[position + 1][0]})
                }
                // props.chartWrapper.setDataTable([{row: 0, column: null}])
            }
            if (level === "level2") {
                setPositionLevel2(position)
                setResultChartDetailLevel2(dataInit.userActivity)
                setIsDetail({...isDetail, level1: false, level2: true})
                if (module === "userActivity") {
                    actionDailyUserActivity({
                        ...filters,
                        level1: keyLevel1,
                        level2: resultChartDetailLevel1.data[position + 1][0]
                    })
                }
            }
            setModuleSelect(module);
            setIsOpenChartDetail(true);
        }
    }

    useEffect(() => {
        // actionDailyUserActivity();
        // getDashboardInit();
        form.setFieldsValue({date_filed: [moment(moment().startOf('month').format(DATE_TIME_FORMAT.SHORT_DATE_US), DATE_TIME_FORMAT.SHORT_DATE_US), moment(moment().endOf('month').format(DATE_TIME_FORMAT.SHORT_DATE_US), DATE_TIME_FORMAT.SHORT_DATE_US)]})
        return () => {
            setFilters({})
        }
    }, []);

    useEffect(() => {
        if (!isDetail.level1 && !isDetail.level2) {
            setResultInit({
                userActivity: itemDailyUserActivity?.cma,
            })
        }
        if (isDetail.level1) {
            setResultChartDetailLevel1(itemDailyUserActivity?.cma)
        }
    }, [itemDailyUserActivity]);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    const onSearch = (val) => {
        setIsDetail(initLevel);
        setFilters(val)
        actionDailyUserActivity(val);
    };

    const onClosePopup = () => {
        setIsDetail(initLevel);
        setIsOpenChartDetail(false);
        setPositionLevel1(null);
        setPositionLevel2(null);
        setModuleSelect("");
    }

    return (
        <>
            {
                ((resultInit.userActivity?.data || []).length > 0) && (
                    <div className={`col-md-12`}>
                        <div className={`ibox mb-4`}>
                            <div className="ibox-content">
                                <ChartComponent
                                    options={{
                                        ...resultInit.userActivity.options,
                                        chartArea: {height: '70%', width: '90%'},
                                        titleTextStyle: {fontSize: 20},
                                        legend: {position: "none"},
                                        hAxis: {
                                            slantedText: true,
                                            slantedTextAngle: -45
                                        }
                                    }}
                                    items={resultInit.userActivity}
                                    selectModule="userActivity"
                                    selectLevel="level1"
                                    onSelectChartInit={onSelectChartInit}/>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ActiveUserComponent

const ChartComponent = (props) => {
    return (
        <div className={`chart-component ${props.wrapperClass ? props.wrapperClass : ""}`}>
            {/*<Chart*/}
            {/*    width={'100%'}*/}
            {/*    height={'20vw'}*/}
            {/*    chartType="PieChart"*/}
            {/*    loader={<div>Loading Chart</div>}*/}
            {/*    data={props.items.data}*/}
            {/*    options={props.options}*/}
            {/*    chartEvents={[*/}
            {/*        {*/}
            {/*            eventName: "select",*/}
            {/*            callback: (charts) => props.onSelectChartInit ? props.onSelectChartInit(charts, props.selectModule, props.selectLevel) : null*/}
            {/*        }*/}
            {/*    ]}*/}
            {/*    rootProps={{'data-testid': '1'}}*/}
            {/*/>*/}

            {
                (props.items.data.length > 0) ? (
                    <>
                        {/*<h4 className="mb-4">{props.options?.title}</h4>*/}
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="40vw"
                            data={props.items.data}
                            options={props.options}
                            chartEvents={[
                                // {
                                //     eventName: "select",
                                //     callback: (charts) => props.onSelectChartInit ? props.onSelectChartInit(charts, props.selectModule, props.selectLevel) : null
                                // }
                            ]}
                            loader={<div>Loading Chart</div>}
                        />
                    </>
                ) : (Object.keys(props.items?.options).length > 0) ? (
                    <div className="text-center">
                        <img src="/layout/no_data_found.svg" alt=""/>
                        <p>No data Found</p>
                    </div>
                ) : ""
            }
        </div>
    )
}
