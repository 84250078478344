import {Button, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Select, Spin, Table} from "antd";
import React, {useEffect, useState} from "react";
import SubscriptionCustomerRecurringResponse, {
    constants,
    listCols,
    packagesCols
} from "../../../../../data/mapping/Response/SubscriptionCustomerRecurringResponse";
import {columnsTable, getTitleTable} from "../../../../../utils/functions";
import {find} from "lodash";
import {Icon} from "../../../../common";
import {common} from "../../../../../data/mapping/Request/SubscriptionCustomerRequest";
import {isEmail} from "../../../../../utils/validation";
import {DATE_TIME_FORMAT} from "../../../../../constants/define";
import {v4 as uuidv4} from 'uuid';
import PERMISSION from "../../../../../constants/permission";


const PackagesList = props => {
    const [form] = Form.useForm(),
        [selectCode, setSelectCode] = useState(""),
        [selectPackage, setSelectPackage] = useState(""),
        [optionsType, setOptionsType] = useState([]),
        [isCreate, setIsCreate] = useState(false),
        [isUpdate, setIsUpdate] = useState(false),
        [itemDetail, setItemDetail] = useState({}),
        [resultPackages, setResultPackages] = useState([]),
        componentTable = {
            package: {
                width: 500,
                render: (val, rec) => find(props?.master?.packages, {key: rec?.package_id})?.value
            },
            state: {
                width: 200,
                render: (val, rec) => find(props?.master?.states, {code: rec?.code})?.state_name
            },
            price: {
                width: 300,
            },
            action: {
                width: 50,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => (
                    <>
                        <Icon type="edit-outlined" className="cursorPointer"
                              onClick={() => {
                                  form.resetFields();
                                  if (record?.code && record?.type) {
                                      let newTypes = [];
                                      newTypes = find(props?.master?.states, {code: record?.code})[(record?.type).toLowerCase()]
                                      setOptionsType(newTypes)
                                  }
                                  setIsUpdate(true)
                                  form.setFieldsValue(record)
                                  setIsCreate(true)
                              }}/>
                        <Popconfirm
                            title="Are you sure to delete this item?"
                            onConfirm={() => {
                                if (find(resultPackages, {id: record?.id})) {
                                    let newItems = [];
                                    newItems = resultPackages.filter(i => i?.id !== record?.id)
                                    setResultPackages(newItems)
                                }
                            }}
                            onCancel={e => {
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Icon type="delete" className="cursorPointer ms-2"/>
                        </Popconfirm>
                    </>
                ),
            }
        };

    useEffect(() => {
        if ((props?.items || []).length > 0) {
            setResultPackages(props?.items)
        }
    }, [props?.items]);

    useEffect(() => {
        props?.getPackages(resultPackages)
    }, [resultPackages]);

    useEffect(() => {
        if (form.getFieldValue("code") && form.getFieldValue("type")) {
            let newTypes = [];
            newTypes = find(props?.master?.states, {code: form.getFieldValue("code")})[form.getFieldValue("type").toLowerCase()]
            setOptionsType(newTypes)
        }
    }, [selectCode, selectPackage]);


    const onFinish = val => {
        let newItems = [...resultPackages]
        if (find(newItems, {id: val?.id})) {
            let getIndex = newItems.findIndex(i => i?.id === val?.id)
            // newItems = newItems.filter(i => i?.id !== val?.id);
            newItems[getIndex] = val
            // newItems.push(val)
        } else {
            newItems.push(val)
        }
        setResultPackages(newItems)
        form.resetFields();
        setOptionsType([]);
        setIsUpdate(false);
        setItemDetail({});
        setIsCreate(false);
    }

    const onValuesChange = (val, all) => {
        if (val?.code) {
            setSelectCode(val?.code)
            form.setFieldsValue({
                item: ""
            })
        } else if (val?.package_id) {
            setSelectPackage(val?.package_id)
            form.setFieldsValue({
                type: find(props?.master?.packages, {key: val?.package_id})?.type,
                item: ""
            })
        }
    }
    return (
        <>
            <Modal
                width={1000}
                title={`${isUpdate ? "Update" : "Create New"} Package`}
                destroyOnClose
                visible={isCreate}
                onOk={() => form.submit()}
                onCancel={() => {
                    form.resetFields();
                    setOptionsType([]);
                    setIsUpdate(false);
                    setItemDetail({});
                    setIsCreate(false);
                }}
                maskClosable={false}
            >
                <Spin spinning={false}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onValuesChange={onValuesChange}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            className="d-none"
                            name="type"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            className="d-none"
                            name="id"
                            initialValue={uuidv4()}
                        >
                            <Input disabled/>
                        </Form.Item>
                        <div className="row">
                            <div className="col-6">
                                <Form.Item
                                    label="Package"
                                    name="package_id"
                                >
                                    <Select
                                        className="w-100"
                                        allowClear
                                    >
                                        {
                                            (props?.master?.packages || []).length > 0 && (props?.master?.packages || []).map((i, k) => {
                                                return (
                                                    <Select.Option value={i?.key}
                                                                   all={i}
                                                                   key={k}>{i?.value}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    label="State"
                                    name="code"
                                >
                                    <Select
                                        className="w-100"
                                        allowClear
                                    >
                                        {
                                            (props?.master?.states_dropdown || []).length > 0 && (props?.master?.states_dropdown || []).map((i, k) => {
                                                return (
                                                    <Select.Option value={i?.value}
                                                                   key={k}>{i?.label}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    label="Item"
                                    name="item"
                                >
                                    <Select
                                        showSearch
                                        className="w-100"
                                        allowClear
                                        // disabled={!(optionsType.length > 0) && !form.setFieldsValue("item")}
                                        filterOption={(input, option) =>
                                            (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {
                                            (optionsType || []).length > 0 && (optionsType || []).map((i, k) => {
                                                return (
                                                    <Select.Option value={i}
                                                                   key={k}>{i}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    label="Price"
                                    name="price"
                                >
                                    <InputNumber className="w-100"/>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </Modal>
            <div className='row'>
                <div className="col-6">
                    <p className="h4 mb-4">Packages</p>
                </div>
                <div className="col-6 text-end">
                    <Button type="primary" htmlType="button" onClick={e => setIsCreate(true)}>
                        Create
                    </Button>
                </div>
            </div>
            <Table
                className={Object.keys(props?.master).length > 0 ? "" : "d-none"}
                pagination={false}
                rowKey="id"
                columns={columnsTable({
                    titleTable: getTitleTable(packagesCols, ['action'], ["id"]),
                    component: componentTable,
                    itemName: "subscription_customer_recurring_update_packages"
                })}
                // columns={getTitleTable(packagesCols, ['action'], ["id"])}
                dataSource={resultPackages}
            />
        </>
    )
}

export default PackagesList
