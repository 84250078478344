import {Button, DatePicker, Form, Input, Modal, Popconfirm, Spin, Table, Upload} from "antd";
import HeaderAction from "../../common/layout/HeaderAction";
import React, {useEffect, useState} from "react";
import {ActionGetListBanner, ActionUpdateBanner} from "../../../data/hooks/banner";
import {useSelector} from "react-redux";
import {selectFetching, selectListBanners, selectStatusAction} from "../../../data/reselects/bannerSelector";
import {columnsTable, genaratePaginateFilterSort, getDataSource, setParamsUrlFilter} from "../../../utils/functions";
import {ListComponent} from "../../common/ListComponent";
import {find, omit} from "lodash";
import PERMISSION from "../../../constants/permission";
import {Icon} from "../../common";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {LoadingOutlined, PaperClipOutlined} from "@ant-design/icons";
import {ActionUpload, ActionUploadInitStore} from "../../../data/hooks/upload";
import {
    selectFilesUpload,
    selectFetching as selectFetchingUpload,
    selectStatusAction as selectStatusActionUpload
} from "../../../data/reselects/uploadSelector";


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const Index = props => {
    const [paramsFilters, setPramsFilters] = useState({}),
        [fileList, setFileList] = useState([]),
        [resultList, setResultList] = useState([]);
    const [isFetching, setIsFetching] = useState(false),
        [isCreate, setIsCreate] = useState(false),
        [typeAction, setTypeAction] = useState(0),
        [selectedItem, setSelectedItem] = useState(),
        [files, setFiles] = useState({}),
        [imageUrl, setImageUrl] = useState(),
        [loadingUpload, setLoadingUpload] = useState(false),
        [form] = Form.useForm(),
        actionGetListBanner = ActionGetListBanner(),
        actionUpdateBanner = ActionUpdateBanner(),
        actionUpload = ActionUpload(),
        actionUploadInitStore = ActionUploadInitStore(),
        itemFetching = useSelector(selectFetching()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemFileUpload = useSelector(selectFilesUpload()),
        itemIsUpload = useSelector(selectFetchingUpload()),
        itemList = useSelector(selectListBanners());

    const componentTable = {
        banner: {
            width: 150,
            align: "center",
            render: (img, record) => <img className="width-150px height--50px img-thumbnail" alt="" src={img}/>
        },
        action: {
            width: 100,
            align: 'center',
            key: 'action',
            fixed: 'right',
            render: (text, record, key) => (
                <>
                    <Icon type="edit-outlined" className="cursorPointer"
                          onClick={() => {
                              setTypeAction(1)
                              setSelectedItem(key)
                              form.setFieldsValue({
                                  url: record?.url
                              })
                              setFiles({file_url: record?.banner})
                              setIsCreate(true)
                          }}/>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => {
                            let response = [...resultList]
                            response = response.filter((i, k) => k !== key)
                            // console.log(9999, response)
                            actionUpdateBanner({data: response})
                        }}
                        onCancel={e => {
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Icon type="delete" className="cursorPointer ms-2"/>
                    </Popconfirm>
                </>
            ),
        }
    }

    useEffect(() => {
        actionGetListBanner()
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        setResultList(itemList || [])
    }, [itemList]);

    useEffect(() => {
        setFiles(itemFileUpload)
    }, [itemFileUpload]);

    useEffect(() => {
        if (itemStatusAction?.actionSuccess) {
            onCloseModal()
        }
    }, [itemStatusAction]);

    useEffect(() => {
        setLoadingUpload(itemIsUpload)
    }, [itemIsUpload]);

    const onTableChange = (pagination, filters, sorter) => {
        // filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        // let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        // setPramsFilters(newFilters)
        // setInitData({
        //     ...initData,
        //     filters: newFilters
        // });
        // getListCustomers(newFilters);
    };

    const onFinish = val => {
        let response = [...resultList]
        if (typeAction === 0) {
            response.push({
                url: val?.url,
                banner: files?.file_url
            })
            // setResultList(response)
            actionUpdateBanner({data: response})
        } else {
            response[selectedItem] = {
                url: val?.url,
                banner: files?.file_url
            }
            actionUpdateBanner({data: response})
        }
    }

    const propsUpload = {
        onRemove: file => {
            setFileList([])
            return false
        },
        beforeUpload: file => {
            setFileList([file])
            return false;
        },
        fileList,
    };

    const handleChangeImage = info => {
        if (info.file.status === 'uploading') {
            setFiles({})
            setLoadingUpload(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                    setLoadingUpload(false);
                    setImageUrl(imageUrl)
                }
            );
        }
    };

    const onCloseModal = val => {
        setSelectedItem("")
        actionUploadInitStore();
        setIsCreate(false);
        form.resetFields();
        setTypeAction(0)
        setFiles({})
        setImageUrl("")
        setLoadingUpload(false)
    }

    return (
        <>
            <HeaderAction title="Banners List" isCreate onClick={e => setIsCreate(true)}/>
            <Modal
                title={(typeAction === 0) ? "Create Banner" : "Update Banner"}
                maskClosable={false}
                destroyOnClose
                visible={isCreate}
                footer={false}
                onCancel={onCloseModal}>
                <Spin spinning={isFetching}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-12">
                                <Form.Item
                                    label="Url"
                                    name="url"
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="col-12">
                                {/*<Upload {...propsUpload} maxCount={1} className="data-upload">*/}
                                {/*    <Button icon={<Icon type="upload-outlined"/>}>Select File</Button>*/}
                                {/*</Upload>*/}
                                <p className="mb-2">Image</p>
                                <div className="text-center overflow-hidden">
                                    <Upload
                                        name="file"
                                        listType="picture-card"
                                        className="avatar-uploader h-100 profile--upload upload-full-wrap"
                                        showUploadList={false}
                                        action={(files) => actionUpload({files})}
                                        customRequest={() => {
                                        }}
                                        // beforeUpload={beforeUpload}
                                        onChange={handleChangeImage}
                                    >
                                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : (
                                            <div className="text-center w-100">
                                                {loadingUpload ? <>
                                                    <LoadingOutlined/>
                                                    <div style={{marginTop: 8}}>Upload</div>
                                                </> : files?.file_url ? <>
                                                    <div className="profile__wrap">
                                                        <img
                                                            src={`${files?.file_url ? files?.file_url : "http://dev.realestate/layout/banner/affiliate%20banner%201.png"}`}
                                                            alt="avatar"
                                                            className="border__radius--50 w-100 height--150px object-fit profile__wrap--avatar"/>
                                                    </div>
                                                </> : <><Icon type="upload-outlined"/><p className="text-center">Select
                                                    File</p></>}
                                            </div>
                                        )}
                                    </Upload>
                                </div>
                            </div>
                            <div className="col-12 text-end mt-3">
                                <Form.Item className="">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={onCloseModal}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </Modal>
            <ListComponent
                scroll={{x: 1000}}
                classParent="m-0 p-0 col-md-12 mt-4"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultList || [], ['id', 'banner', 'url'])}
                columns={columnsTable({
                    titleTable: ["banner", 'url', "action"],
                    component: componentTable,
                    itemName: 'banners'
                })}
                pagination={false}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </>
    )
}

export default Index
