import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class SubscriptionPackageResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.lead_type = data?.lead_type || "";
        this.state = data?.state || "";
        this.county = data?.county || "";
        this.standard_price = data?.standard_price || "";
        this.premium_price = data?.premium_price || "";
        this.status = data?.status || 0;
        this.standard_sale_price = data?.standard_sale_price || "";
        this.premium_sale_price = data?.premium_sale_price || "";
    }

    exportList() {
        return {
            id: this.id,
            lead_type: this.lead_type,
            state: this.state,
            county: this.county,
            standard_price: this.standard_price,
            premium_price: this.premium_price,
            status: this.status,
        }
    }

    exportItem() {
        return {
            id: this.id,
            lead_type: this.lead_type,
            state: this.state,
            county: this.county,
            status: this.status,
            standard_price: this.standard_price,
            standard_sale_price: this.standard_sale_price,
            premium_price: this.premium_price,
            premium_sale_price: this.premium_sale_price,
        }
    }
}

export class MasterPackageResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.status || {};
        this.lead_type = data?.lead_type || [];
        this.state = data?.state || []
    }

    exportListMaster() {
        return {
            status: this.status,
            lead_type: this.lead_type,
            state: this.state,
        }
    }
}

export const listCols = ["lead_type", "state", "county", "standard_price", "premium_price", "status",];
export const constants = {
    status: ["Inactive", "Active"]
};
