import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionPackageReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        subscriptionPackageReducer => subscriptionPackageReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionPackageReducer => subscriptionPackageReducer.errors
    )

export const selectListSubscriptionPackage = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionPackageReducer => subscriptionPackageReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionPackageReducer => subscriptionPackageReducer.masterData
    )
export const selectSubscriptionPackageItem = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionPackageReducer => subscriptionPackageReducer.item
    )
