import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
import {
    ActionCustomerCreditAdd,
    ActionCustomerCreditList,
    ActionGetListCustomer,
    ActionMasterData
} from '../../../../data/hooks/customer';
import {Tooltip, Button, Tag, Form, Input, Select} from 'antd'

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListCustomer,
    selectMasterData, selectCustomerCreditList,
    selectStatusAction
} from '../../../../data/reselects/customerSelector';
import CustomerResponse, {
    listCols,
    customerCreditCols,
    CustomerCreditResponse
} from "../../../../data/mapping/Response/CustomerResponse";
import {common} from "../../../../data/mapping/Request/UserRequest";
import {filtersParams} from "../../../../data/mapping/Request/SubscriptionCustomerRequest";
import queryString from "query-string";
import moment from "moment";


const ModelFields = Object.getOwnPropertyNames(new CustomerCreditResponse());
const Index = props => {
    const namePage = "customers_credit",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(customerCreditCols, [], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {};
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        actionCustomerCreditList = ActionCustomerCreditList(),
        getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemListCredit = useSelector(selectCustomerCreditList()),
        itemErrors = useSelector(selectErrors()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (props?.user_id) {
            actionCustomerCreditList({user_id: props?.user_id});
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListCredit?.result || [])
        setPagination({
            total: itemListCredit?.total || 0,
            totalPage: itemListCredit?.total_page || 0,
            defaultCurrent: itemListCredit?.page_index || 1,
            pageSize: itemListCredit?.page_size || 10
        })
    }, [itemListCredit]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        actionCustomerCreditList({...newFilters, user_id: props?.user_id});
    };


    return <>
        <ListComponent
            disabledClassParent
            scroll={{x: 1000}}
            classParent="col-md-12 mt-4"
            loading={{spinning: itemStatusAction?.isFetchingCredit, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
