import * as Types from '../types/SubscriptionTransaction';

export const subscriptionTransactionListAction = (filters) => ({
    type: Types.SUBSCRIPTION_TRANSACTION_LIST_ACTION,
    filters
});
export const subscriptionTransactionListSuccess = (subscriptionTransactions) => ({
    type: Types.SUBSCRIPTION_TRANSACTION_LIST_SUCCESS,
    subscriptionTransactions
});

export const subscriptionTransactionMasterDataAction = (params) => ({
    type: Types.SUBSCRIPTION_TRANSACTION_MASTER_ACTION,
    params
});
export const subscriptionTransactionMasterDataSuccess = (masterData) => ({
    type: Types.SUBSCRIPTION_TRANSACTION_MASTER_SUCCESS,
    masterData
});

export const subscriptionTransactionUpdateDataAction = (params) => ({
    type: Types.SUBSCRIPTION_TRANSACTION_UPDATE_ACTION,
    params
});
export const subscriptionTransactionUpdateDataSuccess = (update) => ({
    type: Types.SUBSCRIPTION_TRANSACTION_UPDATE_SUCCESS,
    update
});

export const subscriptionTransactionInit = (refreshToken) => ({
    type: Types.SUBSCRIPTION_TRANSACTION_INIT,
    refreshToken
});
export const subscriptionTransactionFail = (refreshToken) => ({
    type: Types.SUBSCRIPTION_TRANSACTION_FAILED,
    refreshToken
});
