import {call, put, takeLatest, fork, all, select} from 'redux-saga/effects';
import * as Types from '../types/SubscriptionTransaction';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionTransactionService';
import * as FunctionAction from '../actions/subscriptionTransactionAction';
import moment from "moment";
// import TransactionRequest from "../mapping/Request/TransactionRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";
import SubscriptionTransactionRequest, {filtersParams} from "../mapping/Request/SubscriptionTransactionRequest";
import {downloadFile} from "../../utils/functions";
import CustomerRequest from "../mapping/Request/CustomerRequest";
import {message} from "antd";

function* actionGetListTransactions() {
    yield takeLatest(Types.SUBSCRIPTION_TRANSACTION_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters || {}));
        const result = yield call(FunctionService.getListSubscriptionTransactions, params, {isDownload: params?.is_export}),
            selectOldData = yield select();

        if (params?.is_export) {
            yield put(FunctionAction.subscriptionTransactionListSuccess(selectOldData.subscriptionTransactionReducer.data));
            const fileName = ("Transaction_List_" + moment().format('DDMMYYYY') + ".xlsx");
            downloadFile(result, fileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        } else {
            yield put(FunctionAction.subscriptionTransactionListSuccess(result));
        }
        // yield put(FunctionAction.subscriptionTransactionListSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_TRANSACTION_FAILED)))
}

function* updateAction() {
    yield takeLatest(Types.SUBSCRIPTION_TRANSACTION_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.update, new SubscriptionTransactionRequest(action.params.query).exportUpdate());
        message.success(result);
        yield put(FunctionAction.subscriptionTransactionListAction(action.params.filters));
    }, errorHandle(Types.SUBSCRIPTION_TRANSACTION_FAILED)))
}

function* actionGetMasterTransactions() {
    yield takeLatest(Types.SUBSCRIPTION_TRANSACTION_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterData, action.params);
        yield put(FunctionAction.subscriptionTransactionMasterDataSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_TRANSACTION_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionTransactionSaga() {
    yield all([
        fork(actionGetListTransactions),
        fork(actionGetMasterTransactions),
        fork(updateAction),
    ])
}
