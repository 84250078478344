import {Button, DatePicker, Form, Input, message, Modal, Select, Spin, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {Icon} from "../../../../common";
import moment from "moment"
import {common} from "../../../../../data/mapping/Request/UserRequest";
import {isEmail} from "../../../../../utils/validation";
import {find} from "lodash";

const AddSubscription = props => {
    const [form] = Form.useForm();
    const [listMonths, setListMonths] = useState([]),
        [optionsType, setOptionsType] = useState([]),
        [selectCode, setSelectCode] = useState(""),
        [selectPackage, setSelectPackage] = useState("");

    useEffect(() => {
        if (props.isOpenCreate) {
            form.resetFields();
            setListMonths([])
        }
    }, [props.isOpenCreate]);

    useEffect(() => {
        return () => {
            form.resetFields();
            setOptionsType([]);
            setSelectCode("");
            setSelectPackage("");
        }
    }, []);


    const onCreate = val => {
        props.actionCreate(val)

        setOptionsType([]);
        setSelectCode("");
        setSelectPackage("");
    }

    const onValuesChange = val => {
        if (val?.code) {
            setSelectCode(val?.code)
            form.setFieldsValue({
                item: ""
            })
        } else if (val?.package_id) {
            setSelectPackage(val?.package_id)
            form.setFieldsValue({
                type: find(props?.masterData?.packages, {key: val?.package_id})?.type,
                item: ""
            })
        }
    }

    useEffect(() => {
        if (form.getFieldValue("code") && form.getFieldValue("type")) {
            let newTypes = [];
            newTypes = find(props?.masterData?.states, {code: form.getFieldValue("code")})[form.getFieldValue("type").toLowerCase()]
            setOptionsType(newTypes)
        }
    }, [selectCode, selectPackage]);

    const custom = val => {
        let listMonth = [...listMonths];
        let newDate = moment(val).format("YYYYMM")
        if (!listMonth.includes(newDate)) {
            listMonth.push(newDate)
            form.setFieldsValue({months: listMonth, month: null})
            setListMonths(listMonth)
        } else {
            form.setFieldsValue({month: null});
            message.error("This month has been selected")
        }
    }

    return (
        <Modal
            width={1000}
            title="Add Subscription"
            destroyOnClose
            visible={props.isOpenCreate}
            onOk={() => form.submit()}
            onCancel={() => props.setIsOpenCreate(false)}
            maskClosable={false}
        >
            <Spin spinning={props.itemIsAction}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onCreate}
                    onValuesChange={onValuesChange}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="row bg-white align-items-center">
                        <Form.Item
                            className="d-none"
                            name="type"
                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            name="months"
                            hidden
                        >
                            <Input/>
                        </Form.Item>
                        <div className="col-6">
                            <Form.Item
                                label="Customer"
                                name="user_id"
                                rules={[{required: true, message: "Customer is required"}]}
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        (Object.keys(props.masterData?.customer || {})).length > 0 && (Object.keys(props.masterData?.customer || {})).map((i, k) => {
                                            return (
                                                <Select.Option value={props.masterData?.customer[i]}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        {/*<div className="col-6">*/}
                        {/*    <Form.Item*/}
                        {/*        label="Type"*/}
                        {/*        name="subscription_type"*/}
                        {/*        rules={[{required: true, message: "Type is required"}]}*/}
                        {/*    >*/}
                        {/*        <Select*/}
                        {/*            className="w-100"*/}
                        {/*            allowClear*/}
                        {/*        >*/}
                        {/*            {*/}
                        {/*                (Object.keys(props.masterData?.subscription_type || {})).length > 0 && (Object.keys(props.masterData?.subscription_type || {})).map((i, k) => {*/}
                        {/*                    return (*/}
                        {/*                        <Select.Option value={props.masterData?.subscription_type[i]}*/}
                        {/*                                       key={k}>{i}</Select.Option>*/}
                        {/*                    )*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</div>*/}
                        {/*<div className="col-6">*/}
                        {/*    <Form.Item*/}
                        {/*        label="State"*/}
                        {/*        name="states"*/}
                        {/*        rules={[{required: true, message: "State is required"}]}*/}
                        {/*    >*/}
                        {/*        <Select*/}
                        {/*            className="w-100"*/}
                        {/*            mode="multiple"*/}
                        {/*            allowClear*/}
                        {/*            showSearch*/}
                        {/*            filterOption={(input, option) =>*/}
                        {/*                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                        {/*            }*/}
                        {/*        >*/}
                        {/*            {*/}
                        {/*                (props.masterData?.state || []).length > 0 && (props.masterData?.state || []).map((i, k) => {*/}
                        {/*                    return (*/}
                        {/*                        <Select.Option value={i}*/}
                        {/*                                       key={k}>{i}</Select.Option>*/}
                        {/*                    )*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</div>*/}
                        <div className="col-6">
                            <Form.Item
                                label="Months"
                                name="month"
                                rules={[
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            if (listMonths.length > 0) {
                                                return Promise.resolve();
                                            }
                                            if (value) {
                                                return Promise.reject("Month is required");
                                            }
                                            return Promise.reject("Month is required")
                                        },
                                    })
                                ]}
                            >
                                <DatePicker
                                    picker="month"
                                    className="w-100"
                                    onChange={(e) => custom(e)}
                                />
                            </Form.Item>
                            {
                                listMonths.length > 0 && listMonths.map((i, k) => {
                                    return (
                                        <Tag key={i} closable onClose={() => {
                                            let old = [...listMonths],
                                                newResult = [];
                                            old.map(j => {
                                                if (j !== i) {
                                                    newResult.push(j)
                                                }
                                            })
                                            setListMonths(newResult)
                                            form.setFieldsValue({months: newResult})
                                        }}>
                                            {moment(i).format("YYYY-MM")}
                                        </Tag>
                                    )
                                })
                            }
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Package"
                                name="package_id"
                                rules={[{required: true, message: "Package is required"}]}
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                >
                                    {
                                        (props.masterData?.packages || []).length > 0 && (props.masterData?.packages || []).map((i, k) => {
                                            return (
                                                <Select.Option value={i?.key}
                                                               all={i}
                                                               key={k}>{i?.value}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="State"
                                name="code"
                                rules={[{required: true, message: "code is required"}]}
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                >
                                    {
                                        (props.masterData?.states_dropdown || []).length > 0 && (props.masterData?.states_dropdown || []).map((i, k) => {
                                            return (
                                                <Select.Option value={i?.value}
                                                               key={k}>{i?.label}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Item"
                                name="item"
                                rules={[{required: true, message: "Item is required"}]}
                            >
                                <Select
                                    showSearch
                                    className="w-100"
                                    allowClear
                                    // disabled={!(optionsType.length > 0) && !form.setFieldsValue("item")}
                                    filterOption={(input, option) =>
                                        (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        (optionsType || []).length > 0 && (optionsType || []).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        {/*<div className="col-2">*/}
                        {/*    <Form.Item*/}
                        {/*        label="Type"*/}
                        {/*        name="type"*/}
                        {/*    >*/}
                        {/*        <Select*/}
                        {/*            className="w-100"*/}
                        {/*            allowClear*/}
                        {/*        >*/}
                        {/*            {*/}
                        {/*                (Object.keys(itemMasterData?.type || {})).length > 0 && (Object.keys(itemMasterData?.type || {})).map((i, k) => {*/}
                        {/*                    return (*/}
                        {/*                        <Select.Option value={i}*/}
                        {/*                                       key={k}>{itemMasterData?.type[i]}</Select.Option>*/}
                        {/*                    )*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</div>*/}
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

export default AddSubscription
