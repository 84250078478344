import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class SubscriptionCustomerRecurringRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.status;
        this.next_payment_date = data?.next_payment_date || "";
        this.user_id = data?.email || "";
        this.item = this.generateItem(data?.item || [])
    }

    generateItem(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                price: i?.price || "",
                package_id: i?.package_id || "",
                code: i?.code || "",
                type: i?.type || "",
                item: i?.item || "",
                id: i?.id || "",
            }))
        }
        return newItems
    }

    exportUpdate() {
        return removeObjectNullFull({
            status: this.status,
            next_payment_date: this.next_payment_date ? moment(this.next_payment_date).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "",
            item: this.item,
        })
    }

    exportCreate() {
        return removeObjectNullFull({
            status: this.status,
            next_payment_date: this.next_payment_date ? moment(this.next_payment_date).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "",
            item: this.item,
            user_id: this.user_id,
        })
    }

}

export const common = {
    ACTION_FIELD: {
        status: "status",
    },
}

export const filtersParams = (filters) => {
    let newDateField = (filters?.recurring_date || []).length > 0 ? [moment(filters?.recurring_date[0]).format("YYYY-MM-DD"), moment(filters?.recurring_date[1]).format("YYYY-MM-DD")] : ["", ""]
    return Object.keys(filters).length > 0 ? removeObjectNullFull({
        recurring_date_from: newDateField[0],
        recurring_date_to: newDateField[1],
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    }) : {}
}

