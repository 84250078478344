import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";

export default class SubscriptionTransactionResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.code = data?.code || "";
        this.credit = data?.credit || "";
        this.user_id = data?.user_id || "";
        this.email = data?.email || "";
        this.price = data?.price || 0;
        this.discount = data?.discount || 0;
        this.discount_code = data?.discount_code || "";
        this.total_amount = data?.total_amount || 0;
        this.month = data?.month || "";
        this.type = data?.type || "";
        this.subscription_type = data?.subscription_type || "";
        this.status = data?.status || 0;
        this.status_explain = data?.status_explain || "";
        this.subscribe_date = data?.subscribe_date ? moment(data?.subscribe_date).format(DATE_TIME_FORMAT.FULL_DATE_US) : "";
        this.packages = this.getPackages(data?.packages || [])
        this.tracker_email = data?.tracker_email || "";
        this.exchange_value = data?.exchange_value || "";
        this.tracker = data?.tracker || [];
    }

    getPackages(packages = []) {
        let newPackages = []
        if (packages.length > 0) {
            packages.map(i => newPackages.push({
                key: i?.id || "",
                id: i?.id || "",
                lead_type: i?.lead_type || "",
                state: i?.state || "",
                county: i?.county || "",
            }))
        }
        return newPackages
    }

    exportList() {
        return {
            id: this.id,
            code: this.code,
            credit: this.credit,
            user_id: this.user_id,
            email: this.email,
            price: this.price,
            discount: this.discount,
            discount_code: this.discount_code,
            total_amount: this.total_amount,
            month: this.month,
            type: this.type,
            subscription_type: this.subscription_type,
            status: this.status,
            status_explain: this.status_explain,
            subscribe_date: this.subscribe_date,
            packages: this.packages,
            tracker_email: this.tracker_email,
            tracker: this.tracker,
            exchange_value: this.exchange_value,
        }
    }
}

export class MasterTransactionResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.status || {};
        this.admin = data?.admin || {};
        this.type = data?.type || [];
        this.subscription_type = data?.subscription_type || []
    }

    exportListMaster() {
        return {
            status: this.status,
            type: this.type,
            subscription_type: this.subscription_type,
            admin: this.admin,
        }
    }
}

export const listCols = ["id", "code", "email", "type", "subscription_type", "status_explain", "discount_code", "price", "discount", "credit", "exchange_value", "total_amount", "month", "subscribe_date", "tracker_email"];
export const listColDetail = ["key", "lead_type", "state", "county"];
export const constants = {
    status: ["Inactive", "Active"]
};

export const responseQueryParams = (query) => {
    return Object.keys(query).length > 0 ? removeObjectNullFull({
        month: query?.month ? moment(query?.month) : "",
        type: query?.type || "",
        subscription_type: query?.subscription_type || "",
        search: query?.search || "",
        page_size: query?.page_size || 10,
        page_index: query?.page_index || 1,
        status: query?.status ? parseInt(query.status) : undefined,
        // sort: (query?.order_field && query?.order_value) ? `${query?.order_field} ${query?.order_value}` : "",
        order_field: query?.sort ? (query?.sort || "").split(" ")[0] : "",
        order_value: query?.sort ? (query?.sort || "").split(" ")[1] : "",
    }) : {}
}
