import * as Types from '../types/Data';
import {PAGINATION} from "../../constants/define";

const initialState = {
    isTruncate: false,
    isFetching: false,
    errors: [],
    statusAction: {
        uploadOtherLeadTypesSuccess: false
    },
    masterLeadType: {},
    masterOtherLeadType: {},
    data: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
    dataImport: {},
    uploadOtherLeadTypes: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.DATA_IMPORT_ACTION:
        case Types.DATA_UPLOAD_OTHER_LEAD_TYPE_ACTION:
            return {...state, isFetching: true, errors: [], statusAction: initialState.statusAction};
        case Types.DATA_IMPORT_SUCCESS:
            return {...state, isFetching: false, dataImport: action.dataImport?.result || {}, errors: []};
        case Types.DATA_UPLOAD_OTHER_LEAD_TYPE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                statusAction: {
                    ...initialState.statusAction,
                    uploadOtherLeadTypesSuccess: true
                },
                uploadOtherLeadTypes: action.uploadOtherLeadTypes || {},
                errors: []
            };
        case Types.DATA_TRUNCATE_ACTION:
            return {...state, isTruncate: true, errors: []};
        case Types.DATA_TRUNCATE_SUCCESS:
            return {...state, isTruncate: false, errors: []};
        case Types.DATA_MASTER_LEAD_TYPE_ACTION:
        case Types.DATA_MASTER_OTHER_LEAD_TYPE_ACTION:
            return {...state, isTruncate: true, errors: [], statusAction: initialState.statusAction};
        case Types.DATA_MASTER_LEAD_TYPE_SUCCESS:
            return {...state, isTruncate: false, errors: [], masterLeadType: action.leadTypes};
        case Types.DATA_MASTER_OTHER_LEAD_TYPE_SUCCESS:
            return {...state, isTruncate: false, errors: [], masterOtherLeadType: action.otherLeadTypes};
        case Types.DATA_INIT:
            return initialState;
        case Types.DATA_FAILED:
            return {...state, isFetching: false, errors: action.errors, statusAction: initialState.statusAction};
        default:
            return state;
    }
}
