export const DASHBOARD_USER_ACTIVITY_ACTION = "DASHBOARD_USER_ACTIVITY_ACTION";
export const DASHBOARD_USER_ACTIVITY_SUCCESS = "DASHBOARD_USER_ACTIVITY_SUCCESS";

export const DASHBOARD_CITY_FILTER_ACTION = "DASHBOARD_CITY_FILTER_ACTION";
export const DASHBOARD_CITY_FILTER_SUCCESS = "DASHBOARD_CITY_FILTER_SUCCESS";

export const DASHBOARD_ALL_ACTION = "DASHBOARD_ALL_ACTION";
export const DASHBOARD_ALL_SUCCESS = "DASHBOARD_ALL_SUCCESS";

export const DASHBOARD_DAILY_USER_ACTIVITY_ACTION = "DASHBOARD_DAILY_USER_ACTIVITY_ACTION";
export const DASHBOARD_DAILY_USER_ACTIVITY_SUCCESS = "DASHBOARD_DAILY_USER_ACTIVITY_SUCCESS";

export const DASHBOARD_INIT = "DASHBOARD_INIT";
export const DASHBOARD_FAILED = "DASHBOARD_FAILED";

export const DASHBOARD_COUNTY_ACTION = "DASHBOARD_COUNTY_ACTION";
export const DASHBOARD_COUNTY_SUCCESS = "DASHBOARD_COUNTY_SUCCESS";

export const DASHBOARD_LEAD_TYPE_ACTION = "DASHBOARD_LEAD_TYPE_ACTION";
export const DASHBOARD_LEAD_TYPE_SUCCESS = "DASHBOARD_LEAD_TYPE_SUCCESS";

export const DASHBOARD_TRACKING_ACTION = "DASHBOARD_TRACKING_ACTION";
export const DASHBOARD_TRACKING_SUCCESS = "DASHBOARD_TRACKING_SUCCESS";
