import * as Types from '../types/Customer';

export const customerListAction = (filters) => ({type: Types.CUSTOMER_LIST_ACTION, filters});
export const customerListSuccess = (customers) => ({type: Types.CUSTOMER_LIST_SUCCESS, customers});

export const customerCreateAction = (params) => ({type: Types.CUSTOMER_CREATE_ACTION, params});
export const customerCreateSuccess = (createCustomer) => ({type: Types.CUSTOMER_CREATE_SUCCESS, createCustomer});

export const customerUpdateAction = (params) => ({type: Types.CUSTOMER_UPDATE_ACTION, params});
export const customerUpdateSuccess = (updateCustomer) => ({type: Types.CUSTOMER_UPDATE_SUCCESS, updateCustomer});

export const customerMasterDataAction = (params) => ({type: Types.CUSTOMER_MASTER_DATA_ACTION, params});
export const customerMasterDataSuccess = (masterData) => ({type: Types.CUSTOMER_MASTER_DATA_SUCCESS, masterData});

export const customerGetItemAction = (params) => ({type: Types.CUSTOMER_GET_TEM_ACTION, params});
export const customerGetItemSuccess = (item) => ({type: Types.CUSTOMER_GET_TEM_SUCCESS, item});

export const customerCreditListAction = (filters) => ({type: Types.CUSTOMER_CREDIT_LIST_ACTION, filters});
export const customerCreditListSuccess = (customerCredit) => ({type: Types.CUSTOMER_CREDIT_LIST_SUCCESS, customerCredit});

export const customerCreditAddAction = (params) => ({type: Types.CUSTOMER_CREDIT_ADD_ACTION, params});
export const customerCreditAddSuccess = (addCredit) => ({type: Types.CUSTOMER_CREDIT_ADD_SUCCESS, addCredit});

export const customerAnnouncementListAction = (params) => ({type: Types.CUSTOMER_ANNOUNCEMENT_LIST_ACTION, params});
export const customerAnnouncementListSuccess = (announcements) => ({type: Types.CUSTOMER_ANNOUNCEMENT_LIST_SUCCESS, announcements});
export const customerAnnouncementAction = (params) => ({type: Types.CUSTOMER_ANNOUNCEMENT_ACTION, params});
export const customerAnnouncementSuccess = (announcementsAction) => ({type: Types.CUSTOMER_ANNOUNCEMENT_SUCCESS, announcementsAction});

export const customerInit = (refreshToken) => ({type: Types.CUSTOMER_INIT, refreshToken});
export const customerFail = (refreshToken) => ({type: Types.CUSTOMER_FAILED, refreshToken});
