import * as Types from '../types/Dashboard';

export const dashboardUserActivityAction = (params) => ({type: Types.DASHBOARD_USER_ACTIVITY_ACTION, params});
export const dashboardUserActivitySuccess = (userActivity) => ({type: Types.DASHBOARD_USER_ACTIVITY_SUCCESS, userActivity});

export const dashboardDailyUserActivityAction = (params) => ({type: Types.DASHBOARD_DAILY_USER_ACTIVITY_ACTION, params});
export const dashboardDailyUserActivitySuccess = (dailyUserActivity) => ({type: Types.DASHBOARD_DAILY_USER_ACTIVITY_SUCCESS, dailyUserActivity});

export const dashboardCityFilterAction = (params) => ({type: Types.DASHBOARD_CITY_FILTER_ACTION, params});
export const dashboardCityFilterSuccess = (cityFilter) => ({type: Types.DASHBOARD_CITY_FILTER_SUCCESS, cityFilter});

export const dashboardAllAction = (params) => ({type: Types.DASHBOARD_ALL_ACTION, params});
export const dashboardAllSuccess = (all) => ({type: Types.DASHBOARD_ALL_SUCCESS, all});

export const dashboardInit = (params) => ({type: Types.DASHBOARD_INIT, params});
export const dashboardFail = (params) => ({type: Types.DASHBOARD_FAILED, params});

export const dashboardImportCountyAction = (params) => ({type: Types.DASHBOARD_COUNTY_ACTION, params});
export const dashboardImportCountySuccess = (importCounty) => ({type: Types.DASHBOARD_COUNTY_SUCCESS, importCounty});

export const dashboardImportLeadTypeAction = (params) => ({type: Types.DASHBOARD_LEAD_TYPE_ACTION, params});
export const dashboardImportLeadTypeSuccess = (importLeadType) => ({type: Types.DASHBOARD_LEAD_TYPE_SUCCESS, importLeadType});

export const dashboardTrackingAction = (params) => ({type: Types.DASHBOARD_TRACKING_ACTION, params});
export const dashboardTrackingSuccess = (tracking) => ({type: Types.DASHBOARD_TRACKING_SUCCESS, tracking});
