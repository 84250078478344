import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";
import PERMISSION from "../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import {ListComponent} from "../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../utils/commonUtils";
import {Tooltip, Button, Tag, Form, Input, Select} from 'antd'

// ============== ACTION ==============
import {ActionGetListTicket, ActionInit, ActionMasterData} from '../../../data/hooks/ticket';

// ============== SELECT DATA ==============
import {selectListTicket, selectMasterData, selectFetching, selectErrors} from '../../../data/reselects/ticketSelector';
import TicketResponse, {listCols, TICKET} from "../../../data/mapping/Response/TicketResponse";
import {common} from "../../../data/mapping/Request/UserRequest";
import {filtersParams} from "../../../data/mapping/Request/TicketRequest";
import queryString from "query-string";
import moment from "moment";


const ModelFields = Object.getOwnPropertyNames(new TicketResponse());
const Index = props => {
    const namePage = "tickets",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            assignee: {
                // width: 150,
                render: (val, record) => `${record.assignee_first_name} ${record.assignee_last_name}`,
            },
            owner: {
                // width: 150,
                render: (val, record) => `${record.user_first_name} ${record.user_last_name}`,
            },
            status_explain: {
                width: 150,
                render: (val, record) => <span
                    style={{color: TICKET.STATUS[record.status]}}>{record.status_explain}</span>,
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="edit-outlined" className="cursorPointer"
                                                onClick={() => props.history.push((PERMISSION.CUSTOMER.TICKET_UPDATE.route).replace(":id", record.id), {
                                                    group: PERMISSION.CUSTOMER.TICKET_UPDATE.group,
                                                    withParams: true
                                                })}/>,
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListData = ActionGetListTicket(),
        getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemListData = useSelector(selectListTicket()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            setPramsFilters(paramsFilters)
            // if (paramsFilters?.status !== undefined) {
            //     paramsFilters.status = parseInt(paramsFilters.status);
            // }
            form.setFieldsValue(paramsFilters)
            getListData(removeObjectNullFull(paramsFilters));
        } else {
            props.history.replace(setParamsUrlFilter(PERMISSION.CUSTOMER.TICKET_LIST.route, filtersParams(TICKET.INIT_FORM)))
            setPramsFilters(TICKET.INIT_FORM)
            getListData(TICKET.INIT_FORM);
        }
        getMasterData()
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListData?.result || [])
        setPagination({
            total: itemListData?.total || 0,
            totalPage: itemListData?.total_page || 0,
            defaultCurrent: itemListData?.page_index || 1,
            pageSize: itemListData?.page_size || 10
        })
    }, [itemListData]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(PERMISSION.CUSTOMER.TICKET_LIST.route, filtersParams(newFilters)))
        getListData(newFilters);
    };

    const onSearch = (val) => {
        let getFilters = removeObjectNullFull({...initData.filters, ...paramsFilters, ...val, page_index: 1});
        setPramsFilters(getFilters)
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(PERMISSION.CUSTOMER.TICKET_LIST.route, filtersParams(getFilters)))
        getListData(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction title="Ticket List"/>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-4">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item
                            label="Status"
                            name="status"
                            initialValue={TICKET.INIT_FORM.status}
                        >
                            <Select
                                mode="multiple"
                                className="w-100"
                                allowClear
                            >
                                {
                                    (Object.keys(itemMasterData?.status || {})).length > 0 && (Object.keys(itemMasterData?.status || {})).map((i, k) => {
                                        return (
                                            <Select.Option value={i}
                                                           key={k}>{itemMasterData?.status[i]}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-2">
                        <Form.Item
                            label="Type"
                            name="type"
                        >
                            <Select
                                className="w-100"
                                allowClear
                            >
                                {
                                    (Object.keys(itemMasterData?.type || {})).length > 0 && (Object.keys(itemMasterData?.type || {})).map((i, k) => {
                                        return (
                                            <Select.Option value={i}
                                                           key={k}>{itemMasterData?.type[i]}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-1">
                        <Form.Item className="text-end" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            rowClassName={record => record?.is_unread ? "bg--red-blur" : ""}
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
