export const CUSTOMER_LIST_ACTION = "CUSTOMER_LIST_ACTION";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_CREATE_ACTION = "CUSTOMER_CREATE_ACTION";
export const CUSTOMER_CREATE_SUCCESS = "CUSTOMER_CREATE_SUCCESS";
export const CUSTOMER_UPDATE_ACTION = "CUSTOMER_UPDATE_ACTION";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_MASTER_DATA_ACTION = "CUSTOMER_MASTER_DATA_ACTION";
export const CUSTOMER_MASTER_DATA_SUCCESS = "CUSTOMER_MASTER_DATA_SUCCESS";
export const CUSTOMER_GET_TEM_ACTION = "CUSTOMER_GET_TEM_ACTION";
export const CUSTOMER_GET_TEM_SUCCESS = "CUSTOMER_GET_TEM_SUCCESS";
export const CUSTOMER_CREDIT_LIST_ACTION = "CUSTOMER_CREDIT_LIST_ACTION";
export const CUSTOMER_CREDIT_LIST_SUCCESS = "CUSTOMER_CREDIT_LIST_SUCCESS";
export const CUSTOMER_CREDIT_ADD_ACTION = "CUSTOMER_CREDIT_ADD_ACTION";
export const CUSTOMER_CREDIT_ADD_SUCCESS = "CUSTOMER_CREDIT_ADD_SUCCESS";
export const CUSTOMER_ANNOUNCEMENT_LIST_ACTION = "CUSTOMER_ANNOUNCEMENT_LIST_ACTION";
export const CUSTOMER_ANNOUNCEMENT_LIST_SUCCESS = "CUSTOMER_ANNOUNCEMENT_LIST_SUCCESS";
export const CUSTOMER_ANNOUNCEMENT_ACTION = "CUSTOMER_ANNOUNCEMENT_ACTION";
export const CUSTOMER_ANNOUNCEMENT_SUCCESS = "CUSTOMER_ANNOUNCEMENT_SUCCESS";
export const CUSTOMER_INIT = "CUSTOMER_INIT";
export const CUSTOMER_FAILED = "CUSTOMER_FAILED";
