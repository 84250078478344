import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";

export default class SubscriptionCustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.email = data?.email || "";
        this.valid_time = data?.valid_time || "";
        this.subscription_type = data?.subscription_type || "";
        this.lead_type = data?.lead_type || "";
        this.state = data?.state || "";
        this.county = data?.county || "";
        this.status = data?.status || 0;
        this.subscribe_date = data?.subscribe_date ? moment(data?.subscribe_date).format(DATE_TIME_FORMAT.FULL_DATE_US) : "";
        this.order_id = data?.order_id || "";
        this.updated_date = data?.updated_date || "";
        this.is_delete = data?.is_delete;
        this.exchange_order_id = data?.exchange_order_id || "";
    }

    exportList() {
        return {
            id: this.id,
            email: this.email,
            valid_time: this.valid_time,
            subscription_type: this.subscription_type,
            lead_type: this.lead_type,
            state: this.state,
            county: this.county,
            status: this.status,
            order_id: this.order_id,
            subscribe_date: this.subscribe_date,
            is_delete: this.is_delete,
            exchange_order_id: this.exchange_order_id,
        }
    }

    exportItem() {
        return {
            id: this.id,
            email: this.email,
            valid_time: this.valid_time,
            subscription_type: this.subscription_type,
            lead_type: this.lead_type,
            state: this.state,
            county: this.county,
            status: this.status,
            subscribe_date: this.subscribe_date,
            order_id: this.order_id,
            updated_date: this.updated_date,
        }
    }
}

export class MasterCustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.status || {};
        this.subscription_type = data?.subscription_type || {};
        this.customer = data?.customer || {};
        this.lead_type = data?.lead_type || [];
        this.state = data?.state || [];
        this.states = this.generateStates(data?.states || []);
        this.states_dropdown = this.generateStatesDropdown(data?.states || []);
        this.packages = this.generatePackages(data?.packages || []);
    }

    generateStatesDropdown(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                value: i?.code || "",
                label: i?.state_name || "",
            }))
        }
        return newItems
    }

    generatePackages(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                key: i?.key || "",
                value: i?.value || "",
                type: i?.type || "",
            }))
        }
        return newItems
    }

    generateStates(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                code: i?.code || "",
                state_name: i?.state_name || "",
                state: i?.state || [],
                city: i?.city || [],
                county: i?.county || [],
                metro: i?.metro || []
            }))
        }
        return newItems
    }

    exportListMaster() {
        return {
            status: this.status,
            lead_type: this.lead_type,
            state: this.state,
        }
    }

    exportListMasterState() {
        return {
            subscription_type: this.subscription_type,
            customer: this.customer,
            state: this.state,
            states: this.states,
            packages: this.packages,
            states_dropdown: this.states_dropdown,
        }
    }
}

export const listCols = ["email", "valid_time", "subscription_type", "lead_type", "state", "county", "order_id", "status", "exchange_order_id", "subscribe_date"];
export const constants = {
    status: ["Inactive", "Active", "", "", "", "Exchange"]
};

export const responseQueryParams = (query) => {
    return Object.keys(query).length > 0 ? removeObjectNullFull({
        valid_time_from: query?.valid_time_from ? moment(query?.valid_time_from) : "",
        valid_time_to: query?.valid_time_to ? moment(query?.valid_time_to) : "",
        status: query?.status ? parseInt(query.status) : undefined,
        email: query?.email || "",
        lead_type: query?.lead_type || "",
        state: query?.state || "",
        page_size: query?.page_size || 10,
        page_index: query?.page_index || 1,
        // sort: (query?.order_field && query?.order_value) ? `${query?.order_field} ${query?.order_value}` : "",
        order_field: query?.sort ? (query?.sort || "").split(" ")[0] : "",
        order_value: query?.sort ? (query?.sort || "").split(" ")[1] : "",
    }) : {}
}
