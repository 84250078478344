import HeaderAction from "../../common/layout/HeaderAction";
import {
    Button,
    Checkbox, Collapse,
    DatePicker,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Space,
    Spin,
    Table,
    Tag,
    Upload
} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    arrayRemoveItem, checkUndefined,
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable, removeArrayElementObject, removeElementObject,
    setParamsUrlFilter
} from "../../../utils/functions";
import {ListComponent} from "../../common/ListComponent";
import {ActionCustomerAnnouncementAction, ActionCustomerAnnouncementList} from "../../../data/hooks/customer";
import {useSelector} from "react-redux";
import {
    selectCustomerAnnouncementList,
    selectFetching,
    selectStatusAction
} from "../../../data/reselects/customerSelector";
import CustomerResponse, {
    announcementCols,
    AnnouncementResponse
} from "../../../data/mapping/Response/CustomerResponse";
import {Icon} from "../../common";
import PERMISSION from "../../../constants/permission";
import {find, omit} from "lodash";
import {common} from "../../../data/mapping/Request/CustomerRequest";
import {isEmail} from "../../../utils/validation";
import {v4 as uuidv4} from "uuid";
import {DeleteOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {FcCheckmark} from "react-icons/fc";
import {ActionUpload, ActionUploadInitStore} from "../../../data/hooks/upload";
import {selectFetching as selectFetchingUpload, selectFilesUpload} from "../../../data/reselects/uploadSelector";

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const ModelFields = Object.getOwnPropertyNames(new AnnouncementResponse());
const Index = props => {
    const namePage = "customer_announcement",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(announcementCols, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            is_popup: {
                width: 100,
                align: 'center',
                key: 'action',
                // fixed: 'right',
                render: (text, record) => text ? <FcCheckmark/> : null
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                // fixed: 'right',
                render: (text, record) => (
                    <>
                        <Icon type="edit-outlined" className="cursorPointer me-2"
                              onClick={() => {
                                  let itemSelect = find(resultList, {id: record?.id})
                                  setIdItemEdit(record?.id)
                                  form.setFieldsValue(itemSelect)
                                  if (Boolean(record?.is_popup) === true || Boolean(record?.is_popup) === false) {
                                      setIsPopupContent(Boolean(record?.is_popup))
                                  }
                                  setType("UPDATE")
                                  setIsModal(true)
                              }}/>
                        <Popconfirm
                            title="Are you sure to delete?"
                            onConfirm={e => onDelete(record?.id)}
                            onCancel={e => {
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined/>
                        </Popconfirm>
                    </>
                ),
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [isPopupContent, setIsPopupContent] = useState(false),
        [isModal, setIsModal] = useState(false),
        [type, setType] = useState(""),
        [idItemEdit, setIdItemEdit] = useState(""),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        [fileList, setFileList] = useState([]),
        [imageUrl, setImageUrl] = useState(),
        [files, setFiles] = useState({}),
        [loadingUpload, setLoadingUpload] = useState(false),
        actionCustomerAnnouncementList = ActionCustomerAnnouncementList(),
        actionCustomerAnnouncementAction = ActionCustomerAnnouncementAction(),
        actionUpload = ActionUpload(),
        actionUploadInitStore = ActionUploadInitStore(),
        itemFileUpload = useSelector(selectFilesUpload()),
        itemIsUpload = useSelector(selectFetchingUpload()),
        itemAnnouncements = useSelector(selectCustomerAnnouncementList()),
        itemIsFetching = useSelector(selectFetching()),
        itemStatusAction = useSelector(selectStatusAction());

    useEffect(() => {
        actionCustomerAnnouncementList()
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        if (itemStatusAction?.isCreateAnnouncementsSuccess) {
            form.resetFields();
            setIsModal(false)
            setIdItemEdit("")
        }
    }, [itemStatusAction]);

    useEffect(() => {
        setResultList(itemAnnouncements?.result || [])
        // setPagination({
        //     total: itemAnnouncements?.total || 0,
        //     totalPage: itemAnnouncements?.total_page || 0,
        //     defaultCurrent: itemAnnouncements?.page_index || 1,
        //     pageSize: itemAnnouncements?.page_size || 10
        // })
    }, [itemAnnouncements]);

    useEffect(() => {
        setLoadingUpload(itemIsUpload)
    }, [itemIsUpload]);

    useEffect(() => {
        setFiles(itemFileUpload)
        form.setFieldsValue({
            popup_image_url: itemFileUpload?.file_url
        })
    }, [itemFileUpload]);

    const onCreate = e => {
        form.resetFields();
        setIsModal(true)
        setType("CREATE")
    }

    const onDelete = id => {
        let newItemsList = [...resultList];
        newItemsList = newItemsList.filter(i => i?.id !== id)
        actionCustomerAnnouncementAction(newItemsList)
    }

    const handleOk = () => {
        setIsModal(false);
        setType("")
    };
    const handleCancel = () => {
        setIsModal(false);
    };

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        // filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        // let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        // setPramsFilters(newFilters)
        // setInitData({
        //     ...initData,
        //     filters: newFilters
        // });
        // props.history.replace(setParamsUrlFilter(PERMISSION.CUSTOMER.VIEW.route, newFilters))
        // getListCustomers(newFilters);
    };

    const onFinish = val => {
        let newItemsList = [...resultList];
        if (type === "CREATE") {
            newItemsList.push({
                id: uuidv4(),
                title: val?.title,
                url: val?.url,
                is_popup: val?.is_popup,
                date_time: val?.date_time,
                popup_image_url: val?.popup_image_url,
                popup_content: val?.popup_content,
            })
            actionCustomerAnnouncementAction(newItemsList)
        } else {
            for (let i = 0; i < newItemsList.length; i++) {
                if (newItemsList[i].id === idItemEdit) {
                    newItemsList[i].title = val?.title;
                    newItemsList[i].url = val?.url;
                    newItemsList[i].is_popup = val?.is_popup;
                    newItemsList[i].date_time = val?.date_time;
                    newItemsList[i].popup_image_url = val?.popup_image_url;
                    newItemsList[i].popup_content = val?.popup_content;
                }
            }
            actionCustomerAnnouncementAction(newItemsList)
        }
    }

    const handleChangeImage = info => {
        if (info.file.status === 'uploading') {
            setFiles({})
            setLoadingUpload(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                    setLoadingUpload(false);
                    setImageUrl(imageUrl)
                }
            );
        }
    };
    return (
        <>
            <HeaderAction title="Announcement List" isCreate onClick={e => onCreate(e)}/>
            <Modal
                title={(type === "CREATE") ? "Create Announcement" : "Update Announcement"}
                maskClosable={false}
                destroyOnClose
                visible={isModal}
                onOk={handleOk}
                footer={false}
                onCancel={e => {
                    form.resetFields();
                    actionUploadInitStore();
                    setIdItemEdit("");
                    setImageUrl("");
                    setIsModal(false);
                    setIsPopupContent(false);
                    setType("")
                }}>
                <Spin spinning={isFetching}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        onValuesChange={e => {
                            if (e?.is_popup === true || e?.is_popup === false) {
                                setIsPopupContent(e?.is_popup || false)
                            }
                        }}
                    >
                        <Form.Item
                            label="Popup Image"
                            name="popup_image_url"
                            className="d-none"
                        >
                            <Input/>
                        </Form.Item>
                        <div className="row">
                            <div className="col-12">
                                <Form.Item
                                    label="Title"
                                    name="title"
                                    rules={[{required: true}]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="col-12">
                                <Form.Item
                                    label="Url"
                                    name="url"
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="col-12">
                                <Form.Item
                                    label="Time"
                                    name="date_time"
                                >
                                    <DatePicker.RangePicker format={DATE_TIME_FORMAT.FULL_DATE_US} className="w-100"
                                                            showTime/>
                                </Form.Item>
                            </div>
                            <div className="col-4">
                                <Form.Item
                                    label="Is Popup"
                                    name="is_popup"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Is Popup</Checkbox>
                                </Form.Item>
                            </div>
                            {/*<div className="col-8 overflow-hidden">*/}
                            {/*    <Upload*/}
                            {/*        name="file"*/}
                            {/*        listType="picture-card"*/}
                            {/*        className="avatar-uploader h-100 profile--upload upload-full-wrap"*/}
                            {/*        showUploadList={false}*/}
                            {/*        action={(files) => actionUpload({files})}*/}
                            {/*        customRequest={() => {*/}
                            {/*        }}*/}
                            {/*        // beforeUpload={beforeUpload}*/}
                            {/*        onChange={handleChangeImage}*/}
                            {/*    >*/}
                            {/*        {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : (*/}
                            {/*            <div className="text-center w-100">*/}
                            {/*                {loadingUpload ? <>*/}
                            {/*                    <LoadingOutlined/>*/}
                            {/*                    <div style={{marginTop: 8}}>Upload</div>*/}
                            {/*                </> : files?.file_url ? <>*/}
                            {/*                    <div className="profile__wrap">*/}
                            {/*                        <img*/}
                            {/*                            src={`${files?.file_url ? files?.file_url : "http://dev.realestate/layout/banner/affiliate%20banner%201.png"}`}*/}
                            {/*                            alt="avatar"*/}
                            {/*                            style={{*/}
                            {/*                                objectFit: "cover"*/}
                            {/*                            }}*/}
                            {/*                            className="border__radius--50 w-100 height--150px object-fit profile__wrap--avatar"/>*/}
                            {/*                    </div>*/}
                            {/*                </> : <><Icon type="upload-outlined"/><p className="text-center">Select*/}
                            {/*                    File</p></>}*/}
                            {/*            </div>*/}
                            {/*        )}*/}
                            {/*    </Upload>*/}
                            {/*</div>*/}
                            <div className={`col-12 ${isPopupContent ? "" : "d-none"}`}>
                                <Form.List name="popup_content">
                                    {(fields, {add, remove}) => (
                                        <>
                                            <Form.Item className="text-center w-100">
                                                <Button className="w-100" type="dashed" onClick={() => add()} block
                                                        icon={<PlusOutlined/>}/>
                                            </Form.Item>
                                            <Collapse defaultActiveKey={[]}
                                                      className={fields.length > 0 ? "" : "d-none"}>
                                                {fields.map(({key, name, ...restField}) => (
                                                    <Collapse.Panel
                                                        header={`Content`}
                                                        key={key}
                                                        extra={<MinusCircleOutlined
                                                            onClick={() => remove(name)}/>}
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'type']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please choose a type',
                                                                },
                                                            ]}
                                                            initialValue="update"
                                                        >
                                                            <Select
                                                                className="w-100"
                                                            >
                                                                {

                                                                    [
                                                                        {
                                                                            title: "Update",
                                                                            value: "update"
                                                                        },
                                                                        {
                                                                            title: "New",
                                                                            value: "new"
                                                                        }
                                                                    ].map((i, k) => {
                                                                        return (
                                                                            <Select.Option value={i?.value}
                                                                                           key={k}>{i?.title}</Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'title']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Title is require',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Input your Title"/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'description']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Description is require',
                                                                },
                                                            ]}
                                                        >
                                                            <Input.TextArea placeholder="Input your description"/>
                                                        </Form.Item>
                                                    </Collapse.Panel>
                                                ))}
                                            </Collapse>
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <div className="col-12 text-end mt-4">
                                <Form.Item className="">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => {
                                                form.resetFields();
                                                setIsModal(false);
                                                setType("")
                                            }}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </Modal>
            <ListComponent
                // scroll={{x: 1500}}
                classParent="m-0 p-0 col-md-12 mt-4"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultList || [], initData.modelItem)}
                columns={columnsTable({
                    titleTable: initData.titleItemTable,
                    component: componentTable,
                    itemName: namePage
                })}
                pagination={false}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </>
    )
}

export default Index
