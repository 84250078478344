import {Button, DatePicker, Form, Input, Select, Space} from "antd";
import {Icon} from "../../../common";
import React from "react";

const ModalTracker = props => {
    const [form] = Form.useForm();

    const onSubmit = val => {
        props?.actionUpdate({query: {...val, id: props?.result?.id}})
    }

    return (
        <Form
            form={form}
            layout="vertical"
            className="w-100"
            onFinish={onSubmit}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className="row bg-white py-3 align-items-center w-100">
                <div className="col-12">
                    <Form.Item
                        label="Tracker"
                        name="tracker"
                        className="mb-0"
                        initialValue={props?.result?.query}
                    >
                        <Select
                            className="w-100"
                            allowClear
                            mode="multiple"
                        >
                            {
                                (Object.keys(props?.master?.admin || {})).length > 0 && (Object.keys(props?.master?.admin || {})).map((i, k) => {
                                    return (
                                        <Select.Option value={i}
                                                       key={k}>{props?.master?.admin[i]}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-12 text-end">
                    <Space>
                        <Form.Item className="text-end mb-0" label=" ">
                            <Button type="default" htmlType="button" onClick={e => props?.onCloseModal(false)}>
                                Cancel
                            </Button>
                        </Form.Item>
                        <Form.Item className="text-end mb-0" label=" ">
                            <Button type="primary" htmlType="submit">
                                Save {props?.isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </Space>
                </div>
            </div>
        </Form>
    )
}
export default ModalTracker
