import {call, put, takeLatest, fork, all, delay, select} from 'redux-saga/effects';
import * as Types from '../types/Dashboard';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import {
    getAllDashboard,
    getItemCityFilter, getItemCounty,
    getItemDailyUserActivity, getItemLeadType,
    getItemUserActivity, getTrackingDashboard,
} from '../services/dashboardService';
import {
    dashboardUserActivitySuccess,
    dashboardCityFilterSuccess, dashboardAllSuccess, dashboardDailyUserActivitySuccess,
    dashboardImportLeadTypeSuccess,
    dashboardImportCountySuccess,
    dashboardTrackingSuccess
} from '../actions/dashboardAction';
import {filtersParams} from "../mapping/Request/DashboardRequest";
import moment from "moment";
import {downloadFile} from "../../utils/functions";

function* actionUserActivity() {
    yield takeLatest(Types.DASHBOARD_USER_ACTIVITY_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getItemUserActivity, params);
        yield put(dashboardUserActivitySuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function* actionDailyUserActivity() {
    yield takeLatest(Types.DASHBOARD_DAILY_USER_ACTIVITY_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getItemDailyUserActivity, params, {isDownload: params?.is_export}),
            selectOldData = yield select();
        if (params?.is_export) {
            yield put(dashboardDailyUserActivitySuccess(selectOldData?.dashboardReducer?.dailyUserActivity || {}));
            const fileName = ("Daily_User_activity_" + moment().format('DDMMYYYY') + ".xlsx");
            downloadFile(result, fileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        } else {
            yield put(dashboardDailyUserActivitySuccess(result));
        }
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function* actionCityFilter() {
    yield takeLatest(Types.DASHBOARD_CITY_FILTER_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getItemCityFilter, params);
        yield put(dashboardCityFilterSuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function* actionGetAll() {
    yield takeLatest(Types.DASHBOARD_ALL_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getAllDashboard, params);
        yield put(dashboardAllSuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function* actionLeadType() {
    yield takeLatest(Types.DASHBOARD_LEAD_TYPE_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getItemLeadType, params);
        yield put(dashboardImportLeadTypeSuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function* actionCounty() {
    yield takeLatest(Types.DASHBOARD_COUNTY_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        // const result = yield call(getItemCounty, params);
        const result = yield call(getItemCounty, params, {isDownload: params?.is_export}),
            selectOldData = yield select();
        if (params?.is_export) {
            yield put(dashboardImportCountySuccess(selectOldData?.dashboardReducer?.county || {}));
            const fileName = ("Import_Tracking" + moment().format('DDMMYYYY') + ".xlsx");
            downloadFile(result, fileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        } else {
            yield put(dashboardImportCountySuccess(result));
        }
        // yield put(dashboardImportCountySuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function* actionGetTracking() {
    yield takeLatest(Types.DASHBOARD_TRACKING_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getTrackingDashboard, params);
        yield put(dashboardTrackingSuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* dashboardSaga() {
    yield all([
        fork(actionGetAll),
        fork(actionUserActivity),
        fork(actionCityFilter),
        fork(actionDailyUserActivity),
        fork(actionLeadType),
        fork(actionCounty),
        fork(actionGetTracking),
    ])
}
