import {Button, DatePicker, Form, Modal, Space, Spin} from "antd";
import {DATE_TIME_FORMAT} from "../../../../constants/define";
import {Icon} from "../../../common";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {
    ActionAllDashboard,
    ActionImportCounty,
    ActionDataInit,
    ActionImportLeadType,
    ActionTrackingDashboard
} from "../../../../data/hooks/dashboard";
import {useSelector} from "react-redux";
import {selectImportCounty, selectFetching, selectImportLeadType} from "../../../../data/reselects/dashboardSelector";
import {Chart} from "react-google-charts";
import HeaderAction from "../../../common/layout/HeaderAction";

const {RangePicker} = DatePicker,
    initLevel = {
        level1: false,
        level2: false,
    },
    dataInit = {leadType: {data: [], options: {}}, county: {data: [], options: {}}};

const TrackingComponent = props => {
    const [isFetching, setIsFetching] = useState(false),
        [form] = Form.useForm(),
        [isDetail, setIsDetail] = useState(initLevel),
        [isOpenChartDetail, setIsOpenChartDetail] = useState(false),
        [keyLevel1, setKeyLevel1] = useState(""),
        [filters, setFilters] = useState({}),
        // [titleChartDetail, setTitleChartDetail] = useState("Detail Chart"),
        [resultInit, setResultInit] = useState(dataInit),
        [resultChartDetailLevel1, setResultChartDetailLevel1] = useState(dataInit.leadType),
        [resultChartDetailLevel2, setResultChartDetailLevel2] = useState(dataInit.leadType),
        [moduleSelect, setModuleSelect] = useState(""),
        [positionLevel1, setPositionLevel1] = useState(),
        [positionLevel2, setPositionLevel2] = useState(),
        getImportLeadType = ActionImportLeadType(),
        getImportCounty = ActionImportCounty(),
        getDashboardInit = ActionDataInit(),
        getAllDashboard = ActionTrackingDashboard(),
        itemFetching = useSelector(selectFetching()),
        itemImportCounty = useSelector(selectImportCounty()),
        itemImportLeadType = useSelector(selectImportLeadType());

    const onSelectChartInit = (props, module, level = "level1") => {
        if ((props.chartWrapper.getChart().getSelection()).length > 0) {
            const position = props.chartWrapper.getChart().getSelection()[0]?.row
            if (level === "level1") {
                setPositionLevel1(position)
                setResultChartDetailLevel1(dataInit.leadType)
                setResultChartDetailLevel2(dataInit.leadType)
                setIsDetail({...isDetail, level1: true, level2: false})
                setKeyLevel1(resultInit[module].data[position + 1][0])
                if (module === "leadType") {
                    getImportLeadType({...filters, level1: resultInit[module].data[position + 1][0]})
                } else if (module === "county") {
                    getImportCounty({...filters, level1: resultInit[module].data[position + 1][0]})
                }
                // props.chartWrapper.setDataTable([{row: 0, column: null}])
            }
            if (level === "level2") {
                setPositionLevel2(position)
                setResultChartDetailLevel2(dataInit.leadType)
                setIsDetail({...isDetail, level1: false, level2: true})
                if (module === "leadType") {
                    getImportLeadType({
                        ...filters,
                        level1: keyLevel1,
                        level2: resultChartDetailLevel1.data[position + 1][0]
                    })
                } else if (module === "county") {
                    getImportCounty({
                        ...filters,
                        level1: keyLevel1,
                        level2: resultChartDetailLevel1.data[position + 1][0]
                    })
                }
            }
            setModuleSelect(module);
            setIsOpenChartDetail(true);
        }
    }

    useEffect(() => {
        // getImportLeadType();
        getDashboardInit();
        form.setFieldsValue({date_filed: [moment(moment().startOf('month').format(DATE_TIME_FORMAT.SHORT_DATE_US), DATE_TIME_FORMAT.SHORT_DATE_US), moment(moment().endOf('month').format(DATE_TIME_FORMAT.SHORT_DATE_US), DATE_TIME_FORMAT.SHORT_DATE_US)]})
        return () => {
            setFilters({})
        }
    }, []);

    useEffect(() => {
        if (!isDetail.level1 && !isDetail.level2) {
            setResultInit({
                leadType: itemImportLeadType,
                county: itemImportCounty
            })
        }
    }, [itemImportLeadType, itemImportCounty]);

    useEffect(() => {
        if (isDetail.level1) {
            setResultChartDetailLevel1(itemImportLeadType)
        }
        if (isDetail.level2) {
            setResultChartDetailLevel2(itemImportLeadType)
        }
    }, [itemImportLeadType]);

    useEffect(() => {
        if (isDetail.level1) {
            setResultChartDetailLevel1(itemImportCounty)
        }
        if (isDetail.level2) {
            setResultChartDetailLevel2(itemImportCounty)
        }
    }, [itemImportCounty]);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    const onSearch = (val) => {
        setIsDetail(initLevel);
        setFilters(val)
        getAllDashboard(val);
    };

    const onClosePopup = () => {
        setIsDetail(initLevel);
        setIsOpenChartDetail(false);
        setPositionLevel1(null);
        setPositionLevel2(null);
        setModuleSelect("");
    }

    const onExport = () => {
        setIsDetail(initLevel);
        setFilters(form.getFieldsValue())
        getImportCounty({
            ...form.getFieldsValue(),
            is_export: 1
        });
    }

    return (
        <>
            <Spin spinning={isFetching}>
                <div className="container-fluid px-4">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onSearch}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className="row bg-white py-3 align-items-center">
                            <div className="col-4">
                                <Form.Item label="Date" name="date_filed"
                                           tooltip="Filter By date">
                                    <RangePicker style={{width: "100%"}} format={DATE_TIME_FORMAT.SHORT_DATE_US}/>
                                </Form.Item>
                            </div>
                            <div className="col-1">
                                <Form.Item className="text-end" label=" ">
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            Search {isFetching && <Icon type="sync-outlined" spin/>}
                                        </Button>
                                        <Button
                                            type="default"
                                            htmlType="button"
                                            onClick={e => onExport()}
                                        >
                                            Export {isFetching && <Icon type="sync-outlined" spin/>}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
                <Modal
                    maskClosable={false}
                    width={1000}
                    title="Chart Detail"
                    footer={<button type="button" className="ant-btn ant-btn-primary" onClick={() => onClosePopup()}>
                        <span>OK</span></button>}
                    destroyOnClose
                    onCancel={() => onClosePopup()}
                    visible={isOpenChartDetail}>
                    <Spin spinning={isFetching}>
                        {
                            (resultChartDetailLevel1.data.length > 0) && (
                                <ChartComponent
                                    options={{
                                        ...resultChartDetailLevel1.options,
                                        chartArea: {height: '70%', width: '90%'},
                                        titleTextStyle: {fontSize: 20},
                                        legend: {position: "none"},
                                        slices: {
                                            [positionLevel2]: {offset: 0.05},
                                        },
                                        hAxis: {
                                            slantedText: true,
                                            slantedTextAngle: -45
                                        }
                                    }}
                                    items={resultChartDetailLevel1}
                                    selectModule={moduleSelect}
                                    selectLevel="level2"
                                    // onSelectChartInit={(charts, module, level) => moduleSelect !== "county" ? onSelectChartInit(charts, module, level) : null}
                                />
                            )
                        }
                        {
                            (resultChartDetailLevel2.data.length > 0) && (
                                <ChartComponent
                                    wrapperClass="mt-3"
                                    options={{
                                        ...resultChartDetailLevel2.options,
                                        chartArea: {height: '70%', width: '90%'},
                                        titleTextStyle: {fontSize: 20},
                                        legend: {position: "none"},
                                        hAxis: {
                                            slantedText: true,
                                            slantedTextAngle: -45
                                        }
                                    }}
                                    items={resultChartDetailLevel2}
                                    selectModule=""
                                    selectLevel="level2"
                                    onSelectChartInit={null}/>
                            )
                        }
                    </Spin>
                </Modal>
                <div className="row hms-list-component mt-4 mx-3">
                    {
                        ((resultInit.leadType?.data || []).length > 0) && (
                            <div className={`col-md-12`}>
                                <div className={`ibox mb-4`}>
                                    <div className="ibox-content">
                                        <ChartComponent
                                            options={{
                                                ...resultInit.leadType.options,
                                                chartArea: {height: '70%', width: '90%'},
                                                titleTextStyle: {fontSize: 20},
                                                legend: {position: "none"},
                                                hAxis: {
                                                    slantedText: true,
                                                    slantedTextAngle: -45
                                                }
                                            }}
                                            items={resultInit.leadType}
                                            selectModule="leadType"
                                            selectLevel="level1"
                                            onSelectChartInit={onSelectChartInit}/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        ((resultInit.county?.data || []).length > 0) && (
                            <div className={`col-md-12`}>
                                <div className={`ibox mb-4`}>
                                    <div className="ibox-content">
                                        {/*<p className="h4">Recent orders</p>*/}
                                        {/*<p className="font-14">An order is an investor's instructions to a broker or brokerage firm*/}
                                        {/*    to purchase or sell</p>*/}
                                        <ChartComponent
                                            options={{
                                                ...resultInit.county.options,
                                                chartArea: {height: '70%', width: '90%'},
                                                titleTextStyle: {fontSize: 20},
                                                legend: {position: "none"},
                                                hAxis: {
                                                    slantedText: true,
                                                    slantedTextAngle: -45
                                                }
                                            }} items={resultInit.county}
                                            selectModule="county"
                                            selectLevel="level1"
                                            onSelectChartInit={onSelectChartInit}/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Spin>
        </>
    )
}

export default TrackingComponent

const ChartComponent = (props) => {
    return (
        <div className={`chart-component ${props.wrapperClass ? props.wrapperClass : ""}`}>
            {/*<Chart*/}
            {/*    width={'100%'}*/}
            {/*    height={'20vw'}*/}
            {/*    chartType="PieChart"*/}
            {/*    loader={<div>Loading Chart</div>}*/}
            {/*    data={props.items.data}*/}
            {/*    options={props.options}*/}
            {/*    chartEvents={[*/}
            {/*        {*/}
            {/*            eventName: "select",*/}
            {/*            callback: (charts) => props.onSelectChartInit ? props.onSelectChartInit(charts, props.selectModule, props.selectLevel) : null*/}
            {/*        }*/}
            {/*    ]}*/}
            {/*    rootProps={{'data-testid': '1'}}*/}
            {/*/>*/}

            {
                (props.items.data.length > 0) ? (
                    <>
                        {/*<h4 className="mb-4">{props.options?.title}</h4>*/}
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="40vw"
                            data={props.items.data}
                            options={props.options}
                            chartEvents={[
                                {
                                    eventName: "select",
                                    callback: (charts) => props.onSelectChartInit ? props.onSelectChartInit(charts, props.selectModule, props.selectLevel) : null
                                }
                            ]}
                            loader={<div>Loading Chart</div>}
                        />
                    </>
                ) : (Object.keys(props.items?.options).length > 0) ? (
                    <div className="text-center">
                        <img src="/layout/no_data_found.svg" alt=""/>
                        <p>No data Found</p>
                    </div>
                ) : ""
            }
        </div>
    )
}
