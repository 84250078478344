import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Switch, Route, withRouter} from 'react-router-dom';
import {checkUserAction, refreshTokenAction} from "../data/actions/authAction";
import {verifyGeneralAction, verifyInit, verifyRouteAction} from "../data/actions/verifyAction";
import {logoutAction} from "../data/actions/authAction";
import DEFINE, {CONFIG_LOCAL_STORAGE} from "../constants/define";
import $ from "jquery";
import Header from "./common/layout/Header";
import Navigation from "./common/layout/Navigation";
import PERMISSION from "../constants/permission";
import * as LocalStorage from "../utils/localStorage";
import {find} from 'lodash';
import {Spin} from 'antd';

class ContainerIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataUser: {},
            logging: false,
        };
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            let group = location?.state?.group || "",
                withParams = location?.state?.withParams || "";
            this.props.onVerifyGeneral()
            if (group && (group !== "errorPages" || this.props?.group !== "errorPages")) {
                let pathname = location.pathname;
                if (withParams) {
                    let arrayPath = location.pathname.split("/");
                    arrayPath[arrayPath.length - 1] = ":id"
                    pathname = arrayPath.join("/")
                }
                let permission = find(PERMISSION[group.toUpperCase()], {route: pathname});
                if (permission) {
                    this.props.onVerifyRoute({route: `${group}.${permission.permission}`})
                } else {
                    // this.props.onVerifyRoute({route: `NOT_FOUND`})
                }
            } else {
                // this.props.onVerifyRoute({route: `NOT_FOUND`})
            }
        });
    }

    componentDidMount() {
        const {location} = this.props;
        this.props.onVerifyGeneral()
        let group = location?.state?.group || this.props?.group || "",
            withParams = location?.state?.withParams || this.props?.withParams || "";
        if (group) {
            let pathname = location.pathname;
            if (withParams) {
                let arrayPath = location.pathname.split("/");
                arrayPath[arrayPath.length - 1] = ":id"
                pathname = arrayPath.join("/")
            }
            let permission = find(PERMISSION[group.toUpperCase()], {route: pathname});
            if (permission) {
                this.props.onVerifyRoute({route: `${group}.${permission.permission}`})
            } else {
                // this.props.onVerifyRoute({route: `NOT_FOUND`})
            }
        } else {
            // this.props.onVerifyRoute({route: `NOT_FOUND`})
        }

        let e = (window.innerHeight > 0 ? window.innerHeight : window.screen.height) - 1;
        $(".page-wrapper").css("min-height", e + "px");
        console.log('VERSION:', process.env.REACT_APP_VERSION);
        // this.props.onCheckUser();


        // const profile = LocalStorage.get(CONFIG_LOCAL_STORAGE.PROFILE);
        const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        // const refreshToken = LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
        // const expires = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
        //TODO: Ad-hoc fix, recheck
        if (!token) {
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
            LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
        } else
            this.props.onCheckUser();
        // this.setState({dataUser: profile || {}})
    };

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const {authLogin, location, verifyAction} = this.props;
        const {dataUser, logging} = this.state;

        return (
            <Spin tip="Loading..." spinning={verifyAction.isFetching}>
                <Header onLogoutRequest={this.props.onLogoutRequest} profile={authLogin.user?.user || {}}
                        authFetching={authLogin?.isFetching}/>
                <Navigation path={location.pathname} menu={authLogin?.user?.menu || []}/>
                <div className="page-wrapper">
                    {
                        !authLogin.logging && verifyAction.access && React.createElement(
                            this.props.children.type,
                            {
                                list_permission: PERMISSION,
                                profileUser: authLogin?.user,
                                ...this.props.children.props
                            },
                            this.props.children
                        )
                    }
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        authLogin: state.authReducer,
        verifyAction: state.verifyReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCheckUser: () => {
            dispatch(checkUserAction())
        },
        onLogoutRequest: () => {
            dispatch(logoutAction());
        },
        onVerifyRoute: (params) => {
            dispatch(verifyRouteAction(params));
        },
        onVerifyGeneral: (params) => {
            dispatch(verifyGeneralAction(params));
        },
        onVerifyInit: (params) => {
            dispatch(verifyInit(params));
        },
        refreshToken: token => dispatch(refreshTokenAction(token)),
    }
};

// export default ContainerIndex;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerIndex));

