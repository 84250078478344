import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {v4 as uuidv4} from 'uuid';
import {formatInt} from "../../../utils/functions";

export default class CustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.email = data?.email || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.roles = data?.roles || [];
        this.status = data?.status + "" || "0";
        this.phone_number = data?.phone_number || "";
        this.account_manager = data?.account_manager ? {
            id: data?.account_manager?.id || "",
            name: data?.account_manager?.name || "",
            email: data?.account_manager?.email || ""
        } : null;
        this.status_explain = data?.status_explain || "";
        this.created_at = data?.created_at ? moment(data?.created_at).format(DATE_TIME_FORMAT.SHORT_DATE_US) : "";
        this.last_login_at = data?.last_login_at ? moment(data?.last_login_at).format(DATE_TIME_FORMAT.SHORT_DATE_US) : "";
        this.credit = data?.credit || "";
        this.updated_at = data?.updated_at || "";
        this.api_key = data?.api_key || "";
        this.is_bypass_multiple_login = (data?.is_bypass_multiple_login === 1) ? true : false;
    }

    exportList() {
        return {
            id: this.id,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            status: this.status,
            status_explain: this.status_explain,
            roles: this.roles,
            phone_number: this.phone_number,
            account_manager: this.account_manager,
            created_at: this.created_at,
            last_login_at: this.last_login_at,
            credit: this.credit,
        }
    }

    exportItem() {
        return {
            id: this.id,
            email: this.email,
            credit: this.credit,
            first_name: this.first_name,
            last_name: this.last_name,
            status: this.status,
            status_explain: this.status_explain,
            roles: this.roles,
            phone_number: this.phone_number,
            is_bypass_multiple_login: this.is_bypass_multiple_login,
            account_manager: this.account_manager?.id,
            api_key: this.api_key,
        }
    }
}

export class MasterCustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.ROLES = data?.ROLES || {};
        this.STATUS = data?.STATUS || {};
        this.ADMIN = this.generateAdmin(data?.ADMIN || {});
    }

    generateAdmin(items = {}) {
        let newItems = {};
        if ((Object.keys(items || {})).length > 0) {
            (Object.keys(items || {})).map((i, k) => {
                newItems = {
                    ...newItems,
                    [items[i]]: i
                }
            })
        }
        return newItems
    }


    exportRoles() {
        return {
            roles: this.ROLES,
            status: this.STATUS,
            admin: this.ADMIN,
        }
    }
}


export class CustomerCreditResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.user = data?.user || "";
        this.before_credit = data?.before_credit || "";
        this.credit = data?.credit || "";
        this.after_credit = data?.after_credit || "";
        this.actor = data?.actor || "";
        this.created_at = data?.created_at ? moment(data?.created_at).format(DATE_TIME_FORMAT.SHORT_DATE_US) : "";
        this.note = data?.note || "";
    }

    exportList() {
        return {
            id: this.id,
            user: this.user,
            before_credit: this.before_credit,
            credit: this.credit,
            after_credit: this.after_credit,
            actor: this.actor,
            created_at: this.created_at,
            note: this.note,
        }
    }
}

export const customerCreditCols = ["created_at", "before_credit", "credit", "after_credit", "actor", "note"];
export const listCols = ['email', "phone_number", "account_manager", "first_name", "last_name", "credit", "last_login_at", "created_at", "status"];

export class AnnouncementResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || uuidv4();
        this.title = data?.title || "";
        this.url = data?.url || "";
        this.popup_content = data?.popup_content || [];
        this.popup_image_url = data?.popup_image_url || "";
        this.is_popup = Number(data?.is_popup);
        this.startTime = moment(data?.startTime).format(DATE_TIME_FORMAT.SHORT_DATE_US) || "";
        this.endTime = moment(data?.endTime).format(DATE_TIME_FORMAT.SHORT_DATE_US) || "";
        this.date_time = [moment(data?.startTime), moment(data?.endTime)];
    }

    exportList() {
        return {
            key: this.id,
            id: this.id,
            title: this.title,
            url: this.url,
            startTime: this.startTime,
            endTime: this.endTime,
            date_time: this.date_time,
            is_popup: this.is_popup,
            popup_image_url: this.popup_image_url,
            popup_content: this.popup_content,
            // is_popup: this.is_popup,
        }
    }
}

export const announcementCols = ["title", "url", "is_popup", "startTime", "endTime"];
