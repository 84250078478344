export default {
    LOGIN: '/api/v1/admin/login',
    REGISTER: '/api/v1/register',
    LOGOUT: '/',
    PROFILE: '/api/v1/admin/profile',
    UPDATE_PROFILE: '/api/v1/admin/profile/update',
    REFRESH: 'api/admin/user/refresh',

    VERIFY_ROUTE: '/api/v1/admin/check',
    VERIFY_GENERAL: '/api/v1/admin/general',

    DATA_UPLOAD: '/api/v1/admin/import/:leadType',
    // DATA_UPLOAD: '/api/v1/admin/import/pre-foreclosure',
    DATA_TRUNCATE: '/api/v1/admin/truncate/pre-foreclosure',
    DATA_MASTER_LEAD_TYPE: '/api/v1/admin/import/master/lead-type',
    DATA_MASTER_OTHER_LEAD_TYPE: '/api/v1/admin/other-lead-type/import/master',
    DATA_IMPORT_OTHER_LEAD_TYPE: '/api/v1/admin/other-lead-type/import',

    USER_LIST: '/api/v1/admin/user',
    USER_CREATE: '/api/v1/admin/user',
    USER_UPDATE: '/api/v1/admin/user/:id',
    USER_GET_ITEM: '/api/v1/admin/user/:id',
    USER_MASTER_DATA: '/api/v1/admin/user/master',

    CUSTOMER_LIST: '/api/v1/admin/customer',
    CUSTOMER_CREDIT_LIST: '/api/v1/admin/customer/credit',
    CUSTOMER_CREATE: '/api/v1/admin/customer',
    CUSTOMER_ADD_CREDIT: '/api/v1/admin/customer/credit',
    CUSTOMER_UPDATE: '/api/v1/admin/customer/:id',
    CUSTOMER_GET_ITEM: '/api/v1/admin/customer/:id',
    CUSTOMER_MASTER_DATA: '/api/v1/admin/customer/master',
    CUSTOMER_ANNOUNCEMENT: '/api/v1/admin/customer/announcement',

    HOTEL_LIST: '/api/v1/admin/hotel',
    HOTEL_ALL: '/api/v1/admin/hotel',
    HOTEL_MASTER: '/api/v1/admin/hotel/master',
    HOTEL_CREATE: '/api/v1/admin/hotel',
    HOTEL_DELETE: '/api/v1/admin/hotel/:id',
    HOTEL_UPDATE: '/api/v1/admin/hotel',
    HOTEL_DETAIL: '/api/v1/admin/hotel/:id',

    DASHBOARD_USER_ACTIVITY: '/api/v1/admin/dashboard/user-activity',
    DASHBOARD_DAILY_USER_ACTIVITY: '/api/v1/admin/dashboard/all-daily-active-user',
    DASHBOARD_CITY_FILTER: '/api/v1/admin/dashboard/city-filter',
    DASHBOARD_COUNTY: '/api/v1/admin/dashboard/import-county',
    DASHBOARD_LEAD_TYPE: '/api/v1/admin/dashboard/import-lead-type',

    TICKET_LIST: '/api/v1/admin/ticket',
    TICKET_CREATE: '/api/v1/admin/ticket',
    TICKET_COMMENT: '/api/v1/admin/ticket/:id',
    TICKET_UPDATE: '/api/v1/admin/ticket/:id',
    TICKET_GET_ITEM: '/api/v1/admin/ticket/:id',
    TICKET_MASTER_DATA: '/api/v1/admin/ticket/master',
    SUBSCRIPTION_CUSTOMER_MASTER_DATA: '/api/v1/admin/subscription/customer/master',
    SUBSCRIPTION_CUSTOMER_MASTER_STATE: '/api/v1/admin/subscription/customer/custom/state/master',
    SUBSCRIPTION_CUSTOMER_LIST: '/api/v1/admin/subscription/customer',
    SUBSCRIPTION_CUSTOMER_DETAIL: '/api/v1/admin/subscription/customer/:id',
    SUBSCRIPTION_CUSTOMER_REMOVE: '/api/v1/admin/subscription/customer/:id',
    SUBSCRIPTION_CUSTOMER_UPDATE: '/api/v1/admin/subscription/customer/:id',
    SUBSCRIPTION_CUSTOMER_CREATE: '/api/v1/admin/subscription/customer/custom/state',

    SUBSCRIPTION_CANCELATION_FEEDBACK_LIST: '/api/v1/admin/subscription/cancelation-feedback',

    SUBSCRIPTION_PACKAGE_MASTER_DATA: '/api/v1/admin/subscription/package/master',
    SUBSCRIPTION_PACKAGE_LIST: '/api/v1/admin/subscription/package',
    SUBSCRIPTION_PACKAGE_DETAIL: '/api/v1/admin/subscription/package/:id',
    SUBSCRIPTION_PACKAGE_UPDATE: '/api/v1/admin/subscription/package/:id',

    SUBSCRIPTION_GENERAL_PACKAGE_LIST: '/api/v1/admin/subscription/package-v2/general',
    SUBSCRIPTION_GENERAL_PACKAGE_UPDATE: '/api/v1/admin/subscription/package-v2/general/:id',

    SUBSCRIPTION_GEO_DATA_LIST: '/api/v1/admin/subscription/package-v2/master',
    SUBSCRIPTION_GEO_DATA_UPDATE: '/api/v1/admin/subscription/package-v2/master/:id',

    SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_DATA: '/api/v1/admin/subscription/package-v2/custom/master',
    SUBSCRIPTION_CUSTOM_PACKAGE_CREATE: '/api/v1/admin/subscription/package-v2/custom',
    SUBSCRIPTION_CUSTOM_PACKAGE_LIST: '/api/v1/admin/subscription/package-v2/custom',
    SUBSCRIPTION_CUSTOM_PACKAGE_DETAIL: '/api/v1/admin/subscription/package-v2/custom/:id',
    SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE: '/api/v1/admin/subscription/package-v2/custom/:id',

    SUBSCRIPTION_TRANSACTION_MASTER_DATA: '/api/v1/admin/subscription/transaction/master',
    SUBSCRIPTION_TRANSACTION_LIST: '/api/v1/admin/subscription/transaction',
    SUBSCRIPTION_TRANSACTION_UPDATE: '/api/v1/admin/subscription/transaction/:id',

    EXCLUSIVE_CONTENT_POST_LIST: '/api/v1/admin/exclusive-content',
    EXCLUSIVE_CONTENT_POST_CREATE: '/api/v1/admin/exclusive-content',
    EXCLUSIVE_CONTENT_POST_UPDATE: '/api/v1/admin/exclusive-content/:id',
    EXCLUSIVE_CONTENT_POST_GET_ITEM: '/api/v1/admin/exclusive-content/:id',

    EXCLUSIVE_CONTENT_LAYOUT_LIST: '/api/v1/admin/exclusive-content/layout',
    EXCLUSIVE_CONTENT_LAYOUT_UPDATE: '/api/v1/admin/exclusive-content/layout',

    SUBSCRIPTION_CUSTOMER_RECURRING_LIST: '/api/v1/admin/subscription/package-v2/recurring',
    SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE: '/api/v1/admin/subscription/package-v2/recurring/:id',
    SUBSCRIPTION_CUSTOMER_RECURRING_CREATE: '/api/v1/admin/subscription/package-v2/recurring',
    SUBSCRIPTION_CUSTOMER_RECURRING_GET_ITEM: '/api/v1/admin/subscription/package-v2/recurring/:id',
    SUBSCRIPTION_CUSTOMER_RECURRING_MASTER: '/api/v1/admin/subscription/package-v2/recurring/master',

    BANNER_LIST: '/api/v1/admin/banner',
    BANNER_UPDATE: '/api/v1/admin/banner',

    UPLOAD_FILE: '/api/v1/admin/upload',
}
