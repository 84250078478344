import {createSelector} from 'reselect'

const stateReducer = state => state.bannerReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        getReducer => getReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        getReducer => getReducer.statusAction
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        getReducer => getReducer.errors
    )

export const selectListBanners = (initItems) =>
    createSelector(
        stateReducer,
        getReducer => getReducer.data
    )
