import * as Types from '../types/Customer';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isFetchingCredit: false,
        isUpdateCreditSuccess: true,
        isCreateAnnouncements: false,
        isCreateAnnouncementsSuccess: false,
    },
    all: [],
    errors: [],
    masterData: {},
    // item: new HotelRequest().update(),
    item: {},
    data: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
    customerCredits: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
    announcements: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.CUSTOMER_LIST_ACTION:
        case Types.CUSTOMER_ANNOUNCEMENT_LIST_ACTION:
            return {
                ...state,
                isFetching: true,
                statusAction: initialState.statusAction,
            };
        case Types.CUSTOMER_ANNOUNCEMENT_ACTION:
            return {
                ...state, isFetching: true,
                statusAction: {
                    ...initialState.statusAction,
                    isCreateAnnouncements: true,
                    isCreateAnnouncementsSuccess: false
                },
            };
        case Types.CUSTOMER_CREDIT_ADD_ACTION:
        case Types.CUSTOMER_CREDIT_LIST_ACTION:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isFetchingCredit: true,
                    isUpdateCreditSuccess: false
                },
            };
        case Types.CUSTOMER_CREDIT_LIST_SUCCESS:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isFetchingCredit: false
                },
                customerCredits: action.customerCredit
            };
        case Types.CUSTOMER_CREDIT_ADD_SUCCESS:
            return {
                ...state,
                statusAction: {
                    ...initialState.statusAction,
                    isFetchingCredit: false,
                    isUpdateCreditSuccess: true
                },
            };
        case Types.CUSTOMER_LIST_SUCCESS:
            return {...state, isFetching: false, data: action.customers};
        case Types.CUSTOMER_CREATE_ACTION:
            return {...state, isFetching: true};
        case Types.CUSTOMER_CREATE_SUCCESS:
            return {...state, isFetching: false};
        case Types.CUSTOMER_UPDATE_ACTION:
            return {...state, isFetching: true};
        case Types.CUSTOMER_UPDATE_SUCCESS:
            return {...state, isFetching: false};
        case Types.CUSTOMER_MASTER_DATA_ACTION:
            return {...state, isFetching: true};
        case Types.CUSTOMER_MASTER_DATA_SUCCESS:
            return {...state, isFetching: false, masterData: action.masterData};
        case Types.CUSTOMER_GET_TEM_ACTION:
            return {...state, isFetching: true};
        case Types.CUSTOMER_GET_TEM_SUCCESS:
            return {...state, isFetching: false, item: action.item};
        case Types.CUSTOMER_ANNOUNCEMENT_LIST_SUCCESS:
            return {...state, isFetching: false, announcements: action.announcements};
        case Types.CUSTOMER_ANNOUNCEMENT_SUCCESS:
            return {
                ...state, isFetching: false,
                statusAction: {
                    ...initialState.statusAction,
                    isCreateAnnouncements: false,
                    isCreateAnnouncementsSuccess: true
                },
            };
        case Types.CUSTOMER_FAILED:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                statusAction: initialState.statusAction,
            };
        case Types.CUSTOMER_INIT:
            return initialState;
        default:
            return state;
    }
}
