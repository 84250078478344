import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class SubscriptionCustomerRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.status = formatInt(data?.status) || 0;
        this.user_id = data?.user_id || "";
        this.states = data?.states || [];
        this.months = data?.months || [];
        this.subscription_type = data?.subscription_type || "";
        this.package = {
            code: data?.code || "",
            item: data?.item || "",
            package_id: data?.package_id || "",
            type: data?.type || "",
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            status: this.status,
        }
    }

    exportCreate() {
        return {
            user_id: this.user_id,
            // states: this.states,
            months: this.months,
            // subscription_type: this.subscription_type,
            package: {
                ...this.package
            }
        }
    }

}

export const common = {
    ACTION_FIELD: {
        status: "status",
        email: "email",
        valid_time: "valid_time",
        subscription_type: "subscription_type",
        lead_type: "lead_type",
        state: "state",
        county: "county",
        order_id: "order_id",
        subscribe_date: "subscribe_date",
    },
}

export const filtersParams = (filters) => {
    return Object.keys(filters).length > 0 ? removeObjectNullFull({
        valid_time_from: filters?.valid_time_from ? moment(filters?.valid_time_from).format("YYYYMM") : undefined,
        valid_time_to: filters?.valid_time_to ? moment(filters?.valid_time_to).format("YYYYMM") : undefined,
        status: filters?.status === 0 ? 0 : filters?.status || "",
        email: filters?.email || "",
        lead_type: filters?.lead_type || "",
        state: filters?.state || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        sort: (filters?.order_field && filters?.order_value) ? `${filters?.order_field} ${filters?.order_value}` : "",
        // order_field: filters?.order_field || "",
        // order_value: filters?.order_value || "",
    }) : {}
}

