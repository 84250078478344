import React, {useState, useEffect, useRef} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {
    Form,
    Input,
    Button,
    Select,
    Spin,
    Descriptions,
    Badge,
    Comment,
    message,
    Upload,
    Space,
    Popconfirm
} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCommentTicket,
    ActionInit,
    ActionGetItem,
    ActionUpdateTicket
} from "../../../../data/hooks/ticket";

// ============== SELECT DATA ==============
import {selectFetching, selectMasterData, selectTicketItem} from "../../../../data/reselects/ticketSelector";
import {common} from "../../../../data/mapping/Request/TicketRequest";
import {Icon} from "../../../common";
import {isEmail} from "../../../../utils/validation";
import PERMISSION from "../../../../constants/permission";
import Avatar from "antd/es/avatar/avatar";
import MessageComments from "./components/MessageComments";
import {CloseOutlined, LoadingOutlined, PaperClipOutlined} from "@ant-design/icons";
import {ActionUpload, ActionUploadInitStore} from "../../../../data/hooks/upload";
import {selectFetching as selectFetchingUpload, selectFilesUpload} from "../../../../data/reselects/uploadSelector";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [comments, setComments] = useState(""),
        [masterUser, setMasterUser] = useState({}),
        [masterStatus, setMasterStatus] = useState({}),
        [masterType, setMasterType] = useState({}),
        [attachments, setAttachments] = useState([]),
        [isUpdate, setIsUpdate] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        // getCreateUser = ActionCreateUser(),
        getUpdate = ActionUpdateTicket(),
        getInitAction = ActionInit(),
        getItem = ActionGetItem(),
        actionCommentTicket = ActionCommentTicket(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectTicketItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail).length < 1) {
                getItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }

        return () => {
            getInitAction();
            setComments("");
            setAttachments([]);
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.user || {}).length > 0) {
            setMasterUser(itemMasterData?.user)
        }
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
        if (Object.keys(itemMasterData?.type || {}).length > 0) {
            setMasterType(itemMasterData?.type)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            getUpdate({...val, props, id: itemDetail?.id})
        } else {
            // getCreateUser({...val, props})
        }
    }

    const handleChange = e => {
        setComments(e.target.value);
    };

    const handleSubmit = e => {
        if (comments) {
            actionCommentTicket({message: comments, id: itemDetail?.id, attachments})
            setComments("");
        } else {
            message.error("Message is not empty")
        }
    };

    return (
        <>
            <HeaderAction title="Ticket Detail"/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-weight-bold">Ticket Information</p>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Title"
                                            name={common.ACTION_FIELD.title}
                                            rules={[{required: true}]}
                                        >
                                            <p>{itemDetail[common.ACTION_FIELD.title]}</p>
                                            {/*<Input disabled/>*/}
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Code"
                                            name={common.ACTION_FIELD.code}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Owner"
                                            name={common.ACTION_FIELD.owner}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Status"
                                            name={common.ACTION_FIELD.status}
                                            className="mb-0 pb-0"
                                            rules={[{required: true, message: 'Please select status!'}]}
                                        >
                                            <Select
                                                className="w-100"
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={parseInt(i)}
                                                                           key={k}>{masterStatus[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Assignee"
                                            className="mb-0 pb-0"
                                            name={common.ACTION_FIELD.assignee_id}
                                            rules={[{required: true, message: 'Please select assignee!'}]}
                                        >
                                            <Select
                                                showSearch
                                                showArrow
                                                allowClear
                                                className="w-100"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterUser)).length > 0 && (Object.keys(masterUser)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={i}
                                                                           key={k}>{masterUser[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Type"
                                            className="mb-0 pb-0"
                                            name={common.ACTION_FIELD.type}
                                            rules={[{required: true, message: 'Please select type!'}]}
                                        >
                                            <Select
                                                showSearch
                                                showArrow
                                                allowClear
                                                className="w-100"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterType)).length > 0 && (Object.keys(masterType)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={i}
                                                                           key={k}>{masterType[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="mt-3">
                                        <Form.Item className="text-end">
                                            <Button type="default" htmlType="button" className="me-3"
                                                    onClick={() => props.history.goBack()}>
                                                Back
                                            </Button>
                                            <Button type="primary" htmlType="submit">
                                                Save {isFetching && <Icon type="sync-outlined" spin/>}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="col-12 mt-3">
                            <p className="font-weight-bold">Ticket Comments</p>
                            <div className="ticket__comments--content">
                                <MessageComments {...props}
                                                 itemDetail={itemDetail}/>
                            </div>
                            <div className="mt-5">
                                <p>Leave Message <span
                                    className="color--description fs--15px">(Maximum 2500 characters)</span></p>
                                <Editor
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    submitting={isFetching}
                                    value={comments}
                                    setAttachments={e => setAttachments(e)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const Editor = ({onChange, onSubmit, submitting, value, setAttachments}) => {
    const [inputVal, setInputVal] = useState(""),
        [fileList, setFileList] = useState([]),
        [attach, setAttach] = useState([]),
        [files, setFiles] = useState({}),
        [imageUrl, setImageUrl] = useState(),
        [loadingUpload, setLoadingUpload] = useState(false),
        actionUpload = ActionUpload(),
        actionUploadInitStore = ActionUploadInitStore(),
        itemFileUpload = useSelector(selectFilesUpload()),
        itemIsUpload = useSelector(selectFetchingUpload());

    useEffect(() => {
        return () => {
            setAttach([])
            actionUploadInitStore();
        }
    }, []);

    useEffect(() => {
        setInputVal(value)
    }, [value]);

    useEffect(() => {
        setLoadingUpload(itemIsUpload)
    }, [itemIsUpload]);

    useEffect(() => {
        // let newItems = [];
        // if (attach.length > 0) {
        //     attach.map(i => newItems.push(i?.value))
        // }
        setAttachments(attach)
    }, [attach]);

    useEffect(() => {
        setFiles(itemFileUpload)
        if (Object?.keys(itemFileUpload || {}).length > 0) {
            let newAttach = [...attach];
            newAttach.push({
                title: itemFileUpload?.file_name,
                value: itemFileUpload?.file_url
            });
            setAttach(newAttach)
        }
    }, [itemFileUpload]);

    const _onChange = (event) => {
        const caret = event.target.selectionStart
        const element = event.target
        window.requestAnimationFrame(() => {
            element.selectionStart = caret
            element.selectionEnd = caret
        })

        onChange(event);
    }

    const handleChangeImage = info => {
        if (info.file.status === 'uploading') {
            setFiles({})
            setLoadingUpload(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                    setLoadingUpload(false);
                    setImageUrl(imageUrl)
                }
            );
        }
    };

    return (
        <>
            <Form.Item className="mb-2">
                <Input.TextArea rows={5} onChange={_onChange} value={inputVal} maxLength={2500}/>
            </Form.Item>
            <div className="">
                {/*<Upload {...propsUpload} maxCount={1} className="data-upload">*/}
                {/*    <PaperClipOutlined className="cursorPointer"/>*/}
                {/*</Upload>*/}
                {/*<Upload*/}
                {/*    name="file"*/}
                {/*    listType="picture-card"*/}
                {/*    // className="avatar-uploader h-100 profile--upload upload-full-wrap"*/}
                {/*    showUploadList={false}*/}
                {/*    action={(files) => actionUpload({files})}*/}
                {/*    customRequest={() => {*/}
                {/*    }}*/}
                {/*    // beforeUpload={beforeUpload}*/}
                {/*    onChange={handleChangeImage}*/}
                {/*>*/}
                {/*    {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : (*/}
                {/*        <div className="text-center">*/}
                {/*            {loadingUpload ? <>*/}
                {/*                <LoadingOutlined/>*/}
                {/*                <div style={{marginTop: 8}}>Upload</div>*/}
                {/*            </> : files?.file_url ? <>*/}
                {/*                <div className="profile__wrap">*/}
                {/*                    <img*/}
                {/*                        src={`${files?.file_url ? files?.file_url : "http://dev.realestate/layout/banner/affiliate%20banner%201.png"}`}*/}
                {/*                        alt="avatar"*/}
                {/*                        className="border__radius--50 w-100 height--150px object-fit profile__wrap--avatar"/>*/}
                {/*                </div>*/}
                {/*            </> : <>*/}
                {/*                <PaperClipOutlined className="cursorPointer"/>*/}
                {/*            </>}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</Upload>*/}
                {attach.length > 0 ? (
                    <div className="mb-2">
                        {
                            attach.map((i, k) => {
                                return (
                                    <div>
                                        <Space>
                                            <a href={i.value} key={k} target="_blank">
                                                {/*<PaperClipOutlined className="cursorPointer"/>*/}
                                                {i.title}
                                            </a>
                                            <Popconfirm
                                                title="Are you sure to delete this item?"
                                                onConfirm={() => {
                                                    let response = [...attach]
                                                    response = response.filter((i2, k) => i2?.title !== i?.title)
                                                    setAttach(response)
                                                }}
                                                onCancel={e => {
                                                }}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <CloseOutlined className="icon-ant fs--13px"/>
                                            </Popconfirm>
                                        </Space>
                                    </div>
                                )
                            })
                        }
                    </div>
                ) : null}
                <Upload
                    name="file"
                    listType="picture"
                    // className="avatar-uploader h-100 profile--upload upload-full-wrap"
                    showUploadList={false}
                    action={(files) => actionUpload({files})}
                    customRequest={() => {
                    }}
                    // beforeUpload={beforeUpload}
                    onChange={handleChangeImage}
                >
                    {loadingUpload ? <>
                        <LoadingOutlined/>
                    </> : <PaperClipOutlined className="cursorPointer"/>}
                </Upload>
            </div>
            <Form.Item className="text-end">
                <Button htmlType="submit" loading={submitting} onClick={e => {
                    onSubmit(e);
                    setAttach([])
                }} type="primary">
                    Send
                </Button>
            </Form.Item>
        </>
    )
};

export default Index
