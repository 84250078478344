import {useSelector} from "react-redux";
import {
    selectFetching,
    selectMasterDataOtherLeadType,
    selectStatusAction
} from "../../../../data/reselects/dataSelector";
import {Button, Checkbox, DatePicker, Form, Input, Popconfirm, Select, Space, Spin, Tooltip, Upload} from "antd";
import {common} from "../../../../data/mapping/Request/CustomerRequest";
import {isEmail} from "../../../../utils/validation";
import PERMISSION from "../../../../constants/permission";
import {Icon} from "../../../common";
import React, {useEffect, useRef, useState} from "react";
import {capitalizeFirstLetter} from "../../../../utils/functions";
import {DATE_TIME_FORMAT} from "../../../../constants/define";
import {cloneDeep, find} from "lodash";
import {ActionUpload, ActionUploadInitStore} from "../../../../data/hooks/upload";
import {selectFetching as selectFetchingUpload, selectFilesUpload} from "../../../../data/reselects/uploadSelector";
import {DeleteOutlined} from "@ant-design/icons";
import {ActionMasterDataOtherLeadType, ActionUploadDataOtherLeadType} from "../../../../data/hooks/data";

const FormOtherLeadType = props => {
    const [form] = Form.useForm(),
        [fileList, setFileList] = useState([]),
        itemMasterOtherLeadType = useSelector(selectMasterDataOtherLeadType()),
        [listCounty, setListCounty] = useState([]),
        [itemUploadSuccess, setItemUploadSuccess] = useState([]),
        [listCity, setListCity] = useState([]),
        actionUpload = ActionUpload(),
        actionUploadDataOtherLeadType = ActionUploadDataOtherLeadType(),
        actionUploadInitStore = ActionUploadInitStore(),
        itemIsFetching = useSelector(selectFetching()),
        itemFileUpload = useSelector(selectFilesUpload()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemIsUpload = useSelector(selectFetchingUpload());
    let listFile = [...fileList];

    useEffect(() => {
        listFile = []
        return () => {
            listFile = [];
            setItemUploadSuccess([])
        }
    }, [])

    useEffect(() => {
        if (itemStatusAction?.uploadOtherLeadTypesSuccess) {
            form.resetFields();
            onClearItemsUpload();
            setItemUploadSuccess([]);
            setListCounty([])
            setListCity([])
        }
    }, [itemStatusAction]);

    useEffect(() => {
        if (fileList.length > 0) {
            // onClearItemsUpload();
            actionUpload({files: fileList, isMultiple: true})
        }
    }, [fileList]);
    useEffect(() => {
        if (itemFileUpload.length > 0) {
            setItemUploadSuccess([...itemUploadSuccess, ...itemFileUpload])
            onClearItemsUpload();
        }
    }, [itemFileUpload]);

    const onClearItemsUpload = () => {
        actionUploadInitStore();
        setFileList([])
        listFile = []
    }

    const onValuesChange = val => {
        if (val?.property_state) {
            const itemState = find(itemMasterOtherLeadType?.states, {code: val.property_state})
            if (Object?.keys(itemState).length > 0) {
                setListCounty(itemState?.county || [])
                setListCity(itemState?.city || [])
            } else {
                setListCounty([])
                setListCity([])
            }
        }
    }

    const onFinish = val => {
        actionUploadDataOtherLeadType({...val, files: itemUploadSuccess})
    }

    const propsUpload = {
        // onRemove: file => {
        //     const index = fileList.indexOf(file);
        //     const newFileList = fileList.slice();
        //     newFileList.splice(index, 1);
        //     setFileList(newFileList);
        //     return false
        // },
        beforeUpload: file => {
            listFile = [...listFile, file]
            // const formData = new FormData();
            // formData.append('file', file)
            setFileList(listFile);
            return false;
        },
        fileList,
    };

    const onRemoveFile = (item, position) => {
        let items = cloneDeep(itemUploadSuccess);
        const newFileList = items.slice();
        newFileList.splice(position, 1);
        setItemUploadSuccess(newFileList)
    }

    return (
        <div className="container-fluid bg-white">
            <Spin spinning={itemIsUpload || itemIsFetching}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="row">
                        {
                            (Object.values(itemMasterOtherLeadType?.otherLeadType || {})).length > 0 ? (
                                <div className="col-4">
                                    <Form.Item
                                        label="Lead Type"
                                        name="lead_type"
                                        rules={[{required: true, message: 'Please select your Lead Type!'}]}
                                    >
                                        <Select
                                            className="w-100"
                                            style={{width: 120}}>
                                            {
                                                (Object.values(itemMasterOtherLeadType?.otherLeadType || {})).length > 0 && (Object.values(itemMasterOtherLeadType?.otherLeadType || {})).map((i, k) => {
                                                    return (
                                                        <Select.Option value={i?.key}
                                                                       key={k}>{i?.title}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            ) : null
                        }
                        <div className="col-4">
                            <Form.Item
                                label="Date Added"
                                name="date_added"
                                rules={[{required: true, message: 'Please select Date Added!'}]}
                            >
                                <DatePicker format={DATE_TIME_FORMAT.SHORT_DATE_US} className="w-100"/>
                            </Form.Item>
                        </div>
                        {
                            (itemMasterOtherLeadType?.states || []).length > 0 ? (
                                <div className="col-4">
                                    <Form.Item
                                        label="State"
                                        name="property_state"
                                        rules={[{required: true, message: 'Please select your State!'}]}
                                    >
                                        <Select
                                            className="w-100"
                                            style={{width: 120}}>
                                            {
                                                (itemMasterOtherLeadType?.states || []).length > 0 && (itemMasterOtherLeadType?.states || []).map((i, k) => {
                                                    return (
                                                        <Select.Option value={i?.code}
                                                                       key={k}>{i?.state_name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            ) : null
                        }
                        <div className="col-4">
                            <p className="mb-2">File Import</p>
                            <Upload
                                {...propsUpload}
                                multiple
                                className="data-upload"
                                itemRender={() => {
                                }}
                            >
                                <Button icon={<Icon type="upload-outlined"/>}>Select File</Button>
                            </Upload>
                            {
                                itemUploadSuccess.length > 0 ? (
                                    <ItemImageUploadComponent
                                        fileList={itemUploadSuccess}
                                        onRemove={onRemoveFile}
                                        // moveRow={moveRow}
                                    />
                                ) : null
                            }
                        </div>
                        {
                            (listCounty || []).length > 0 ? (
                                <div className="col-4">
                                    <Form.Item
                                        label="County"
                                        name="county"
                                    >
                                        <Select
                                            className="w-100"
                                            style={{width: 120}}>
                                            {
                                                (listCounty || []).length > 0 && (listCounty || []).map((i, k) => {
                                                    return (
                                                        <Select.Option value={i}
                                                                       key={k}>{i}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            ) : null
                        }
                        {
                            (listCity || []).length > 0 ? (
                                <div className="col-4">
                                    <Form.Item
                                        label="City"
                                        name="property_city"
                                    >
                                        <Select
                                            className="w-100"
                                            style={{width: 120}}>
                                            {
                                                (listCity || []).length > 0 && (listCity || []).map((i, k) => {
                                                    return (
                                                        <Select.Option value={i}
                                                                       key={k}>{i}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="row">
                        <div className="col-12 text-end">
                            <Form.Item className="">
                                <Button type="primary" htmlType="submit">
                                    Import Data {itemIsFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Spin>
        </div>
    )
}

export default FormOtherLeadType

const ItemImageUploadComponent = ({fileList, onRemove}) => {

    return (
        <div className="mt-3">
            {
                fileList.map((i, k) => {
                    return (
                        <Space key={k} className="mb-3 w-100">
                            <p className=""
                               style={{width: "100%", overflow: "hidden", textOverflow: "ellipsis"}}>{i?.file_name}</p>
                            <Popconfirm
                                title="Are you sure to delete this task?"
                                onConfirm={e => onRemove(i, k)}
                                onCancel={e => {
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined className="cursorPointer"/>
                            </Popconfirm>
                        </Space>
                    )
                })
            }
        </div>
    )
}
