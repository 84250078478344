import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import CustomerResponse, {
    MasterCustomerResponse,
    CustomerCreditResponse,
    AnnouncementResponse
} from "../mapping/Response/CustomerResponse";

export function getListCustomers(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function getCreditListCustomers(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_CREDIT_LIST,
        params: params,
        parser: customerCreditParser,
    }).get();
}

export function masterData() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new MasterCustomerResponse(data.data.result).exportRoles(),
    }).get();
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_CREATE,
    }).post(data);
}

export function addCredit(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_ADD_CREDIT,
    }).post(data);
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_UPDATE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function getItem(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_GET_ITEM,
        endpointParams: {id},
        parser: data => new CustomerResponse(data.data.result).exportItem(),
    }).get();
}

export function getAnnouncementList(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_ANNOUNCEMENT,
        params: params,
        parser: customerAnnouncement,
    }).get();
}

export function createAnnouncement(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_ANNOUNCEMENT,
    }).post(data);
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new CustomerResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}

function customerCreditParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new CustomerCreditResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}


function customerAnnouncement(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new AnnouncementResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
