import * as Types from '../types/Banner';

export const bannerListAction = (filters) => ({type: Types.BANNER_LIST_ACTION, filters});
export const bannerListSuccess = (banners) => ({type: Types.BANNER_LIST_SUCCESS, banners});

export const bannerUpdateAction = (params) => ({type: Types.BANNER_UPDATE_ACTION, params});
export const bannerUpdateSuccess = (update) => ({type: Types.BANNER_UPDATE_SUCCESS, update});

export const bannerInit = (refreshToken) => ({type: Types.BANNER_INIT, refreshToken});
export const bannerFail = (refreshToken) => ({type: Types.BANNER_FAILED, refreshToken});
