import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class SubscriptionCustomerRecurringRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.files = data?.files || [];
        this.date_added = data?.date_added || "";
        this.property_state = data?.property_state || "";
        this.property_city = data?.property_city || "";
        this.county = data?.county || "";
        this.lead_type = data?.lead_type || "";
    }

    exportCreate() {
        return removeObjectNullFull({
            files: this.files,
            date_added: this.date_added ? moment(this.date_added).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "",
            property_state: this.property_state,
            property_city: this.property_city,
            county: this.county,
            lead_type: this.lead_type,
            status: this.status,
        })
    }

}
