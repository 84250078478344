import {Button, Form, Input, InputNumber, Spin} from "antd";
import {Icon} from "../../../common";
import React from "react";
import {ActionCustomerCreditAdd, ActionCustomerCreditList} from "../../../../data/hooks/customer";
import {useSelector} from "react-redux";
import {
    selectFetching,
    selectStatusAction,
    selectCustomerCreditList
} from "../../../../data/reselects/customerSelector";

const CreditUpdateComponent = props => {

    const actionCustomerCreditAdd = ActionCustomerCreditAdd(),
        itemFetching = useSelector(selectFetching()),
        itemStatusAction = useSelector(selectStatusAction());

    const submitCredit = val => {
        actionCustomerCreditAdd({
            query: {
                ...val, id: props?.user_id
            }
        })
    }

    return (
        <Form
            layout="vertical"
            onFinish={submitCredit}
            // onValuesChange={onRequiredTypeChange}
            // requiredMark={requiredMark}
        >
            <Spin spinning={itemStatusAction?.isFetchingCredit}>
                <div className="row">
                    <div className="col-12">
                        <Form.Item label="Credit" name="credit" rules={[{required: true, message: "Credit is required"}]}>
                            <InputNumber className="w-100"/>
                        </Form.Item>
                    </div>
                    <div className="col-12">
                        <Form.Item label="Note" name="note" rules={[{required: true, message: "Note is required"}]}>
                            <Input/>
                        </Form.Item>
                    </div>
                    <div className="col-12">
                        <Form.Item className="text-end mb-0">
                            <Button type="primary" htmlType="submit" className="me-3 mb-0">
                                Submit {props.authFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                            <Button htmlType="button"
                                    className="mb-0"
                                    onClick={() => props?.setShowUpdateCredit(false)}>
                                Cancel
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Spin>
        </Form>
    )
}

export default CreditUpdateComponent
