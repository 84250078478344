import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import DashboardResponse from "../mapping/Response/DashboardResponse";

export function getItemUserActivity(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DASHBOARD_USER_ACTIVITY,
        params: params,
        parser: data => new DashboardResponse(data.data.result).exportUserActivity(),
    }).get();
}

export function getItemDailyUserActivity(params, options) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DASHBOARD_DAILY_USER_ACTIVITY,
        params: params,
        ...options,
        parser: data => params?.is_export ? data : new DashboardResponse(data.data.result).exportDailyUserActivity(),
    }).get();
}

export function getItemCityFilter(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DASHBOARD_CITY_FILTER,
        params: params,
        parser: data => new DashboardResponse(data.data.result).exportCityFilter(),
    }).get();
}

export function getItemLeadType(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DASHBOARD_LEAD_TYPE,
        params: params,
        parser: data => new DashboardResponse(data.data.result).exportImportLeadType(),
    }).get();
}

export function getItemCounty(params, options) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DASHBOARD_COUNTY,
        params: params,
        ...options,
        parser: data => params?.is_export ? data : new DashboardResponse(data.data.result).exportImportCounty(),
        // parser: data => new DashboardResponse(data.data.result).exportImportCounty(),
    }).get();
}

export function getAllDashboard(params = {}) {
    return new Promise((resolve) => {
        return Promise.all([
            getItemUserActivity(params).catch((e) => {
                console.log('===== getAllDashboard service error', e)
            }),
            getItemCityFilter(params).catch((e) => {
                console.log('===== getAllDashboard service error', e)
            })
        ]).then((responses) => {
            const userActivity = responses[0],
                cityFilter = responses[1];
            return resolve({userActivity, cityFilter});
        }).catch((e) => {
            console.log('===== getAllDashboard service error 111', e)
            resolve({})
        })
    })
}

export function getTrackingDashboard(params = {}) {
    return new Promise((resolve) => {
        return Promise.all([
            getItemLeadType(params).catch((e) => {
                console.log('===== getTrackingDashboard service error', e)
            }),
            getItemCounty(params).catch((e) => {
                console.log('===== getTrackingDashboard service error', e)
            })
        ]).then((responses) => {
            const leadType = responses[0],
                county = responses[1];
            return resolve({leadType, county});
        }).catch((e) => {
            console.log('===== getTrackingDashboard service error 111', e)
            resolve({})
        })
    })
}
