import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Data';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as DataService from '../services/dataService';
import * as DataActions from '../actions/dataAction';
import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {formatInt} from "../../utils/functions";
import {UPLOAD} from "../../constants/define";
import TAG_DEFINE from "../../constants/common";
import {dataUploadOtherLeadType} from "../services/dataService";
import SubscriptionCustomerRecurringRequest from "../mapping/Request/DataRequest";

function* dataImport() {
    yield takeLatest(Types.DATA_IMPORT_ACTION, Func.sagaWrapper(function* (action) {
        const file = action.params.file,
            leadType = action.params.leadType;
        if (file.size > UPLOAD.MAX_FILE_SIZE) {
            yield put({type: Types.DATA_FAILED});
            message.error("File data cannot be larger than 10MB")
        } else {
            const formData = new FormData();
            formData.append('file', file);
            const result = yield call(DataService.dataUpload, {formData, leadType});
            yield put(DataActions.dataImportSuccess(result.data.data));
            // message.success("Upload Success")
        }
    }, errorHandle(Types.DATA_FAILED)))
}

function* actionDataUploadOtherLeadType() {
    yield takeLatest(Types.DATA_UPLOAD_OTHER_LEAD_TYPE_ACTION, Func.sagaWrapper(function* (action) {
        const wrapperParams = new SubscriptionCustomerRecurringRequest(action.params).exportCreate()
        const result = yield call(DataService.dataUploadOtherLeadType, wrapperParams);
        message.success(result)
        yield put(DataActions.dataUploadOtherLeadTypeSuccess(result));
    }, errorHandle(Types.DATA_FAILED)))
}

function* dataTruncate() {
    yield takeLatest(Types.DATA_TRUNCATE_ACTION, Func.sagaWrapper(function* (action) {
        yield call(DataService.dataTruncate, action.params?.id);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["203"]);
        yield put(DataActions.dataTruncateSuccess());
    }, errorHandle(Types.DATA_FAILED)))
}

function* actionGetDataMasterLeadType() {
    yield takeLatest(Types.DATA_MASTER_LEAD_TYPE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(DataService.masterDataLeadType, action.params);
        yield put(DataActions.dataMasterLeadTypeSuccess(result));
    }, errorHandle(Types.DATA_FAILED)))
}

function* actionGetDataMasterOtherLeadType() {
    yield takeLatest(Types.DATA_MASTER_OTHER_LEAD_TYPE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(DataService.masterDataOtherLeadType, action.params);
        yield put(DataActions.dataMasterOtherLeadTypeSuccess(result));
    }, errorHandle(Types.DATA_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* dataSaga() {
    yield all([
        fork(dataImport),
        fork(dataTruncate),
        fork(actionGetDataMasterLeadType),
        fork(actionGetDataMasterOtherLeadType),
        fork(actionDataUploadOtherLeadType),
    ])
}
