import {createSelector} from 'reselect'

const stateReducer = state => state.customerReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.statusAction
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.errors
    )

export const selectListCustomer = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.masterData
    )
export const selectCustomerItem = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.item
    )
export const selectCustomerCreditList = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.customerCredits
    )

export const selectCustomerAnnouncementList = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.announcements
    )
