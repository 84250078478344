import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class SubscriptionPackageRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.standard_price = formatInt(data?.standard_price);
        this.standard_sale_price = formatInt(data?.standard_sale_price);
        this.premium_price = formatInt(data?.premium_price);
        this.premium_sale_price = formatInt(data?.premium_sale_price);
        this.status = formatInt(data?.status) || 0;
    }

    exportUpdate() {
        return {
            id: this.id,
            standard_price: this.standard_price,
            standard_sale_price: this.standard_sale_price,
            premium_price: this.premium_price,
            premium_sale_price: this.premium_sale_price,
            status: this.status,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        lead_type: "lead_type",
        state: "state",
        county: "county",
        standard_price: "standard_price",
        standard_sale_price: "standard_sale_price",
        premium_price: "premium_price",
        premium_sale_price: "premium_sale_price",
        status: "status",
    },
}

export const filtersParams = (filters) => {
    return Object.keys(filters).length > 0 ? removeObjectNullFull({
        valid_time_from: filters?.valid_time_from ? moment(filters?.valid_time_from).format("YYYYMM") : undefined,
        valid_time_to: filters?.valid_time_to ? moment(filters?.valid_time_to).format("YYYYMM") : undefined,
        status: filters?.status === 0 ? 0 : filters?.status || "",
        email: filters?.email || "",
        lead_type: filters?.lead_type || "",
        state: filters?.state || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    }) : {}
}

