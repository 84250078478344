import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {DATE_TIME_FORMAT, PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";

// ============== ACTION ==============
import {
    ActionGetListSubscriptionCustomerRecurring, ActionUpdateSubscriptionCustomerRecurring,
} from '../../../../data/hooks/subscriptionCustomerRecurring';
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker, Popconfirm, Modal, Table} from 'antd'

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListSubscriptionCustomerRecurring,
} from '../../../../data/reselects/subscriptionCustomerRecurringSelector';
import SubscriptionCustomerRecurringResponse, {
    constants,
    listCols
} from "../../../../data/mapping/Response/SubscriptionCustomerRecurringResponse";
import {InfoCircleOutlined} from "@ant-design/icons";
import queryString from "query-string";
import moment from "moment";
import {filtersParams} from "../../../../data/mapping/Request/SubscriptionCustomerRecurringRequest";

let STATUS_RQ = [1, 0]
const ModelFields = Object.getOwnPropertyNames(new SubscriptionCustomerRecurringResponse());
const Index = props => {
    const namePage = "subscription_customer_recurring",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ['action'], ["id"]),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            email: {
                fixed: 'left',
                width: 250,
            },
            recurring_date: {
                width: 60,
            },
            price: {
                width: 60,
            },
            status: {
                title: <Tooltip title="Click to change status">
                    <div className="clearfix">
                        <p className="float-start mb-0" style={{justifyContent: "center"}}>Status</p>
                        <InfoCircleOutlined className="float-start mt-1 ms-2 cursorPointer"
                                            style={{justifyContent: "center"}}/>
                    </div>
                </Tooltip>,
                width: 50,
                render: (val, record) => (
                    <Popconfirm placement="top" title="Are you sure?"
                                onConfirm={() => actionUpdateSubscriptionCustomerRecurring({
                                    id: record.id,
                                    query: {
                                        status: STATUS_RQ[record.status]
                                    },
                                    is_listing: true,
                                    filters: paramsFilters
                                })} okText="Yes" cancelText="No" className="">
                        <Tag className="cursorPointer" color={val ? "#2db7f5" : "red"}>{constants.status[val]}</Tag>
                    </Popconfirm>
                ),
            },
            action: {
                width: 50,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="eye-outlined" className="cursorPointer"
                                                onClick={() => props.history.push((PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING_UPDATE.route).replace(":id", record.id), {
                                                    group: PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING_UPDATE.group,
                                                    withParams: true
                                                })}/>,
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [isDetail, setIsDetail] = useState(false),
        [resultList, setResultList] = useState([]),
        [resultDetail, setResultDetail] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListSubscriptionCustomerRecurring = ActionGetListSubscriptionCustomerRecurring(),
        actionUpdateSubscriptionCustomerRecurring = ActionUpdateSubscriptionCustomerRecurring(),
        // ============== SELECT DATA ==============
        itemListSubscriptionCustomerRecurring = useSelector(selectListSubscriptionCustomerRecurring()),
        itemErrors = useSelector(selectErrors()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            setPramsFilters(paramsFilters)
            if (paramsFilters?.recurring_date_from && paramsFilters?.recurring_date_to) {
                paramsFilters.recurring_date = [moment(paramsFilters?.recurring_date_from), moment(paramsFilters?.recurring_date_to)]
            }
            form.setFieldsValue(paramsFilters)
            getListSubscriptionCustomerRecurring(removeObjectNullFull(paramsFilters));
        } else {
            getListSubscriptionCustomerRecurring();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListSubscriptionCustomerRecurring?.result || [])
        setPagination({
            total: itemListSubscriptionCustomerRecurring?.total || 0,
            totalPage: itemListSubscriptionCustomerRecurring?.total_page || 0,
            defaultCurrent: itemListSubscriptionCustomerRecurring?.page_index || 1,
            pageSize: itemListSubscriptionCustomerRecurring?.page_size || 10
        })
    }, [itemListSubscriptionCustomerRecurring]);

    const onTableChange = (pagination, filters, sorter) => {
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.route, filtersParams(newFilters)))
        getListSubscriptionCustomerRecurring(newFilters);
    };

    const onSearch = (val) => {
        let getFilters = removeObjectNullFull({...initData.filters, ...paramsFilters, ...val, page_index: 1});
        setPramsFilters(getFilters)
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.route, filtersParams(getFilters)))
        getListSubscriptionCustomerRecurring(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction title="Subscription Customer Recurring" isCreate
                      onClick={e => props.history.push(PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING_ACTION.route)}/>
        <Modal width="50%"
               title="Details"
               destroyOnClose
               visible={isDetail}
            // maskClosable={false}
               wrapClassName="pt-0"
               footer={false}
               onCancel={e => {
                   setIsDetail(false);
                   setResultDetail([])
               }}
        >
            <Table
                pagination={false}
                dataSource={resultDetail}
                columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Price',
                        dataIndex: 'price',
                        key: 'price',
                    },
                ]}/>
        </Modal>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-3">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-4">
                        <Form.Item
                            label="Recurring Date"
                            name="recurring_date"
                        >
                            <DatePicker.RangePicker className="w-100" format={DATE_TIME_FORMAT.SHORT_DATE_US}/>
                        </Form.Item>
                    </div>
                    <div className="col-1">
                        <Form.Item className="text-end" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
