import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull
} from "../../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
import {ActionGetListCustomer, ActionMasterData} from '../../../../data/hooks/customer';
import {ActionGetListPost} from '../../../../data/hooks/exclusiveContent';
import {Tooltip, Button, Tag, Form, Input, Select} from 'antd'

// ============== SELECT DATA ==============
// import {
//     selectFetching,
//     selectErrors,
//     selectListCustomer,
//     selectMasterData
// } from '../../../../data/reselects/customerSelector';
import {
    selectFetching,
    selectErrors,
    selectListPost,
} from '../../../../data/reselects/exclusiveContentSelector';
import CustomerResponse, {listCols} from "../../../../data/mapping/Response/CustomerResponse";
import ExclusiveContentResponse, {
    postListCols,
    CONSTANT
} from "../../../../data/mapping/Response/ExclusiveContentResponse";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

const ModelFields = Object.getOwnPropertyNames(new ExclusiveContentResponse());
const Index = props => {
    const namePage = "exclusive_content_post",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(postListCols, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            thumbnail: {
                width: 120,
                render: (val, record) => <img className="img-thumbnail" src={val}/>,
            },
            description: {
                render: (val, record) => <p style={{whiteSpace: "pre-wrap"}}>{val}</p>,
            },
            footer_description: {
                render: (val, record) => <p style={{whiteSpace: "pre-wrap"}}>{val}</p>,
            },
            video_url: {
                render: (val, record) => <a href={val}>{val}</a>,
            },
            status: {
                width: 100,
                render: (val, record) => CONSTANT.STATUS[val],
            },
            footer_icon: {
                width: 80,
                align: 'center',
                render: (val, record) => val ? <CheckOutlined style={{color: "#00e673"}}/> :
                    <CloseOutlined style={{color: "#ff0000"}}/>,
            },
            footer_hot: {
                width: 80,
                align: 'center',
                render: (val, record) => val ? <CheckOutlined style={{color: "#00e673"}}/> :
                    <CloseOutlined style={{color: "#ff0000"}}/>,
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="edit-outlined" className="cursorPointer"
                                                onClick={() => props.history.push((PERMISSION.EXCLUSIVE_CONTENT.UPDATE.route).replace(":id", record.id), {
                                                    group: PERMISSION.EXCLUSIVE_CONTENT.UPDATE.group,
                                                    withParams: true
                                                })}/>,
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        actionGetListPost = ActionGetListPost(),
        // actionGetListPost = ActionGetListCustomer(),
        // getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        // itemListCustomers = useSelector(selectListCustomer()),
        itemListPost = useSelector(selectListPost()),
        itemErrors = useSelector(selectErrors()),
        // itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        actionGetListPost();
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListPost?.result || [])
        setPagination({
            total: itemListPost?.total || 0,
            totalPage: itemListPost?.total_page || 0,
            defaultCurrent: itemListPost?.page_index || 1,
            pageSize: itemListPost?.page_size || 10
        })
    }, [itemListPost]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        // setInitData({
        //     ...initData,
        //     filters: newFilters
        // });
        actionGetListPost(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((PERMISSION.EXCLUSIVE_CONTENT.UPDATE.route).replace(":id", data.id))
        } else {
            props.history.push(PERMISSION.EXCLUSIVE_CONTENT.CREATE.route, {group: PERMISSION.EXCLUSIVE_CONTENT.CREATE.group})
        }
    };

    const onSearch = (val) => {
        let getFilters = {...paramsFilters, ...val};
        setPramsFilters(getFilters)
        actionGetListPost(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction title="Exclusive Content Post" isCreate onClick={e => onCreate(e)}/>
        <ListComponent
            scroll={{x: 2000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
