export const SUBSCRIPTION_TRANSACTION_LIST_ACTION = "SUBSCRIPTION_TRANSACTION_LIST_ACTION";
export const SUBSCRIPTION_TRANSACTION_LIST_SUCCESS = "SUBSCRIPTION_TRANSACTION_LIST_SUCCESS";

export const SUBSCRIPTION_TRANSACTION_MASTER_ACTION = "SUBSCRIPTION_TRANSACTION_MASTER_ACTION";
export const SUBSCRIPTION_TRANSACTION_MASTER_SUCCESS = "SUBSCRIPTION_TRANSACTION_MASTER_SUCCESS";

export const SUBSCRIPTION_TRANSACTION_UPDATE_ACTION = "SUBSCRIPTION_TRANSACTION_UPDATE_ACTION";
export const SUBSCRIPTION_TRANSACTION_UPDATE_SUCCESS = "SUBSCRIPTION_TRANSACTION_UPDATE_SUCCESS";

export const SUBSCRIPTION_TRANSACTION_INIT = "SUBSCRIPTION_TRANSACTION_INIT";
export const SUBSCRIPTION_TRANSACTION_FAILED = "SUBSCRIPTION_TRANSACTION_FAILED";
